import * as React from 'react'
import Box from '@mui/material/Box'
import Slider from '@mui/material/Slider'
import { FunctionalButton } from './_components'
import { RangeSliderProps } from '../utils/interfaces'
import { transformFullDateToMonthAndYear } from '../utils/functions'
import { debounce } from 'lodash'
import { useEffect, useMemo, useState } from 'react'
import { findClosestMidnightString } from '../utils/transformingData'

const RangeSlider2 = ({
  dataArray,
  customStyles,
  valueLabelDisplay,
  initialDetails,
  selectedDetails,
  onApply,
  readOnly,
  noApplyButton,
  handleChange,
  dateSliderMode,
  iconMode,
  noSlider,
  extraItems
}: RangeSliderProps) => {
  const transformedData = useMemo(() => {
    return dataArray
      .filter((date) => date !== null && date !== undefined)
      .map((date) => findClosestMidnightString(date))
  }, [dataArray])

  const getIndex = (date: string, defaultIndex: number) => {
    const index = transformedData.findIndex((d) => d === date)
    return index === -1 ? defaultIndex : index
  }

  const initialStartDateIndex = 0
  const initialEndDateIndex = transformedData.length - 1

  const startSelectedIndex = getIndex(selectedDetails[0], initialStartDateIndex)
  const endSelectedIndex = getIndex(selectedDetails[1], initialEndDateIndex)

  const [startDateIndex, setStartDateIndex] = useState(startSelectedIndex)
  const [endDateIndex, setEndDateIndex] = useState(endSelectedIndex)

  const [value, setValue] = useState<number[]>([
    startSelectedIndex,
    endSelectedIndex
  ])
  const debouncedHandleChange = React.useCallback(
    debounce((newValue: string[]) => {
      if (handleChange) handleChange(newValue) // Pass the actual data to handleChange
    }, 500),
    [handleChange]
  )

  const updateSliderValue = (
    newStartDateIndex: number,
    newEndDateIndex: number
  ) => {
    setStartDateIndex(newStartDateIndex)
    setEndDateIndex(newEndDateIndex)
    setValue([newStartDateIndex, newEndDateIndex])
  }

  const onSliderChange = (event: Event, newValue: number | number[]) => {
    if (Array.isArray(newValue) && Math.abs(newValue[0] - newValue[1]) < 5) {
      return false
    }
    setValue(newValue as number[])
  }

  const onStartDateChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const newStartDateIndex = parseInt(event.target.value, 10)
    const newEndDateIndex = Math.max(endDateIndex, newStartDateIndex)
    updateSliderValue(newStartDateIndex, newEndDateIndex)
  }

  const onEndDateChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const newEndDateIndex = parseInt(event.target.value, 10)
    const newStartDateIndex = Math.min(startDateIndex, newEndDateIndex)
    updateSliderValue(newStartDateIndex, newEndDateIndex)
  }

  const generateStartDateOptions = () => {
    const maxStartDateIndex = endDateIndex - 4
    return transformedData
      .slice(0, maxStartDateIndex + 1)
      .map((date, index) => (
        <option key={index} value={index}>
          {transformFullDateToMonthAndYear(date)}
        </option>
      ))
  }

  const generateEndDateOptions = () => {
    const minEndDateIndex = startDateIndex + 4
    return transformedData.slice(minEndDateIndex).map((date, index) => (
      <option key={index} value={index + minEndDateIndex}>
        {transformFullDateToMonthAndYear(date)}
      </option>
    ))
  }

  const previousValue = React.useRef<number[]>(value)

  useEffect(() => {
    // Only trigger handleChange if there is a change in value
    if (
      handleChange &&
      (value[0] !== previousValue.current[0] ||
        value[1] !== previousValue.current[1])
    ) {
      debouncedHandleChange(value.map((index) => transformedData[index]))
      previousValue.current = value
    }
  }, [value, transformedData, debouncedHandleChange, handleChange])

  const functionToExecute = () => {
    if (Math.abs(value[0] - value[1]) < 5) return false
    if (dateSliderMode) {
      const res = onApply(
        transformedData[value[0]].split('T')[0],
        transformedData[value[1]].split('T')[0]
      )
      return res
    } else {
      const res = onApply(transformedData[value[0]], transformedData[value[1]])
      return res
    }
  }

  useEffect(() => {
    setStartDateIndex(value[0])
    setEndDateIndex(value[1])
  }, [value])

  useEffect(() => {
    const newSelectedStartIndex = getIndex(
      selectedDetails[0],
      initialStartDateIndex
    )
    const newSelectedEndIndex = getIndex(
      selectedDetails[1],
      initialEndDateIndex
    )
    setValue([newSelectedStartIndex, newSelectedEndIndex])
  }, [selectedDetails])

  return (
    <div className={`flex middle gap-2 '${!noSlider ? ' w-100' : ''}`}>
      <div>
        <label htmlFor="startDateSelect" className="margin-right-0-half">
          From
        </label>
        <select
          className="narrower"
          id="startDateSelect"
          disabled={readOnly}
          value={startDateIndex}
          onChange={onStartDateChange}
        >
          {generateStartDateOptions()}
        </select>
      </div>
      {!noSlider && (
        <Box
          sx={{ ...customStyles, '& .MuiSlider-root': { color: '#4a4897' } }}
        >
          <Slider
            getAriaLabel={() => 'Range'}
            value={value}
            min={initialStartDateIndex}
            max={initialEndDateIndex}
            valueLabelDisplay={valueLabelDisplay || 'off'}
            disableSwap={true}
            onChange={onSliderChange}
          />
        </Box>
      )}
      {noSlider && <span>-</span>}
      <div>
        <select
          id="endDateSelect"
          className="narrower"
          value={endDateIndex}
          disabled={readOnly}
          onChange={onEndDateChange}
        >
          {generateEndDateOptions()}
        </select>
        <label htmlFor="endDateSelect" className="margin-left-0-half">
          To
        </label>
      </div>

      {!noApplyButton && (
        <FunctionalButton
          functionToExecute={functionToExecute}
          initialButtonState={'Apply'}
          iconSaveMode={iconMode === 'save'}
          iconMinusMode={iconMode === 'minus'}
          iconCalculateMode={iconMode === 'calculate'}
          doesReset={true}
          disabled={Math.abs(value[0] - value[1]) < 5}
        />
      )}
    </div>
  )
}

export default RangeSlider2
