import React, { useState } from 'react'
import { IEntitySharingControls } from '../utils/interfaces'
import { FunctionalButton, InlineSpinner } from './_components'
import {
  getMemoLink,
  initialSharing,
  updateEntitySharing
} from '../utils/fetch'
import { TickFail, TickSuccess, IconUploadNew } from './Icons'
import { isEmpty } from '../utils/functions'

const EntitySharingControls = ({
  refreshFunction,
  entityId,
  entityType,
  ChannelContentPiece,
  initialImage,
  initialDescription
}: IEntitySharingControls) => {
  const [sharingLevel, setSharingLevel] = useState<number>(
    ChannelContentPiece ? ChannelContentPiece.sharing_tier : 0
  )
  const [uploadState, setUploadState] = useState<
    'loading' | 'error' | 'success' | 'ready'
  >('ready')
  const [newDescription, setNewDescription] = useState<string>(
    ChannelContentPiece !== null && ChannelContentPiece !== undefined
      ? ChannelContentPiece.short_description || ''
      : ''
  )
  const [imageLink, setImageLink] = useState<string | null>(
    ChannelContentPiece?.link || ''
  )

  const handleAddImage = async (file: File) => {
    const imageType = file.type.split('/')[1]
    const link = await getMemoLink(file, imageType)

    if (!link) {
      setUploadState('error')
      return
    }

    setUploadState('success')
    setImageLink(link)
    return link
  }

  const initialiseSharing = async () => {
    const res = await initialSharing({
      sharingTier: 0,
      entityId,
      entityType,
      initialDescription
    })

    return res
  }

  const updateSharing = async () => {
    if (!ChannelContentPiece) return console.error('No sharing reference found')
    let linkToSet = ChannelContentPiece.link || ''
    if (imageLink === 'none') {
      linkToSet = ''
    } else if (!isEmpty(imageLink)) {
      linkToSet = imageLink || ''
    }

    const res = await updateEntitySharing({
      sharingTier: sharingLevel,
      fid: ChannelContentPiece.fid,
      link: linkToSet,
      description: newDescription
    })

    return res
  }

  if (!ChannelContentPiece) {
    return (
      <div className="col-12 middle flex center p-3">
        <FunctionalButton
          initialButtonState="Initialize Sharing"
          functionToExecute={initialiseSharing}
          className="primary wide"
          refreshOnComplete={{
            exists: true,
            refreshFunction
          }}
          doesReset
        />
      </div>
    )
  }

  return (
    <div className="sharing-controls">
      <h3>Details</h3>
      <label htmlFor="sharingLevel">
        <strong>Sharing Level: </strong>
      </label>
      <select
        id="sharingLevel"
        value={sharingLevel}
        className="m-3 mt-0"
        onChange={(e) => setSharingLevel(Number(e.target.value))}
      >
        <option value={0}>Private</option>
        <option value={10}>Friends Read</option>
        <option value={11} disabled>
          Friends Write
        </option>
      </select>
      <textarea
        className="col-12"
        rows={3}
        value={newDescription}
        onChange={(e) => setNewDescription(e.target.value)}
        placeholder="Description"
      />
      <div className="profile">
        <div className="profile-image">
          {imageLink && <img className="" src={imageLink} alt="Thumbnail" />}
          <div className="edit-text pointer">
            <div className={'file-upload flex middle'}>
              <label htmlFor="file-input" className="file-input">
                {
                  {
                    loading: <InlineSpinner />,
                    error: <TickFail />,
                    success: <TickSuccess />,
                    ready: <IconUploadNew />
                  }[uploadState]
                }
              </label>
              <input
                type="file"
                id="file-input"
                name="file-input"
                onChange={async (e) => {
                  if (e.target.files) {
                    handleAddImage(e.target.files[0])
                  }
                }}
              />
            </div>
          </div>
        </div>
        <div className="profile-info">
          <h3 className="flex gap-1">
            Thumbnail
            <button
              className="icon no-btn"
              onClick={() => setImageLink('none')}
            >
              x
            </button>
          </h3>
        </div>
      </div>

      <br />
      <div className="col-12 flex center middle">
        <FunctionalButton
          initialButtonState="Update Sharing"
          functionToExecute={updateSharing}
          className="primary wide"
          refreshOnComplete={{
            exists: true,
            refreshFunction
          }}
          doesReset
        />
      </div>
    </div>
  )
}

export default EntitySharingControls
