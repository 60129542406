import React, { useMemo, useRef } from 'react'
// import TicksWrapper from './TicksWrapper'
import {
  IBasicIndicator,
  ICalculatedIndicator,
  IDataMappedComponentProps,
  IForecast,
  IMappedDataEntry,
  ITrendline
  //   IBasicIndicator
} from '../utils/interfaces'
import { transformFullDateToMonthAndYear } from '../utils/functions'
// import TicksWrapper from './TicksWrapper'
import TableRowNew from './TableRowNew'
import { Spinner } from './_components'
// import { IconSettings } from './Icons'

const DataTableNew = ({
  data,
  filteredKeys,
  fetchedIndicators,
  fullDates,
  dataToCompareTo,
  activeIndicator,
  componentMode,
  onValuesClick,
  noCalcCondition,
  showOther,
  readOnly,
  rangeSelected,
  dataMode,
  selectedDataMappedMode,
  tableMode
}: IDataMappedComponentProps) => {
  if (
    data === undefined ||
    data.data.length === 0 ||
    !fetchedIndicators ||
    !fullDates ||
    !filteredKeys ||
    filteredKeys.length === 0
  ) {
    return (
      <Spinner
        options={{
          customMessage: 'No data to display',
          noSpinner: true,
          noDots: true,
          noTransform: true
        }}
        customStyles={{
          marginTop: '10px'
        }}
      />
    )
  }

  const tableBodyRef = useRef<HTMLTableSectionElement>(null)
  const selectedColumns = useMemo(
    () => window.globalSettings.data_mapped_selected_columns,
    [window.globalSettings.data_mapped_selected_columns]
  )

  // useEffect(() => {
  //   if (tableBodyRef.current) {
  //     // Ensure the element has rendered before scrolling
  //     setTimeout(() => {
  //       // Scroll to the right of the table
  //       tableBodyRef.current!.scrollLeft = tableBodyRef.current!.scrollWidth
  //     }, 500)
  //   }
  // }, [])

  return (
    <table className={tableMode === 'mini' ? 'data-table mini' : 'data-table'}>
      <thead>
        <tr id="header">
          {tableMode !== 'mini' && <th className="left options icon "></th>}
          {selectedColumns.includes('base') && tableMode === 'full' && (
            <th className="large options base left">{'Base'}</th>
          )}
          {selectedColumns.includes('title') && (
            <th className="large options base left">Title</th>
          )}
          {selectedColumns.includes('from') && tableMode === 'full' && (
            <th className="small options left">From</th>
          )}
          {selectedColumns.includes('to') && tableMode === 'full' && (
            <th className="small options left">To</th>
          )}
          {selectedColumns.includes('mode') && (
            <th className="options left">Mode</th>
          )}
          {selectedColumns.includes('state') && (
            <th className="small options left">Abs/rel</th>
          )}
          {selectedColumns.includes('inverse') && (
            <th className="small options left">Inverse</th>
          )}
          {selectedColumns.includes('deviation') && (
            <th className="options left">Deviation</th>
          )}
          {selectedColumns.includes('links') && (
            <th className="small options"># Links</th>
          )}
          {data.data[0].dates.map((date: string, index: number) => (
            <th
              className="value left"
              key={`${transformFullDateToMonthAndYear(date)}_${index}`}
            >
              <span>{transformFullDateToMonthAndYear(date)}</span>
            </th>
          ))}
        </tr>
      </thead>
      <tbody className="default-text" ref={tableBodyRef}>
        {[
          'Calculated',
          'Values',
          'Forecasts',
          'Deviations',
          showOther ? 'Other' : ''
        ]
          .filter((section) => section !== '')
          .flatMap((section) => [
            data.data.map((entry: IMappedDataEntry, index: number) => {
              let indicator = fetchedIndicators.find(
                (ind) => ind.fid === entry.title
              )

              if (
                !indicator &&
                showOther &&
                filteredKeys.includes(entry.title)
              ) {
                // this is a workaround for the case whenthere is no indicator
                indicator = {
                  type: 'other',
                  title: entry.title
                } as ITrendline
              }

              if (indicator && indicator.title.includes('Dev.')) {
                indicator = {
                  ...indicator,
                  type: 'other'
                }
              }

              // Determine if the current entry should be shown based on the section
              const shouldDisplay = (() => {
                if (!indicator) return false
                if (
                  (noCalcCondition !== undefined &&
                    noCalcCondition(entry.title)) ||
                  !filteredKeys.includes(entry.title)
                ) {
                  return false
                }

                switch (section) {
                  case 'Values':
                    return (
                      indicator.type === 'actuals' ||
                      indicator.type === 'indicator'
                    )
                  case 'Forecasts':
                    return indicator.type === 'forecast'
                  case 'Deviations':
                    return indicator.type === 'trendline'
                  case 'Calculated':
                    return indicator.type === 'calculated'
                  case 'Other':
                    return indicator.type === 'other'
                  default:
                    return false
                }
              })()

              if (!shouldDisplay) {
                return null
              }

              const isActive = indicator
                ? indicator.fid === activeIndicator
                : false

              // taking care of the edge case when the indicator is a trendline and the component mode is trendline
              if (
                indicator &&
                componentMode === 'trendline' &&
                showOther &&
                indicator.title.includes('Dev.')
              ) {
                indicator = {
                  ...indicator,
                  type: 'other'
                }
              }

              return (
                <TableRowNew
                  key={`${section}-${index}`}
                  isActiveMode={isActive}
                  data={entry}
                  tableMode={tableMode}
                  dates={fullDates}
                  indicatorParameterFetched={
                    indicator as
                      | IBasicIndicator
                      | ITrendline
                      | ICalculatedIndicator
                      | IForecast
                  }
                  selectedColumns={selectedColumns}
                  dataMode={tableMode === 'mini' ? 'deviations' : dataMode}
                  fullDates={fullDates}
                  selectedDataMappedMode={selectedDataMappedMode}
                  onValuesClick={
                    onValuesClick && !readOnly
                      ? () => onValuesClick(entry.title)
                      : undefined
                  }
                  noCalc={
                    noCalcCondition !== undefined
                      ? noCalcCondition(entry.title)
                      : false
                  }
                  componentMode={componentMode}
                  readOnly={readOnly}
                />
              )
            })
          ])}
      </tbody>
    </table>
  )
}

export default DataTableNew
