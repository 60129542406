import React, { useCallback, useState } from 'react'
import {
  ICollection,
  ICollectionEntry,
  ICollectionPage
} from '../utils/interfaces'
import {
  deleteCollectionById,
  fetchedCollectionById,
  modifyCollectionEntries,
  updateCollectionNameAndDescription
} from '../utils/fetch'
import { capitalise } from '../utils/functions'
import {
  TabbingSwitch,
  AccessDenied,
  FunctionalButton,
  Spinner,
  EntityHeader
} from '../helperComponents/_components'
import AdvancedSearch from '../helperComponents/AdvancedSearch'
import CustomContextMenu from '../helperComponents/CustomContextMenu'
import PopupModal from '../helperComponents/PopUpModal'
import EntityTags from '../helperComponents/EntityTags'
import { Icon3Dots } from '../helperComponents/Icons'

const CollectionPage = ({
  collectionId,
  scenarios,
  indicators,
  collections,
  favourites,
  forecast,
  userTags,
  memos,
  channelContent,
  trendlines
}: ICollectionPage) => {
  try {
    const userId = localStorage.getItem('userId') || ''
    const [detailsTabMode, setDetailsTabMode] = useState('entity')
    const [collection, setCollection] = React.useState({} as ICollection)
    const [loading, setLoading] = React.useState<boolean>(false)
    const [newDescription, setNewDescription] = useState<string>('')
    const [newShortDescription, setNewShortDescription] = useState<string>('')
    const [newName, setNewName] = useState<string>('')
    const [
      modalCollectionNameAndDescription,
      setModalCollectionNameAndDescription
    ] = useState(false)

    const [modalDeleteCollection, setModalDeleteCollection] =
      React.useState(false)
    const [isCurrentCollectionFavourite, setIsCurrentCollectionFavourite] =
      useState(false)
    const defaultContextMenuSettings = {
      top: 0,
      left: 0,
      show: false
    }
    const [contextMenuDetails, setContextMenuDetails] = useState(
      defaultContextMenuSettings
    )
    const [collectionIndicators, setCollectionIndicators] = React.useState<
      ICollectionEntry[]
    >([])
    const [collectionScenarios, setCollectionScenarios] = React.useState<
      ICollectionEntry[]
    >([])
    const [collectionCollections, setCollectionCollections] = React.useState<
      ICollectionEntry[]
    >([])
    const [ownerInfo, setOwnerInfo] = useState({
      profileImage: '',
      username: ''
    })
    const [collectionCalculatedIndicators, setCollectionCalculatedIndicators] =
      React.useState<ICollectionEntry[]>([])

    const resetModals = () => {
      window.collapseSidePanel(true)
      setModalDeleteCollection(false)
      setModalCollectionNameAndDescription(false)
      setContextMenuDetails(defaultContextMenuSettings)
      // setContextMenuRelations(defaultContextMenuSettings);
    }

    const checkIfCollectionIsFavourite = () => {
      if (favourites === undefined) {
        return false
      }

      const isFavourite =
        favourites.collections.length > 0 &&
        favourites.collections.some(
          (item: ICollectionEntry) => item.id === collectionId.toString()
        )

      return isFavourite
    }

    const handleContextMenuDetails = (e: any) => {
      e.preventDefault()

      if (contextMenuDetails.show) {
        setContextMenuDetails(defaultContextMenuSettings)
        return
      }

      setContextMenuDetails({
        show: true,
        top: e.pageY,
        left: e.pageX - 210
      })
    }

    const resetContextMenuDetails = () => {
      if (contextMenuDetails.show) {
        setContextMenuDetails(defaultContextMenuSettings)
      }
    }

    const fetchCollection = useCallback(
      async (collectionId: number, noClose?: boolean) => {
        if (!noClose) {
          setLoading(true)
          resetModals()
        }

        setDetailsTabMode('entity')
        const response = await fetchedCollectionById({
          collectionId,
          userId
        })

        if (!response) {
          if (!noClose) {
            setLoading(false)
          }
          return
        }

        const {
          indicators,
          scenarios,
          calculated_indicators,
          collections,
          collectionDetails,
          owner
        } = response

        setCollection(collectionDetails)
        setCollectionIndicators(indicators)
        const isFavourite = checkIfCollectionIsFavourite()
        setIsCurrentCollectionFavourite(isFavourite)
        setNewName(collectionDetails.collection_name)
        setNewDescription(collectionDetails.details)
        setNewShortDescription(collectionDetails.short_description)
        setCollectionScenarios(scenarios)
        setCollectionCollections(collections)
        setCollectionCalculatedIndicators(calculated_indicators)
        setOwnerInfo({
          profileImage: owner.profile_image,
          username: owner.username
        })

        if (!noClose) {
          setLoading(false)
        }
      },
      [collectionId]
    )

    const undoNamingChanges = () => {
      setNewName(collection.collection_name)
      setNewDescription(collection.details)
      setNewShortDescription(collection.short_description)
      resetModals()
    }

    const getCollectionNoClose = () => {
      if (loading) return
      fetchCollection(collectionId, true)
    }

    React.useEffect(() => {
      if (loading) return

      fetchCollection(collectionId)
    }, [collectionId])

    if (loading) {
      return <Spinner />
    }

    if (!collection && !loading) {
      return (
        <div className="entity-page new">
          <h4 className="subtitle">Collection</h4>
          <h1 className="title">Collection not found</h1>
        </div>
      )
    }

    return (
      <div className="entity collection new">
        <EntityHeader
          subtitle="Collection"
          ownerInfo={ownerInfo}
          title={newName}
          description={newShortDescription}
        />
        <div className="entity-block-1">
          <div className="config">
            <div
              className="entity-parameters"
              onClick={resetContextMenuDetails}
            >
              <TabbingSwitch
                options={[
                  {
                    label: 'Entity',
                    active: detailsTabMode === 'entity',
                    onClick: () => setDetailsTabMode('entity')
                  },
                  {
                    label: 'Search',
                    active: detailsTabMode === 'search',
                    onClick: () => setDetailsTabMode('search')
                  },
                  {
                    label: 'Description',
                    active: detailsTabMode === 'description',
                    onClick: () => setDetailsTabMode('description')
                  },
                  {
                    label: 'Tags',
                    active: detailsTabMode === 'tags',
                    onClick: () => setDetailsTabMode('tags')
                  }
                ]}
                numberVisible={6}
                fit
                className="no-border col-3"
              />
              <button
                className="no-btn flex no-hover"
                onClick={handleContextMenuDetails}
              >
                <Icon3Dots />
              </button>
            </div>
            {detailsTabMode === 'description' && (
              <textarea
                className="description default-text p-2"
                contentEditable={false}
                readOnly
                value={newDescription || 'No description provided'}
              />
            )}
            {detailsTabMode === 'entity' && (
              <table className="collection-table">
                <thead>
                  <tr>
                    <th>Title</th>
                    <th>Details</th>
                    <th>Type</th>
                    <th>Remove</th>
                  </tr>
                </thead>
                <tbody>
                  {collection &&
                    collectionCollections.length > 0 &&
                    collectionCollections.map((collection, index) => (
                      <tr className="collection" key={index}>
                        <td
                          className="pointer"
                          onClick={() => {
                            window.switchFunctions.collection(collection.id)
                          }}
                        >
                          {capitalise(collection.name)}
                        </td>
                        <td>{collection.details}</td>
                        <td>Collection</td>
                        <td>
                          <FunctionalButton
                            className="danger"
                            initialButtonState={'Delete'}
                            functionToExecute={() =>
                              modifyCollectionEntries({
                                collectionId,
                                action: 'remove',
                                entityId: collection.id,
                                entityType: 'collection',
                                entityName: collection.name
                              })
                            }
                            refreshOnComplete={{
                              exists: true,
                              refreshFunction: getCollectionNoClose
                            }}
                            iconMinusMode
                          />
                        </td>
                      </tr>
                    ))}
                  {collection &&
                    collectionScenarios.length > 0 &&
                    collectionScenarios.map((scenario, index) => (
                      <tr className="scenario" key={index}>
                        <td
                          className="pointer"
                          onClick={() => {
                            window.switchFunctions.scenario(scenario.id)
                          }}
                        >
                          {capitalise(scenario.name)}
                        </td>
                        <td>{scenario.details}</td>
                        <td>Scenario</td>
                        <td>
                          <FunctionalButton
                            className="danger"
                            initialButtonState={'Delete'}
                            functionToExecute={() =>
                              modifyCollectionEntries({
                                collectionId,
                                action: 'remove',
                                entityId: scenario.id,
                                entityType: 'scenario',
                                entityName: scenario.name
                              })
                            }
                            iconMinusMode
                            refreshOnComplete={{
                              exists: true,
                              refreshFunction: getCollectionNoClose
                            }}
                          />
                        </td>
                      </tr>
                    ))}
                  {collection &&
                    collectionCalculatedIndicators.length > 0 &&
                    collectionCalculatedIndicators.map((indicator, index) => (
                      <tr className="calculatedIndicator" key={index}>
                        <td
                          className="pointer"
                          onClick={() => {
                            window.switchFunctions.calculated(indicator.id)
                          }}
                        >
                          {capitalise(indicator.name)}
                        </td>
                        <td>{indicator.details}</td>
                        <td>Calculated</td>
                        <td>
                          <FunctionalButton
                            className="danger"
                            initialButtonState={'Delete'}
                            functionToExecute={() =>
                              modifyCollectionEntries({
                                collectionId,
                                action: 'remove',
                                entityId: indicator.id,
                                entityType: 'calculated',
                                entityName: indicator.name
                              })
                            }
                            iconMinusMode
                            refreshOnComplete={{
                              exists: true,
                              refreshFunction: getCollectionNoClose
                            }}
                          />
                        </td>
                      </tr>
                    ))}
                  {collection &&
                    collectionIndicators.length > 0 &&
                    collectionIndicators.map((indicator, index) => (
                      <tr className="indicator" key={index}>
                        <td
                          className="pointer"
                          onClick={() => {
                            window.switchFunctions.indicator(indicator.id)
                          }}
                        >
                          {capitalise(indicator.name)}
                        </td>
                        <td>{indicator.details}</td>
                        <td>Indicator</td>
                        <td>
                          <FunctionalButton
                            className="danger"
                            initialButtonState={'Delete'}
                            functionToExecute={() =>
                              modifyCollectionEntries({
                                collectionId,
                                action: 'remove',
                                entityId: indicator.id,
                                entityType: 'indicator',
                                entityName: indicator.name
                              })
                            }
                            iconMinusMode
                            refreshOnComplete={{
                              exists: true,
                              refreshFunction: getCollectionNoClose
                            }}
                          />
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            )}
            {detailsTabMode === 'search' && (
              <AdvancedSearch
                scenarios={scenarios}
                channelsContent={channelContent}
                indicators={indicators}
                collections={collections}
                forecast={forecast}
                addModeParameters={{
                  collectionId,
                  refreshFunction: getCollectionNoClose
                }}
                trendlines={trendlines}
                memos={memos}
              />
            )}
            {detailsTabMode === 'tags' && (
              <div className="description flex-row col-12">
                <div className="entity-info-block col-12 default-text">
                  {userTags && collectionId && (
                    <EntityTags
                      type="collection"
                      allTags={userTags}
                      entityTags={collection.tags || []}
                      entityId={collectionId.toString()}
                      functionRefresh={getCollectionNoClose}
                    />
                  )}
                </div>
              </div>
            )}
          </div>

          <br />
        </div>
        <CustomContextMenu
          contextMenuSettings={contextMenuDetails}
          menu={[
            {
              onClick: () => {
                resetModals()
                setModalCollectionNameAndDescription(true)
              },
              title: 'Edit Details'
            },
            {
              onClick: () =>
                modifyCollectionEntries({
                  entityType: 'collection',
                  entityId: collectionId.toString(),
                  action: isCurrentCollectionFavourite ? 'remove' : 'add',
                  entityName: newName,
                  collectionId: favourites === undefined ? 0 : favourites.id
                }),
              title: isCurrentCollectionFavourite
                ? 'Remove from My Library'
                : 'Add to My Library',
              functionalButtonSettings: {
                exists: true,
                refreshFunction: getCollectionNoClose,
                doesReset: true
              }
            },
            {
              onClick: () => {
                resetModals()
                setModalDeleteCollection(true)
              },
              title: 'Delete'
            }
          ]}
        />
        {modalCollectionNameAndDescription && (
          <PopupModal
            isOpen={modalCollectionNameAndDescription}
            onClose={undoNamingChanges}
            title="Edit Scenario Details"
            size="large"
            handleSubmit={() => undefined}
            noChanges={
              newName === collection.collection_name &&
              newDescription === collection.details &&
              newShortDescription === collection.short_description
            }
            inner
          >
            <input
              className="input center middle flex col-12"
              type="text"
              value={newName}
              onChange={(e) => setNewName(e.target.value)}
              placeholder="Collection name"
            />
            <textarea
              rows={5}
              value={newShortDescription}
              onChange={(e) => setNewShortDescription(e.target.value)}
              placeholder="Collection description"
            />
            <textarea
              rows={10}
              value={newDescription}
              onChange={(e) => setNewDescription(e.target.value)}
              placeholder="Collection description"
            />

            <div className="flex gap-3">
              <button
                className="no-btn border center middle full"
                onClick={undoNamingChanges}
              >
                Undo
              </button>
              <FunctionalButton
                className="primary border center middle full"
                functionToExecute={() =>
                  updateCollectionNameAndDescription({
                    collectionId,
                    collectionName: newName,
                    collectionDescription: newDescription,
                    collectionShortDescription: newShortDescription
                  })
                }
                initialButtonState="Save"
                iconSaveMode
                combinedButtonTitle
                disabled={newName === ''}
                refreshOnComplete={{
                  exists: true,
                  refreshFunction: () =>
                    setModalCollectionNameAndDescription(false)
                }}
              />
            </div>
          </PopupModal>
        )}
        {modalDeleteCollection && (
          <PopupModal
            isOpen={modalDeleteCollection}
            onClose={() => setModalDeleteCollection(false)}
            title="Are you sure you want to delete this trendline?"
            size="small"
            handleSubmit={() => undefined}
            saveButtonExists={false}
            inner
          >
            <div className="flex-row center middle margin-1">
              <FunctionalButton
                className="destructive"
                functionToExecute={async () =>
                  await deleteCollectionById(collectionId)
                }
                initialButtonState={'Delete'}
                refreshOnComplete={{
                  exists: true,
                  refreshFunction: () =>
                    window.switchFunctions.deleted(collectionId)
                }}
              />
            </div>
          </PopupModal>
        )}
      </div>
    )
  } catch (error) {
    return <AccessDenied />
  }
}

export default CollectionPage
