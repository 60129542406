import React from 'react'
import {
  EntityHeader
  // SkeletonLoader
} from '../helperComponents/_components'
import EntitiesTable from '../helperComponents/EntitiesTable'

const WorkspaceHomepage = ({
  entities
}: {
  entities: {
    fid: string | number
    title: string
    type: string
  }[]
}) => {
  return (
    <div className="entity homepage">
      <EntityHeader
        ownerInfo={{
          profileImage: '',
          username: 'You'
        }}
        title="Workspace Homepage"
        description="This is your workspace homepage. Search and add content to your library to work with it withing this space"
        subtitle="Search"
      />

      <div className="entity-blocks">
        <div className="container w-12 full">
          <div className="entity-data">
            <div className="entity-parameters">
              <span className="px-2">Your Workspaces</span>
            </div>
            <EntitiesTable entities={entities} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default WorkspaceHomepage
