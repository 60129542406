import React, { useEffect, useMemo, useState } from 'react'
import {
  IIndicatorsAndTrendlinesSearch,
  IIndicatorsAndTrendlinesSearchResult
} from '../utils/interfaces'
import { FunctionalButton } from './_components'
import iconClose from '../assets/icon-close.png'
import { IconChevronUpDown } from './Icons'
import { createNewTrendline } from '../utils/fetch'

const IndicatorsAndTrendlinesSearch = ({
  indicators,
  includedIndicators,
  refreshIndicators,
  addFunction,
  removeFunction,
  altFunction,
  direction,
  height
}: IIndicatorsAndTrendlinesSearch) => {
  const [search, setSearch] = useState('')
  const [expandedKeys, setExpandedKeys] = useState<string[]>([])

  const allIndicators = useMemo(() => {
    return indicators
      .filter((indicator) => !Object.keys(indicator).includes('base_indicator'))
      .map((indicator) => ({
        id: indicator.fid,
        title: indicator.title,
        trendlines: indicators
          .filter((indicator) =>
            Object.keys(indicator).includes('base_indicator')
          )

          .filter(
            (trendline) =>
              Object.keys(trendline).includes('base_indicator') &&
              trendline.base_indicator === indicator.fid
          )
          .map((trendline) => ({
            id: trendline.fid,
            title: trendline.title
          }))
      }))
  }, [indicators])

  const [filteredResults, setFilteredResults] =
    useState<IIndicatorsAndTrendlinesSearchResult[]>(allIndicators)

  const handleCreateTrendline = async (indicatorId: string) => {
    const res = await createNewTrendline({
      indicatorFid: indicatorId,
      sourceInfo: {
        source: 'indicator',
        source_fid: indicatorId
      }
    })

    if (!res) {
      return false
    }

    window.switchFunctions.trendline(res)
    return true
  }

  useEffect(() => {
    if (search.toLowerCase() === 'all') {
      setFilteredResults(allIndicators)
      return
    }

    if (search.length > 0 && search.toLowerCase() !== 'all') {
      const searchTerm = search.toLowerCase()
      const foundInIndicators = allIndicators.filter((item) =>
        item.title.toLowerCase().includes(searchTerm)
      )
      const foundInTrendlines = allIndicators.filter((item) =>
        item.trendlines
          .map((trendline) => trendline.title.toLowerCase())
          .includes(searchTerm)
      )

      setFilteredResults(
        [...foundInIndicators, ...foundInTrendlines].filter(
          (value, index, self) =>
            self.findIndex((item) => item.id === value.id) === index
        )
      )
    } else {
      setFilteredResults([])
    }
  }, [search])

  return (
    <div className={`col-12 indicators-search ${direction}`}>
      <input
        type="text"
        value={search}
        onChange={(e) => setSearch(e.target.value)}
        className={
          'indicators-search-input' + (search.length > 0 ? ' active' : '')
        }
        placeholder="Search by name or type 'all'"
      />
      {search.length > 0 && (
        <button className="inline search-reset no-btn no-hover">
          <img
            src={iconClose}
            alt=""
            onClick={() => setSearch('')}
            className="icon inline"
          />
        </button>
      )}
      {search.length > 0 && (
        <div
          className="indicators-search-results"
          style={{
            height: height || '55vh'
          }}
        >
          {filteredResults.length === 0 && (
            <div className="indicators-search-result">
              <span>No results</span>
            </div>
          )}
          {filteredResults.length !== 0 &&
            filteredResults.map((item, index) => (
              <div
                className="indicators-search-result"
                key={item.id + '_' + index}
              >
                <div
                  className="result pointer"
                  onClick={() => {
                    item.trendlines.length !== 0 &&
                      setExpandedKeys((prev) =>
                        prev.includes(item.id)
                          ? prev.filter((key) => key !== item.id)
                          : [...prev, item.id]
                      )
                  }}
                >
                  <div className="flex gap-1">
                    <FunctionalButton
                      functionToExecute={() => addFunction(item.id)}
                      initialButtonState={'Add'}
                      disabled={
                        includedIndicators === undefined ||
                        includedIndicators.includes(item.id)
                      }
                      iconPlusMode
                    />
                    <FunctionalButton
                      functionToExecute={() => removeFunction(item.id)}
                      initialButtonState={'Remove'}
                      disabled={
                        includedIndicators === undefined ||
                        !includedIndicators.includes(item.id)
                      }
                      iconMinusMode
                    />
                  </div>
                  <span>{item.title}</span>
                  {item.trendlines.length > 0 && (
                    <button className="no-btn no-hover icon">
                      <IconChevronUpDown
                        open={expandedKeys.includes(item.id)}
                      />
                    </button>
                  )}
                </div>
                <div className="subresults">
                  {expandedKeys.includes(item.id) &&
                    item.trendlines.map((trendline) => (
                      <div className="subresult" key={trendline.id}>
                        <span>
                          <FunctionalButton
                            disabled={
                              includedIndicators === undefined ||
                              includedIndicators.includes(trendline.id)
                            }
                            iconPlusMode={true}
                            initialButtonState={'Add'}
                            functionToExecute={() => addFunction(trendline.id)}
                          />
                          <FunctionalButton
                            disabled={
                              includedIndicators === undefined ||
                              !includedIndicators.includes(trendline.id)
                            }
                            iconMinusMode={true}
                            initialButtonState={'Remove'}
                            functionToExecute={() =>
                              removeFunction(trendline.id)
                            }
                          />
                        </span>
                        <span>{trendline.title}</span>
                      </div>
                    ))}
                  {expandedKeys.includes(item.id) && (
                    <div className="subresult">
                      <span>
                        <FunctionalButton
                          functionToExecute={() =>
                            handleCreateTrendline(item.id)
                          }
                          className="col-12 no-btn no-hover"
                          doesReset
                          initialButtonState={'Generate Trendline'}
                          iconPlusMode
                          combinedButtonTitle
                        />
                      </span>
                    </div>
                  )}
                </div>
              </div>
            ))}
        </div>
      )}
    </div>
  )
}

export default IndicatorsAndTrendlinesSearch
