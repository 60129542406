import { Checkbox } from 'pretty-checkbox-react'
import React, { useMemo } from 'react'
import {
  IAllIndicatorsTableDisplay,
  IEntityAllKeys,
  ITrendline
} from '../utils/interfaces'

import { capitalise } from '../utils/functions'
import { FunctionalButton } from './_components'

const AllIndicatorsTableDisplay = ({
  allKeys,
  entityDataKeys,
  entityChartKeys,
  filterEntityDataKeys,
  filterEntityChartKeys,
  allIndicators,
  scenarioSpecifcAddRemoveButtons,
  onlyIndicators,
  disabledCondition,
  noCheckboxes
}: IAllIndicatorsTableDisplay) => {
  const ids = useMemo(() => {
    let keys = []
    if (onlyIndicators) {
      keys = allKeys
        // .filter((item) => item.base_indicator === undefined)
        .map((item) => item.title)
    } else {
      keys = allKeys.map((item) => item.base_indicator || item.title)
    }

    return keys.filter((value, index, self) => self.indexOf(value) === index)
  }, [allKeys])

  const groups = useMemo(() => {
    return allKeys
      .map((item) => item.type)
      .filter((value, index, self) => self.indexOf(value) === index)
  }, [allKeys])

  const titles = useMemo(() => {
    const obj: any = {}
    allIndicators.forEach((item) => (obj[item.fid] = item.title))
    return obj
  }, [allIndicators])

  const isDisabled = (key: IEntityAllKeys) => {
    if (key.disabled) return true
    if (disabledCondition === undefined) return false
    return disabledCondition(key.title) && disabledCondition(key.base_indicator)
  }

  const isChecked = (
    key: IEntityAllKeys,
    groupKeys: IEntityAllKeys[] | undefined
  ) => {
    if (groupKeys === undefined) return false
    return (
      groupKeys.map((key) => key.title).includes(key.title) && !isDisabled(key)
    )
  }

  const filterBoth = (key: IEntityAllKeys) => {
    filterEntityDataKeys(key)
    filterEntityChartKeys(key)
  }

  return (
    <ol className="column-parent">
      {groups.map((type) => {
        const exists = allKeys.filter((item) => item.type === type).length !== 0

        if (!exists) return null

        const checkeOfType = entityChartKeys.filter(
          (item) => item.type === type
        )

        return (
          <li key={type + '-type'}>
            <h3 className="col-12 flex gap-2">
              <span className={checkeOfType.length === 0 ? 'disabled' : ''}>
                {capitalise(type)}{' '}
                <span className="small disabled">({checkeOfType.length})</span>
              </span>
            </h3>

            {!onlyIndicators && (
              <ol>
                {ids.map((indicator, index) => {
                  const keys = useMemo(() => {
                    return (
                      allKeys
                        .filter((item) => item.type === type)
                        .filter(
                          (item) =>
                            item.base_indicator === indicator ||
                            item.title === indicator
                        )
                        // filter out duplicates
                        .filter(
                          (value, index, self) =>
                            self.findIndex((t) => t.title === value.title) ===
                            index
                        )
                    )
                  }, [allKeys, type, indicator])

                  const allIndicatorsIncluded = useMemo(() => {
                    return allIndicators.filter(
                      (item) =>
                        Object.keys(item).includes('base_indicator') &&
                        (item as ITrendline).base_indicator === indicator &&
                        Object.keys(item).includes('source') &&
                        (item as ITrendline).source === 'indicator' &&
                        !keys.map((key) => key.title).includes(item.fid)
                    )
                  }, [allIndicators, indicator, keys])

                  if (keys.length === 0) return null
                  return (
                    <li key={indicator + '-category'}>
                      <h3>{titles[indicator]}</h3>
                      <ul>
                        {keys.map((item, index) => (
                          <li
                            key={
                              item.id + '_' + index + '-data-display-checkbox'
                            }
                            className="flex gap-2 col-12"
                          >
                            {scenarioSpecifcAddRemoveButtons && (
                              <FunctionalButton
                                initialButtonState={'Remove'}
                                functionToExecute={() =>
                                  scenarioSpecifcAddRemoveButtons.remove(
                                    item.title
                                  )
                                }
                                iconMinusMode
                              />
                            )}
                            <div className="flex middle center">
                              {!noCheckboxes && (
                                <Checkbox
                                  checked={isChecked(item, entityDataKeys)}
                                  disabled={isDisabled(item)}
                                  onChange={() => filterBoth(item)}
                                  color="success-o"
                                  animation="pulse"
                                  shape="curve"
                                >
                                  Show/hide
                                </Checkbox>
                              )}
                              <button
                                className="no-btn no-hover no-border auto p-0"
                                onClick={() =>
                                  item.type === 'calculated'
                                    ? window.switchFunctions.calculated(
                                        item.title
                                      )
                                    : item.type === 'forecast'
                                      ? window.switchFunctions.forecast(
                                          item.title
                                        )
                                      : item.type === 'trendline'
                                        ? window.switchFunctions.trendline(
                                            item.title
                                          )
                                        : window.switchFunctions.indicator(
                                            item.title
                                          )
                                }
                              >
                                {item.name}
                              </button>
                            </div>
                          </li>
                        ))}
                        {allIndicatorsIncluded
                          .filter((item) => item.type === type)
                          .map((item, index) => (
                            <li
                              key={
                                item.fid +
                                '_' +
                                index +
                                '-data-display-checkbox'
                              }
                              className="flex gap-2 col-12"
                            >
                              {scenarioSpecifcAddRemoveButtons && (
                                <FunctionalButton
                                  iconPlusMode={true}
                                  initialButtonState={'Add'}
                                  functionToExecute={() =>
                                    scenarioSpecifcAddRemoveButtons.add(
                                      item.fid
                                    )
                                  }
                                />
                              )}
                              <span className="disabled">{item.title}</span>
                            </li>
                          ))}
                        {scenarioSpecifcAddRemoveButtons && (
                          <li className="flex gap-2 col-12">
                            <span
                              style={{
                                margin: '0px 0 0 -10px'
                              }}
                            >
                              <FunctionalButton
                                functionToExecute={() =>
                                  scenarioSpecifcAddRemoveButtons.generateTrendline(
                                    indicator
                                  )
                                }
                                className="no-btn no-hover wider"
                                doesReset
                                initialButtonState={'Generate Trendline'}
                                iconPlusMode
                                combinedButtonTitle
                              />
                            </span>
                          </li>
                        )}
                      </ul>
                    </li>
                  )
                })}
              </ol>
            )}
            {onlyIndicators && (
              <ul>
                {allKeys
                  .filter((item) => item.type === type)
                  .map((item, index) => (
                    <li
                      key={item.id + '_' + index + '-data-display-checkbox'}
                      className="flex gap-2 col-12"
                    >
                      {scenarioSpecifcAddRemoveButtons && (
                        <FunctionalButton
                          initialButtonState={'Remove'}
                          functionToExecute={() =>
                            scenarioSpecifcAddRemoveButtons.remove(item.title)
                          }
                          iconMinusMode
                        />
                      )}
                      {!noCheckboxes && (
                        <div className="flex middle center">
                          <Checkbox
                            checked={isChecked(item, entityChartKeys)}
                            onChange={() => filterBoth(item)}
                            color="success-o"
                            animation="pulse"
                            shape="curve"
                            disabled={isDisabled(item)}
                          >
                            Show/hide
                          </Checkbox>
                        </div>
                      )}
                      <button
                        className="no-btn no-hover no-border auto p-0"
                        onClick={() =>
                          item.type === 'calculated'
                            ? window.switchFunctions.calculated(item.title)
                            : item.type === 'forecast'
                              ? window.switchFunctions.forecast(item.title)
                              : item.type === 'trendline'
                                ? window.switchFunctions.trendline(item.title)
                                : window.switchFunctions.indicator(item.title)
                        }
                      >
                        {item.name}
                      </button>
                    </li>
                  ))}
              </ul>
            )}
          </li>
        )
      })}
    </ol>
  )
}

export default AllIndicatorsTableDisplay
