import React, { useCallback, useEffect, useRef, useState } from 'react'
import {
  getAllTrendlinesForExternalIndicator,
  getAPIData,
  modifyCollectionEntries,
  updateSelectedColumnsEntity
} from '../utils/fetch'
import {
  // ICollection,
  ICollectionEntry,
  IBasicIndicator,
  ITrendline,
  IEntityAllKeys,
  IIndicatorPage,
  IDataMapped
} from '../utils/interfaces'
import {
  TabbingSwitch,
  AccessDenied,
  FunctionalButton,
  Spinner,
  EntityHeader,
  ScrollTableButtons
} from '../helperComponents/_components'
import {
  exportToExcel,
  // findLowestFrequencyFromParams,
  generateIndicatorsKeysObjects,
  getAllValuesGroupedByKey,
  returnMergedByDate,
  transformFREDIndicatorsToPlatformIndicators
} from '../utils/transformingData'
import { HighChartsLinearChart } from '../charts/HighChartsReact'
import CustomContextMenu from '../helperComponents/CustomContextMenu'

import EntityTags from '../helperComponents/EntityTags'
import DataTableNew from '../helperComponents/DataTableNew'
import EntitySharingControls from '../helperComponents/EntitySharingControls'
import ChatbotComponent from '../helperComponents/ChatbotComponent'
import { jsPDF } from 'jspdf'
import { Icon3Dots } from '../helperComponents/Icons'
import {
  evaluateLocalStoragePageMode,
  findMatchPercentage,
  generateRandomId,
  isEmpty,
  transformFetchedRangedDetailsScenariotoRangeInputs
} from '../utils/functions'
import { Trendlines } from './Trendlines'
import PopupModalNew from '../helperComponents/PopUpModalNew'
import CreateTrendline from '../helperComponents/CreateTrendline'
import AllIndicatorsTableDisplay from '../helperComponents/AllIndicatorsTableDisplay'

const ExternalPage = ({
  indicatorId,
  userTags,
  readonly,
  favourites
}: IIndicatorPage) => {
  try {
    const files = useRef<File[] | null>(null)
    const [accessAllowed, setAccessAllowed] = React.useState(false)
    const fetchedTrendlines = useRef<ITrendline[]>([])
    const fullFetchedIndicatorsParameters = useRef<ITrendline[]>([])
    const fetchedDataTransformed = useRef({} as IDataMapped)
    const fetchedIndicatorData = useRef<any[]>([])
    const fullDates = useRef<string[]>([])
    const [trendlinesFetched, setTrendlinesFetched] = useState<boolean>(false)
    const readOnly = useRef<boolean>(!readonly)
    const entityAllKeys = useRef<IEntityAllKeys[]>([])
    const isCurrentIndicatorFavourite = useRef<boolean>(false)
    const defaultContextMenuSettings = useRef({
      top: 0,
      left: 0,
      show: false
    })
    const fetchedIndicatorParameters = useRef<IBasicIndicator | null>(null)
    const selectedDataBoundaries = useRef(['', ''])

    const [pageMode, setPageMode] = useState<
      'basic' | 'trendline' | 'forecast'
    >('basic')
    // const [dataMappedCellWidth, setDataMappedCellWidth] = useState<number>(50)
    const [detailsTabMode, setDetailsTabMode] = useState<string>('details')
    const [pageLoading, setPageLoading] = React.useState(false)

    const [contextMenuDetails, setContextMenuDetails] = useState(
      defaultContextMenuSettings.current
    )
    const [entityChartKeys, setEntityChartKeys] = useState<IEntityAllKeys[]>([])
    const [entityDataKeys, setEntityDataKeys] = useState<IEntityAllKeys[]>([])
    const [newName, setNewName] = useState<string>('')
    const [newDescription, setNewDescription] = useState<string>('')
    const [newShortDescription, setNewShortDescription] = useState<string>('')

    // const [ownerInfo, setOwnerInfo] = useState({
    //   profileImage: '',
    //   username: ''
    // })

    const resetContextMenuDetails = () => {
      if (contextMenuDetails.show) {
        setContextMenuDetails(defaultContextMenuSettings.current)
      }
    }

    const resetModals = () => {
      window.collapseSidePanel(true)
      localStorage.removeItem('openPopupId')
      setContextMenuDetails(defaultContextMenuSettings.current)
    }

    const handleContextMenuDetails = (e: React.MouseEvent) => {
      e.preventDefault()
      if (contextMenuDetails.show) {
        setContextMenuDetails(defaultContextMenuSettings.current)
        return
      }
      resetModals()
      setContextMenuDetails({
        show: true,
        top: e.pageY,
        left: e.pageX + 10
      })
    }

    const filterEntityKeysBasedOnPageMode = (keys: IEntityAllKeys[]) => {
      if (pageMode === 'trendline') {
        return keys.filter(
          (item) => item.type === 'trendline' || item.type === 'indicator'
        )
      } else if (pageMode === 'forecast') {
        return keys.filter((item) => item.type === 'forecast')
      }

      return keys.filter(
        (item) => item.type === 'actuals' || item.type === 'indicator'
      )
    }

    const filterEntityDataKeys = (key: IEntityAllKeys, chartMode?: boolean) => {
      if (chartMode) {
        setEntityChartKeys((prevKeys) => {
          if (prevKeys.map((item) => item.id).includes(key.id)) {
            return prevKeys.filter((item) => item.id !== key.id)
          } else {
            return [...prevKeys, key]
          }
        })
      } else {
        setEntityDataKeys((prevKeys) => {
          if (prevKeys.map((item) => item.id).includes(key.id)) {
            return prevKeys.filter((item) => item.id !== key.id)
          } else {
            return [...prevKeys, key]
          }
        })
      }
    }

    const filterEntityChartKeys = (key: IEntityAllKeys) => {
      setEntityChartKeys((prevKeys) => {
        if (prevKeys.map((item) => item.id).includes(key.id)) {
          return prevKeys.filter((item) => item.id !== key.id)
        } else {
          return [...prevKeys, key]
        }
      })
    }

    const getCurrentPage = () => {
      return window.visitedPages[window.currentPageIndex - 1]
    }

    const addToVisitedPages = ({
      type,
      fid,
      pageMode
    }: {
      type: string
      fid: string | null | number
      pageMode?: string
    }) => {
      const currentPage = getCurrentPage()
      if (currentPage && currentPage.fid === fid && currentPage.type === type) {
        if (isEmpty(pageMode)) {
          return
        } else if (pageMode === currentPage.pageMode) {
          return
        }
      }

      const currentIndex = window.currentPageIndex
      const visitedPages = window.visitedPages
      if (visitedPages.length === currentIndex) {
        window.currentPageIndex += 1
        window.visitedPages = [
          ...visitedPages,
          {
            type,
            fid,
            pageMode
          }
        ]
      } else {
        const newVisitedPages = [
          ...visitedPages.slice(0, currentIndex),
          {
            type,
            fid,
            pageMode
          }
        ]
        window.visitedPages = newVisitedPages
        window.currentPageIndex = newVisitedPages.length
      }
    }

    const handleSwitchPageMode = (
      selectedOption: 'basic' | 'trendline' | 'forecast'
    ) => {
      resetModals()
      if (selectedOption === 'basic') {
        // ensure the indicator key is included in the chart keys and data keys
        const indicatorKey = entityAllKeys.current.find(
          (item) => item.title === indicatorId
        )

        setTrendlinesFetched(false)

        if (indicatorKey) {
          setEntityChartKeys((prevKeys) => [...prevKeys, indicatorKey])
          setEntityDataKeys((prevKeys) => [...prevKeys, indicatorKey])
        }
        addToVisitedPages({
          fid: indicatorId,
          type: 'external',
          pageMode: selectedOption
        })
      } else {
        addToVisitedPages({
          fid: indicatorId,
          type: 'externalTrendlines',
          pageMode: selectedOption
        })
      }

      localStorage.setItem(
        'indicatorPageMode',
        `${selectedOption}---${indicatorId}`
      )
      setPageMode(selectedOption)

      return true
    }

    const checkIfIndicatorIsFavourite = () => {
      if (favourites === undefined) {
        return false
      }

      return (
        favourites.externals.length > 0 &&
        favourites.externals.some(
          (item: ICollectionEntry) => item.id === indicatorId
        )
      )
    }

    const fetchIndicator = useCallback(
      async (indicatorId: string, noClose?: boolean) => {
        try {
          if (!noClose) {
            resetModals()
            setPageLoading(true)
          }
          const [dataResponse, parametersResponse] = await Promise.all([
            getAPIData(`fred/series/observations?series_id=${indicatorId}`),
            getAPIData(`fred/series?series_id=${indicatorId}`)
          ])

          if (
            !dataResponse ||
            !parametersResponse ||
            !parametersResponse.seriess
          ) {
            setPageLoading(false)
            setAccessAllowed(false)
            return
          }
          const fetchedParameters: IBasicIndicator | null =
            transformFREDIndicatorsToPlatformIndicators(
              parametersResponse.seriess
            )[0]

          if (!fetchedParameters) {
            setPageLoading(false)
            setAccessAllowed(false)
            return
          }
          // const allParams = [fetchedParameters, ...trendlines.params]
          // const frequency = findLowestFrequencyFromParams(allParams)
          const externalData = dataResponse.observations.map(
            (observation: any) => ({
              date: observation.date,
              [indicatorId]: observation.value
            })
          )

          const isFavourite = checkIfIndicatorIsFavourite()
          const { allKeys, allDataKeys, allChartKeys } =
            generateIndicatorsKeysObjects({
              indicatorsParameters: [fetchedParameters as any],
              selectedChart: [indicatorId].join(', '),
              selectedData: [indicatorId].join(', '),
              dataMode: fetchedParameters.data_mode,
              backupVisibleKeys: [indicatorId]
            })

          // setOwnerInfo({
          //   profileImage: res.owner.profile_image,
          //   username: res.owner.username
          // })
          fetchedIndicatorParameters.current = fetchedParameters
          isCurrentIndicatorFavourite.current = isFavourite
          entityAllKeys.current = allKeys
          readOnly.current = !fetchedParameters.is_own
          selectedDataBoundaries.current =
            transformFetchedRangedDetailsScenariotoRangeInputs(
              fetchedParameters.range_chosen
            )
          fullDates.current = externalData.map((item: any) => item.date)
          fullFetchedIndicatorsParameters.current = [fetchedParameters as any]
          fetchedDataTransformed.current =
            getAllValuesGroupedByKey(externalData)
          fetchedIndicatorData.current = externalData

          setNewName(fetchedParameters.title)
          setNewDescription(fetchedParameters.meaning)
          setNewShortDescription(fetchedParameters.short_description)

          setEntityChartKeys(allChartKeys)
          setEntityDataKeys(allDataKeys)

          if (!noClose) {
            setPageLoading(false)
          }
          setAccessAllowed(true)
        } catch (err: any) {
          console.error(err)
          window.switchFunctions.home()
        }
      },
      [indicatorId]
    )

    const fetchTrendlines = async (force = false) => {
      const keys = Object.keys(fetchedIndicatorData.current[0]).filter(
        (key) => key !== 'date' && key !== 'id' && key !== indicatorId
      )
      const existingTrendlines = entityChartKeys
        .filter((key) => key.type === 'trendline')
        .map((key) => [`${key.title} Value`, `${key.title} Deviation`])
        .flat()
      const match = findMatchPercentage(keys, existingTrendlines)

      if ((match > 50 || trendlinesFetched) && !force) {
        setTrendlinesFetched(true)
        return
      }
      setPageLoading(true)

      const { params, data } =
        await getAllTrendlinesForExternalIndicator(indicatorId)
      fetchedTrendlines.current = params

      const mergedData = returnMergedByDate(
        fetchedIndicatorData.current,
        [data],
        true
      )

      const trendlinesIds: string[] = params.map((item: any) => item.fid)
      const { allKeys, allDataKeys, allChartKeys } =
        generateIndicatorsKeysObjects({
          indicatorsParameters: [
            ...fullFetchedIndicatorsParameters.current,
            ...params
          ],
          selectedChart: [indicatorId, ...trendlinesIds].join(', '),
          selectedData: [indicatorId, ...trendlinesIds].join(', '),
          dataMode: fullFetchedIndicatorsParameters.current[0].data_mode,
          backupVisibleKeys: [indicatorId]
        })

      fetchedDataTransformed.current = getAllValuesGroupedByKey(mergedData)
      fetchedIndicatorData.current = mergedData
      entityAllKeys.current = allKeys

      setEntityChartKeys(allChartKeys)
      setEntityDataKeys(allDataKeys)
      setPageLoading(false)
    }

    const refreshIndicator = () => {
      if (pageLoading) return

      fetchIndicator(indicatorId)
    }

    const refreshIndicatorNoClose = (contextMenu?: boolean) => {
      if (pageLoading) return
      fetchIndicator(indicatorId, true).then(() => {
        if (contextMenu) {
          // setTimeout(() => {
          resetContextMenuDetails()
          // }, 500)
        }
      })
    }

    // const applyMappedCellWidthChange = (e: any) => {
    //   if (
    //     e.target.value >= 20 &&
    //     e.target.value <= 150 &&
    //     e.target.value.toString().slice(0, 1) !== '0' &&
    //     !isNaN(e.target.value)
    //   ) {
    //     const value = e.target.value
    //     setDataMappedCellWidth(value)
    //     const tickWidth = 17 * (value / 50)

    //     // Assuming 'value' and 'tickWidth' are defined and have numeric types
    //     // 1. Set width for elements with classes .date-tick and .tick
    //     const ticks: any = document.querySelectorAll('.date-tick, .tick')
    //     ticks.forEach(
    //       (tick: { style: { width: string } }) =>
    //         (tick.style.width = `${value}px`)
    //     )

    //     // 2. Set min-width for elements with classes .date-tick and .tick
    //     ticks.forEach(
    //       (tick: { style: { minWidth: string } }) =>
    //         (tick.style.minWidth = `${value}px`)
    //     )

    //     // 3. Set padding-left to 0 for elements with the selector .ticks-entry .ticks span
    //     const spanElements: any = document.querySelectorAll(
    //       '.ticks-entry .ticks span'
    //     )
    //     spanElements.forEach(
    //       (span: { style: { paddingLeft: string } }) =>
    //         (span.style.paddingLeft = '0')
    //     )

    //     // 4. Set width for images inside elements with the class .tick
    //     const tickImages: any = document.querySelectorAll('.tick img')
    //     tickImages.forEach(
    //       (img: any) =>
    //         (img.style.width = `${tickWidth > 17 ? 17 : tickWidth}px`)
    //     )
    //   }
    // }

    const handleExportData = () => {
      try {
        if (
          !fetchedDataTransformed.current ||
          !fetchedDataTransformed.current.data ||
          !fetchedDataTransformed.current.data[0] ||
          fetchedIndicatorParameters === null ||
          !fetchedIndicatorParameters.current
        ) {
          return false
        }

        const dates = fetchedDataTransformed.current.data[0].dates
        const values = fetchedDataTransformed.current.data[0].values
        const data = values.map((item: any, index: number) => {
          return {
            date: dates[index],
            [(fetchedIndicatorParameters as any).current.title]: item
          }
        })
        exportToExcel(
          data,
          `${fetchedIndicatorParameters.current.title} - ${new Date()
            .toLocaleDateString()
            .split('/')
            .join('-')
            .slice(0, 10)}`
        )

        return true
      } catch (err: any) {
        console.error(err)
        return false
      }
    }

    const analyseWithAI = async () => {
      const exportName = `${newName}.pdf`

      const combineDataForExport = () => {
        if (
          !fetchedDataTransformed.current ||
          !fetchedIndicatorParameters.current
        ) {
          return null
        }

        const description =
          'File description: ' + fetchedIndicatorParameters.current?.meaning
        const data = fetchedDataTransformed.current.data.map((item: any) => {
          const titles = fullFetchedIndicatorsParameters.current.find(
            (indicator) => indicator.fid === item.title
          )
          const title = titles ? titles.title : item.title

          return {
            title,
            data: item.values
          }
        })
        return { description, data }
      }

      const combinedData = combineDataForExport()

      if (combinedData) {
        const doc = new jsPDF() // eslint-disable-line
        doc.setFontSize(12)

        doc.text(combinedData.description, 10, 10)

        let y = 20
        combinedData.data.forEach((item: any) => {
          doc.text(item.title + ': ' + JSON.stringify(item.data), 10, y)
          y += 10
        })

        const blob = doc.output('blob')
        const file = new File([blob], exportName, {
          type: 'application/pdf'
        })

        files.current = [file]
        setDetailsTabMode('ai')

        return true
      } else {
        return false
      }
    }

    const handleNewIndicator = async (indicatorId: string) => {
      if (pageLoading) return
      const pageMode = evaluateLocalStoragePageMode(indicatorId)
      if (indicatorId) {
        await fetchIndicator(indicatorId)
      }
      if (pageMode === 'trendline' && fetchedIndicatorData.current.length) {
        await fetchTrendlines()
      } else {
        setTrendlinesFetched(false)
      }
      setPageMode(pageMode)
    }

    const handleCurrentPageIndexChange = async (index: number) => {
      const currentPage = window.visitedPages[index - 1]
      if (
        currentPage.pageMode === 'trendline' &&
        fetchedIndicatorData.current.length
      ) {
        await fetchTrendlines()
      }
      if (
        currentPage.fid === indicatorId &&
        currentPage.pageMode !== pageMode
      ) {
        setPageMode((currentPage.pageMode as any) || 'basic')
      }
    }

    useEffect(() => {
      handleNewIndicator(indicatorId)
    }, [indicatorId])

    useEffect(() => {
      handleCurrentPageIndexChange(window.currentPageIndex)
    }, [window.currentPageIndex])

    if (pageLoading) return <Spinner />
    else if (!accessAllowed && !pageLoading) return <AccessDenied />
    else if (
      accessAllowed &&
      !pageLoading &&
      fetchedIndicatorParameters.current
    ) {
      return (
        <div className="entity external">
          <EntityHeader
            breadcrumbs={[
              {
                title: 'Indicator',
                onClick: () => handleSwitchPageMode('basic'),
                active: pageMode === 'basic'
              },
              ...(pageMode === 'trendline'
                ? [
                    {
                      title: 'Trendlines Analysis',
                      onClick: () => handleSwitchPageMode('trendline'),
                      active: pageMode === 'trendline'
                    }
                  ]
                : [])
            ]}
            subtitle="Indicator"
            ownerInfo={{ profileImage: null, username: '' }}
            title={newName}
            description={newShortDescription}
            titleClick={() => window.switchFunctions.external(indicatorId)}
          />
          <div className="entity-blocks">
            <div className="container w-12">
              <div className="entity-parameters">
                {!readOnly.current && pageMode === 'trendline' && (
                  <PopupModalNew
                    title="Create Trendline"
                    size="largest"
                    fid={generateRandomId()}
                    handleSubmit={() => undefined}
                    saveButtonExists={false}
                    inner
                    noChanges
                    className="p-0"
                    buttonFragment={{
                      props: {
                        className:
                          'inline no-btn default-text no-hover no-border m-2',
                        functionToExecute: () => undefined,
                        noReturn: true,
                        doesReset: true,
                        initialButtonState: 'Generate Trendline',
                        iconPlusMode: true
                      }
                    }}
                  >
                    <CreateTrendline
                      fetchedDataTransformed={
                        fetchedDataTransformed.current.data.filter(
                          (entry) => entry.title === indicatorId
                        )[0]
                      }
                      fetchedIndicatorParameters={
                        fetchedIndicatorParameters.current
                      }
                      indicatorId={indicatorId}
                      fullDates={fullDates.current}
                      source="external"
                      source_fid={indicatorId}
                      closeModal={resetModals}
                    />
                  </PopupModalNew>
                )}
                {!readOnly.current && pageMode !== 'trendline' && (
                  <FunctionalButton
                    className="inline no-btn default-text no-hover no-border"
                    fakeLoading
                    noReturn
                    functionToExecute={() => handleSwitchPageMode('trendline')}
                    doesReset
                    initialButtonState={'Trendlines Analysis'}
                    iconTrendline
                  />
                )}
                {!readOnly.current && pageMode !== 'basic' && (
                  <PopupModalNew
                    noChanges
                    size="large"
                    fid={generateRandomId()}
                    title="Add/remove indicators"
                    handleSubmit={async () =>
                      await updateSelectedColumnsEntity({
                        selectedChartList: [
                          ...entityChartKeys.map((item) => item.title),
                          indicatorId
                        ]
                          .filter(
                            (item, index, self) => self.indexOf(item) === index
                          )
                          .join(', '),
                        selectedDataList: [
                          ...entityDataKeys.map((item) => item.title),
                          indicatorId
                        ]
                          .filter(
                            (item, index, self) => self.indexOf(item) === index
                          )
                          .join(', '),
                        entityId: indicatorId,
                        entityType: 'indicator'
                      })
                    }
                    buttonFragment={{
                      props: {
                        className:
                          'inline no-btn default-text no-hover no-border',
                        noReturn: true,
                        functionToExecute: () => undefined,
                        doesReset: true,
                        initialButtonState: 'Change Selection',
                        iconListMode: true
                      }
                    }}
                    saveButtonExists
                    refreshOnComplete={{
                      exists: true,
                      refreshFunction: refreshIndicator
                    }}
                  >
                    <div className="flex-column center middle col-12 gap-3 indicators-view-panel">
                      <AllIndicatorsTableDisplay
                        allKeys={filterEntityKeysBasedOnPageMode(
                          entityAllKeys.current
                        )}
                        entityDataKeys={filterEntityKeysBasedOnPageMode(
                          entityDataKeys
                        )}
                        allIndicators={fullFetchedIndicatorsParameters.current}
                        entityChartKeys={filterEntityKeysBasedOnPageMode(
                          entityChartKeys
                        )}
                        filterEntityDataKeys={filterEntityDataKeys}
                        filterEntityChartKeys={filterEntityChartKeys}
                      />
                    </div>
                  </PopupModalNew>
                )}
                {pageMode === 'basic' && (
                  <button
                    className="inline no-btn default-text no-hover no-border"
                    onClick={handleContextMenuDetails}
                  >
                    <Icon3Dots />
                  </button>
                )}
              </div>
            </div>
            {pageMode === 'basic' && (
              <div
                className="container w-6 large no-scrollbar"
                onClick={resetContextMenuDetails}
              >
                <div className="entity-data">
                  <div className="entity-parameters">
                    <TabbingSwitch
                      options={[
                        {
                          label: 'Details',
                          onClick: () => setDetailsTabMode('details'),
                          active: detailsTabMode === 'details'
                        },
                        {
                          label: 'Data',
                          onClick: () => setDetailsTabMode('data'),
                          active: detailsTabMode === 'data',
                          exists: !readOnly.current
                        },
                        {
                          label: 'AI',
                          onClick: () => setDetailsTabMode('ai'),
                          active: detailsTabMode === 'ai'
                        },
                        {
                          label: 'Tags',
                          onClick: () => setDetailsTabMode('tags'),
                          active: detailsTabMode === 'tags',
                          exists: !readOnly.current
                        },
                        {
                          label: 'Sharing',
                          onClick: () => setDetailsTabMode('sharing'),
                          active: detailsTabMode === 'sharing',
                          exists: !readOnly.current
                        }
                      ]}
                      numberVisible={5}
                    />
                  </div>
                  {detailsTabMode === 'details' && (
                    <textarea
                      className="description default-text"
                      contentEditable={false}
                      readOnly
                      value={newDescription || 'No description provided'}
                    />
                  )}
                  <div
                    style={{
                      display: detailsTabMode === 'ai' ? 'block' : 'none'
                    }}
                    className="flex-row col-12"
                  >
                    <ChatbotComponent
                      page="entity"
                      instructions="Examine this document detailing the indicator alongside its trendlines and deliver a comprehensive analysis of its contents. Evaluate the significance of each trendlines values and conduct a comparative analysis among them. Spotlight any distinctive characteristics inherent in both the indicator and its trendlines."
                      assistantOn={true}
                      providedFiles={files.current}
                      clearFiles={() => (files.current = null)}
                    />
                  </div>
                  {detailsTabMode === 'tags' && (
                    <div className="description flex-row col-12">
                      <div className="entity-info-block col-12 default-text">
                        {!readOnly.current && (
                          <EntityTags
                            type="indicator"
                            allTags={userTags}
                            entityTags={
                              fetchedIndicatorParameters.current.tags || []
                            }
                            entityId={indicatorId}
                            functionRefresh={refreshIndicatorNoClose}
                          />
                        )}
                      </div>
                    </div>
                  )}
                  {detailsTabMode === 'sharing' && (
                    <div className="description flex-row col-12">
                      <div className="entity-info-block col-12 default-text">
                        <FunctionalButton
                          className="secondary inline"
                          functionToExecute={handleExportData}
                          disabled={readOnly.current}
                          initialButtonState={'Export Data'}
                        />
                        <EntitySharingControls
                          entityType="indicator"
                          entityId={indicatorId}
                          ChannelContentPiece={
                            fetchedIndicatorParameters.current
                              .ChannelContentPiece
                          }
                          refreshFunction={refreshIndicatorNoClose}
                          initialDescription={newDescription}
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )}
            {pageMode === 'basic' && (
              <div className="container w-6 large scroll no-scrollbar">
                <div className="entity-chart">
                  {/* {readOnly && <span>Chart Read Only</span>} */}
                  <HighChartsLinearChart
                    chartOptions={{
                      xAxisTitle: 'Month',
                      yAxisTitle: 'Value',
                      title: newName,
                      reactive: true,
                      mediumSize: true
                      // reactive: initialChartKeys !== entityChartKeys
                    }}
                    chartData={{
                      fetchedData: fetchedIndicatorData.current,
                      fullDates: fullDates.current,
                      titles: entityChartKeys.map((item: IEntityAllKeys) => ({
                        [item.title]: item.name
                      })),
                      filteredKeys: [indicatorId],
                      allKeys: [indicatorId]
                      // deviations: fullFetchedIndicatorsParameters
                    }}
                    componentOptions={{
                      own:
                        !readOnly.current &&
                        fetchedIndicatorParameters.current &&
                        !fetchedIndicatorParameters.current.admin_access,
                      parametersFetched: fetchedIndicatorParameters.current
                    }}
                  />
                </div>
              </div>
            )}
            {pageMode === 'basic' && (
              <div className="container w-12 fit">
                <div className="container w-12 no-borders flex entity-parameters">
                  <ScrollTableButtons />
                </div>
                <div
                  className="container w-12 scroll-x no-borders"
                  onClick={resetContextMenuDetails}
                >
                  <DataTableNew
                    tableMode="medium"
                    fullDates={fullDates.current}
                    readOnly={readOnly.current}
                    data={fetchedDataTransformed.current}
                    filteredKeys={[indicatorId]}
                    chartKeys={[indicatorId]}
                    fetchedIndicators={fullFetchedIndicatorsParameters.current}
                    dataMode={fetchedIndicatorParameters.current.data_mode}
                    componentMode="indicator"
                    showOther
                  />
                </div>
              </div>
            )}
            {pageMode === 'trendline' && (
              <Trendlines
                functions={{}}
                entityChartKeys={entityAllKeys.current}
                entityDataKeys={entityAllKeys.current}
                entityAllKeys={entityAllKeys.current}
                fullDates={fullDates.current}
                forecastsAssosiated={[]}
                selectedDataBoundaries={selectedDataBoundaries.current}
                data={fetchedIndicatorData.current}
                trendlines={fetchedTrendlines.current}
                actuals={fullFetchedIndicatorsParameters.current.filter(
                  (item) => item.fid === indicatorId
                )}
                readOnly={readOnly.current}
                userTags={userTags}
                allIndicators={[
                  ...fullFetchedIndicatorsParameters.current,
                  ...fetchedTrendlines.current
                ]}
                indicatorPageDependent={true}
              />
            )}
          </div>
          <CustomContextMenu
            contextMenuSettings={contextMenuDetails}
            menu={[
              {
                onClick: () => {
                  resetModals()
                  window.switchFunctions.upload()
                },
                title: 'Create/Clone Indicator',
                noAccess: readOnly.current
              },
              {
                onClick: () => analyseWithAI(),
                title: 'AI Analysis'
              },
              {
                onClick: () =>
                  modifyCollectionEntries({
                    entityType: 'external',
                    entityId: indicatorId,
                    action: isCurrentIndicatorFavourite ? 'remove' : 'add',
                    entityName: newName,
                    collectionId: favourites === undefined ? 0 : favourites.id
                  }),
                title: isCurrentIndicatorFavourite
                  ? 'Remove from My Library'
                  : 'Add to My Library',
                functionalButtonSettings: {
                  exists: true,
                  refreshFunction: () => refreshIndicatorNoClose(true),
                  doesReset: true
                },
                noAccess: !readOnly.current
              }
            ]}
          />
        </div>
      )
    } else return <AccessDenied />
  } catch (error) {
    console.error('Error rendering indicator page:', error)
    return <AccessDenied />
  }
}

export default ExternalPage
