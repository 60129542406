import React, { useState, useRef, useEffect, KeyboardEvent } from 'react'
import {
  // getFilesIds,
  getOpenaiAssistantResponse,
  getOpenaiChatResponse,
  getVectorStoreId
} from '../utils/fetch'
import { IOpenAIMessage } from '../utils/interfaces'
import { IconTick, IconUploadNew } from './Icons'
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'

interface ChatComponentProps {
  page?: 'entity' | 'side' | 'home' // Define the correct type for the 'page' prop
  assistantOn?: boolean
  providedFiles?: File[] | null
  clearFiles?: () => void
  instructions?: string
}

const ChatComponent: React.FC<ChatComponentProps> = ({
  page,
  assistantOn,
  providedFiles,
  instructions = 'Analyse this file containing information and provide insights as well as an in-depth analysis of the content',
  clearFiles
}) => {
  const [userInput, setUserInput] = useState('')
  const [uploadedFiles, setUploadedFiles] = useState<File[] | null>(null)
  const [chatId, setChatId] = useState('')
  const [assistantId, setAssistantId] = useState('')
  const [loading, setLoading] = useState(false)
  const [userMessages, setUserMessages] = useState<IOpenAIMessage[]>([
    {
      role: 'assistant',
      content: 'Hi there! How can I help?',
      type: 'text'
    }
  ])
  const messageListRef = useRef<HTMLDivElement>(null)

  const handleError = (error?: any) => {
    const errorMessage =
      error || 'Error occurred on the backend. Developers have been notified.'

    setUserMessages((prevMessages) => [
      ...prevMessages,
      { role: 'assistant', content: errorMessage, type: 'text' }
    ])
    setUserInput('')
  }

  const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files
    if (!files) return

    setUploadedFiles(Array.from(files))
  }

  const handleSubmit = async ({
    context,
    newInput,
    files,
    hiddenInstruction
  }: {
    context?: IOpenAIMessage[]
    newInput?: string
    files?: File[]
    hiddenInstruction?: string
  }) => {
    if ((!userInput.trim() && !files) || loading) return
    const newMessage = { role: 'user', content: userInput, type: 'text' }
    let newMessages = context || [...userMessages, newMessage]
    const newFiles = files || uploadedFiles
    setUserMessages(newMessages)
    if (hiddenInstruction) {
      newMessages = [
        ...newMessages.slice(0, newMessages.length - 1),
        {
          role: 'user',
          content: hiddenInstruction,
          type: 'text'
        }
      ]
    }

    if (assistantOn) {
      const input = newInput || userInput
      if (input.trim().length === 0 && !files) {
        return handleError()
      }

      // let files: string[] = []

      let data: any
      if (newFiles) {
        const vectorStoreId = await getVectorStoreId(newFiles)
        if (!vectorStoreId) {
          return handleError()
        }
        setUploadedFiles(null)
        data = await getOpenaiAssistantResponse({
          messages: newMessages,
          chatId,
          vectorStoreId: vectorStoreId as string,
          assistantId
        })
      } else {
        data = await getOpenaiAssistantResponse({
          messages: newMessages,
          chatId,
          assistantId
        })
      }

      if (!data || data.responseStatus === 'error') {
        return handleError()
      }

      setChatId(data.chatId)
      setAssistantId(data.assistantId)

      setUserMessages((prevMessages) => [
        ...prevMessages,
        { role: 'assistant', content: data.result, type: 'text' }
      ])

      setUserInput('')
    } else {
      try {
        const response = await getOpenaiChatResponse([
          ...userMessages,
          newMessage
        ])

        if (response) {
          setUserMessages((prevMessages) => [
            ...prevMessages,
            { role: 'assistant', content: response.result, type: 'text' }
          ])
        } else {
          handleError()
        }
      } catch (error) {
        handleError()
      }

      setUserInput('')
    }
    setLoading(false)
  }

  const handleEnter = (e: KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === 'Enter' && userInput.trim()) {
      e.preventDefault()
      handleSubmit({})
    }
  }

  useEffect(() => {
    if (providedFiles && !loading) {
      handleSubmit({
        context: [
          {
            role: 'user',
            content: 'Generating AI insights',
            type: 'text'
          }
        ],
        files: providedFiles,
        hiddenInstruction: instructions
      })
      setLoading(true)
      providedFiles = null
      clearFiles && clearFiles()
    }
  }, [providedFiles])

  useEffect(() => {
    if (messageListRef.current) {
      messageListRef.current.scrollTop = messageListRef.current.scrollHeight
    }
  }, [userMessages])

  return (
    <div className="gpt-new">
      <div
        className={`cloud ${page === 'home' ? 'home' : page === 'side' ? '' : 'entity'}`}
      >
        <div ref={messageListRef} className="messagelist">
          {userMessages.map((message, index) => {
            return (
              <div key={index}>
                <div
                  className={
                    message.role === 'user' && index === userMessages.length - 1
                      ? 'usermessagewaiting'
                      : message.role === 'assistant'
                        ? 'apimessage'
                        : 'usermessage'
                  }
                >
                  <div className="messageContent">
                    <ReactMarkdown remarkPlugins={[remarkGfm]}>
                      {message.content}
                    </ReactMarkdown>
                  </div>
                </div>
              </div>
            )
          })}
        </div>
      </div>

      <div className="input-area">
        {assistantOn && (
          <div className="file-upload">
            <label htmlFor="file-input">
              {uploadedFiles && uploadedFiles.length > 0 && <IconTick />}
              {(!uploadedFiles || uploadedFiles.length === 0) && (
                <IconUploadNew />
              )}
            </label>
            <input
              type="file"
              id="file-input"
              name="file-input"
              // allow multiple files
              multiple
              accept=".c, .cpp, .csv, .docx, .html, .java, .json, .md, .pdf, .php, .pptx, .py, .rb, .tex, .txt"
              // disabled={loading || gptOn || noEditMode || assistant === 'dall-e'}
              onChange={handleFileChange}
            />
          </div>
        )}
        <form
          onSubmit={(e) => {
            e.preventDefault()
            if (userInput.trim().length > 0) {
              const newContext = [
                ...userMessages,
                {
                  role: 'user',
                  content: userInput,
                  type: 'text'
                }
              ]
              handleSubmit({ context: newContext })
            }
          }}
          className="input-form"
        >
          <textarea
            onKeyDown={handleEnter}
            autoFocus={false}
            rows={userInput.length < 50 ? 1 : 3}
            maxLength={16000}
            id="userInput"
            name="userInput"
            placeholder={'Ask your ' + ' GPT' + ' question...'}
            value={userInput}
            onChange={(e) => setUserInput(e.target.value)}
            className="textarea"
          />
          <button type="submit" className="generatebutton">
            <svg
              viewBox="0 0 20 20"
              className="svgicon"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M10.894 2.553a1 1 0 00-1.788 0l-7 14a1 1 0 001.169 1.409l5-1.429A1 1 0 009 15.571V11a1 1 0 112 0v4.571a1 1 0 00.725.962l5 1.428a1 1 0 001.17-1.408l-7-14z"></path>
            </svg>
          </button>
        </form>
      </div>
    </div>
  )
}

export default ChatComponent
