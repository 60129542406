import React, { useState } from 'react'
import { FunctionalButton } from '../helperComponents/_components'
import { runAdminQuery } from '../utils/fetch'

const AdminPage: React.FC = () => {
  const [inputText, setInputText] = useState<string>('')
  const [outputText, setOutputText] = useState<string | any[]>()
  const [errorMessage, setErrorMessage] = useState<string>('')

  const handleButtonClick = (query: string) => {
    setInputText(query)
  }

  const handleRunQuery = async () => {
    try {
      const result = await runAdminQuery(inputText, true)

      if (result.visualRows.length !== 0) {
        const successTexts = result.visualRows
          .filter((item: any) => item.status === 'success')
          .map((item: any) => item.text)

        setOutputText(successTexts)
        setErrorMessage('')

        return true
      }
    } catch (error) {
      setOutputText('')
      setErrorMessage('wrong query')
    }
  }

  return (
    <div className="admin-page">
      <h1 className="flex center middle">Admin Page</h1>

      <div className="query-section">
        <div className="flex gap-3 p-1 middle">
          <span className="">Choose Query</span>
          <button
            className="primary wider"
            onClick={() =>
              handleButtonClick(
                "SELECT table_name FROM information_schema.tables WHERE table_schema = 'public';"
              )
            }
          >
            All tables
          </button>
          <button
            className="primary wider"
            onClick={() =>
              handleButtonClick(
                'SELECT title, meaning as description, fid as fid, type, range_complete, range_chosen_deviation, standard_deviation, standard_deviation_parameters, standard_deviation_relative, standard_deviation_chart_mode, inverse ' +
                  'FROM indicators_parameters ' +
                  'UNION ' +
                  'SELECT title, meaning as description, fid as fid, type, range_complete, range_chosen_deviation, standard_deviation, standard_deviation_parameters, standard_deviation_relative, standard_deviation_chart_mode, inverse ' +
                  'FROM indicators_parameters_calculated ' +
                  'UNION ' +
                  'SELECT title, meaning as description, fid as fid, type, range_complete, range_chosen_deviation, standard_deviation, standard_deviation_parameters, standard_deviation_relative, standard_deviation_chart_mode, inverse ' +
                  'FROM indicators_parameters_custom'
              )
            }
          >
            All records from all tables
          </button>
          <button
            className="primary wider"
            onClick={() =>
              handleButtonClick(
                "SELECT * FROM indicators_parameters_custom WHERE title = 'Reserves'"
              )
            }
          >
            All records by title
          </button>
        </div>
        <span className="p-1">Enter SQL query:</span>
        <textarea
          value={inputText}
          onChange={(e) => setInputText(e.target.value)}
          rows={inputText.length / 100 + 1}
          contentEditable={true}
          className="col-12 p-1"
        />
        <FunctionalButton
          functionToExecute={handleRunQuery}
          initialButtonState={'Run Query'}
          disabled={
            inputText.toLowerCase().includes('drop') ||
            inputText.toLowerCase().includes('delete') ||
            inputText.trim() === ''
          }
          className="primary wider p-1"
          doesReset
        />
        {errorMessage && (
          <p className="banner-strip danger col-3">{errorMessage}</p>
        )}
      </div>

      <div className="result-section">
        <span>Result:</span>
        <pre>{JSON.stringify(outputText, null, 2)}</pre>
      </div>
    </div>
  )
}

export default AdminPage
