import React, { useState } from 'react'
import { ReactTyped } from 'react-typed'
import OwlCarousel from 'react-owl-carousel'
import 'owl.carousel/dist/assets/owl.carousel.css'
import 'owl.carousel/dist/assets/owl.theme.default.css'

const MarketingSite = () => {
  const [options2] = useState({
    margin: 10,
    loop: true,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: {
      0: {
        items: 1
      },
      600: {
        items: 2
      }
    }
  })
  const [options] = useState({
    margin: 10,
    loop: true,
    autoplay: false,
    dots: true,
    lazyLoad: true,
    mouseDrag: false,
    touchDrag: false,
    autoplayHoverPause: true,
    autoplayTimeout: 3000,
    autoplaySpeed: 1000,
    responsive: {
      0: {
        items: 1
      },
      600: {
        items: 2
      },
      1000: {
        items: 3
      }
    }
  })

  return (
    <div className="marketing-site col-12">
      <div className="scroll-up-btn">
        <i className="fas fa-angle-up"></i>
      </div>
      <nav className="navbar-marketing sticky">
        <div className="max-width">
          <div className="logo">
            <p className="hire about-img">
              <img src="/favicon.ico" alt="icon" width="50px" height="50px" />
              Chartit-360
            </p>
          </div>
          <ul className="menu about-img">
            <li>
              <a href="#home" className="menu-btn hire">
                Home
              </a>
            </li>
            <li>
              <a href="#about" className="menu-btn hire">
                About
              </a>
            </li>
            <li>
              <a href="#services" className="menu-btn hire">
                Services
              </a>
            </li>
            <li>
              <a href="#skills" className="menu-btn hire">
                Advantages
              </a>
            </li>
            <li>
              <a href="#teams" className="menu-btn hire">
                Team
              </a>
            </li>
            <li>
              <a href="#contact" className="menu-btn hire">
                Contact
              </a>
            </li>
          </ul>
          <div className="menu-btn">
            <i className="fas fa-bars"></i>
          </div>
        </div>
      </nav>

      <section className="home" id="home">
        <div className="max-width">
          <div className="home-content">
            <div className="text-1"></div>
            <div className="text-2">Chartit-360</div>
            <div className="text-3">
              This is{' '}
              <ReactTyped
                strings={[
                  'Data Visualization',
                  'Financial Analysis',
                  'Scenario Comparison'
                ]}
                typeSpeed={40}
                backSpeed={50}
                loop
              />
            </div>
            <br />
            <br />
            <a href="/app" className="hire about-img">
              Open Chartit-360
            </a>
          </div>
        </div>
      </section>

      <section className="about" id="about">
        <div className="max-width">
          <h2 className="title">About Chartit-360</h2>
          <div className="about-content">
            <div className="about-img">
              <p>
                Chartit-360 is a powerful online platform designed for financial
                analysts, investors, and traders to graphically depict market
                trends, analyze and visualize the performance of various
                indicators. This sophisticated tool empowers users to create
                dynamic scenarios, enabling a comprehensive understanding of
                market dynamics and potential investment outcomes.
                <br />
                <br />
                Key Features:
                <br />
                <br />
                Graphical Representation:
                <br />
                <br />
                The platform offers intuitive charting capabilities to visually
                represent movements and indicator values over time. Users can
                choose from a variety of chart types, including line charts,
                exponential charts and bar charts, to customize the
                visualization based on their preferences.
                <br />
                <br />
                Scenario Analysis:
                <br />
                <br />
                Easily analyze scenarios by identifying peaks and troughs in
                market trends. The tool automatically detects and highlights
                periods of value increases and decreases , providing valuable
                insights into market sentiment.
                <br />
                <br />
                Indicator Insights:
                <br />
                <br />
                Evaluate the performance of financial indicators such as moving
                averages, Relative Strength Index (RSI), and others. Gain a
                deeper understanding of indicator crossovers, trend
                confirmations, and potential reversal points.
                <br />
                <br />
                Dynamic Collections:
                <br />
                <br />
                Users can create and save collections of indicators and
                scenarios for specific stocks, indices. This feature allows for
                easy comparison and analysis of different financial instruments
                within a single, cohesive interface.
                <br />
                <br />
                Collaborative Tools:
                <br />
                <br />
                Facilitate collaboration by sharing scenarios and collections
                with team members or other users. Enhance decision-making
                processes by discussing and analyzing financial market scenarios
                in a collaborative environment.
                <br />
                <br />
                User-Friendly Interface:
                <br />
                <br />
                The platform features an easy-to-use interface for chart
                customization. Accessible to both beginners and experienced
                financial professionals, the interface prioritizes user-friendly
                interactions. Stay informed with real-time data updates,
                ensuring that
                <br />
                <br />
                Real-time Data Integration:
                <br />
                <br /> Stay informed with real-time data updates, ensuring that
                analyses are based on the latest market information. The
                Financial Scenario Visualizer is a versatile tool designed to
                transform complex financial data into clear, actionable
                visualizations. Whether you are a seasoned trader or a novice
                investor, this platform empowers you to make informed decisions
                by understanding the past, present, and potential future trends
                in the financial markets. Explore, analyze, and strategize with
                confidence using the Chartit-360.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="services" id="services">
        <div className="max-width">
          <h2 className="title" style={{ color: 'white' }}>
            Services
          </h2>
          <div className="serv-content">
            <OwlCarousel className="owl-theme" {...options2}>
              <div className="card about-img">
                <div className="box">
                  <img src="/indicator.jpg" width="310px" height="330px" />
                  <div className="text">Indicators</div>
                  <p>
                    Foster innovation by crafting personalized business
                    indicators, generously share your insights, or seamlessly
                    adopt effective metrics from a collaborative community of
                    experts. The platform offers a comprehensive library of
                    indicators, enabling you to select and customize the data
                    that best suit your needs.
                  </p>
                </div>
              </div>
              <div className="card about-img">
                <div className="box">
                  <img src="calculated.jpg" width="310px" height="330px" />
                  <div className="text">Calculated Indicators</div>
                  <p>
                    Fuel strategic insight with calculated indicators—craft your
                    metrics by leveraging existing indicators and employing
                    diverse calculation methods. Share your refined metrics,
                    contributing to a dynamic exchange of analytical approaches
                    within the community.
                  </p>
                </div>
              </div>
              <div className="card about-img">
                <div className="box">
                  <img src="scenario.png" width="310px" height="330px" />
                  <div className="text">Scenarios</div>
                  <p>
                    Create and customize scenarios—a seamless fusion of diverse
                    indicators tailored to your preferences. Effortlessly
                    compare and modify indicators within scenarios, enabling you
                    to adapt and refine your analytical approach with ease.
                    Elevate your decision-making by exploring the versatility of
                    scenario-based analysis.
                  </p>
                </div>
              </div>
              <div className="card about-img">
                <div className="box">
                  <img src="gpt.png" width="310px" height="330px" />
                  <div className="text">Built-in GPT</div>
                  <p>
                    Our platform features a built-in GPT-4 model, enabling users
                    to generate textual descriptions of their scenarios. This
                    feature is particularly useful for sharing scenarios with
                    other users, as it provides a concise summary of the
                    scenario’s key characteristics.
                  </p>
                </div>
              </div>
              <div className="card about-img">
                <div className="box">
                  <img src="collection.png" width="400px" height="330px" />
                  <div className="text">Collections</div>
                  <p>
                    Optimize your analysis by consolidating scenarios and
                    indicators into collections, facilitating more efficient
                    data management. This feature enables you to organize your
                    resources and streamline your workflow, thereby enhancing
                    your overall productivity.
                  </p>
                </div>
              </div>
              <div className="card about-img">
                <div className="box">
                  <img src="chartExp.png" width="400px" height="330px" />
                  <div className="text">Google Charts Modes</div>
                  <p>
                    Elevate the depth of your analysis by seamlessly integrating
                    additional indicators and reevaluating values using your
                    preferred method within the user-friendly &#34;Chart
                    Expanded&#34; interface. This enhanced approach provides you
                    with a comprehensive toolkit for refining insights and
                    optimizing decision-making processes.
                  </p>
                </div>
              </div>
              <div className="card about-img">
                <div className="box">
                  <img src="tiksModal.png" width="400px" height="330px" />
                  <div className="text">Advanced Deviation Analysis</div>
                  <p>
                    Utilize Advanced Deviation Analysis to gain profound
                    insights and pinpoint nuanced variations within your data.
                    This powerful tool surpasses traditional analysis methods,
                    offering a comprehensive examination of deviations through
                    various data calculation methods. Enhance your
                    decision-making process by leveraging the detailed
                    observations and actionable intelligence generated through
                    Advanced Deviation Analysis.
                  </p>
                </div>
              </div>
              <div className="card about-img">
                <div className="box">
                  <img src="tags.png" width="400px" height="330px" />
                  <div className="text">Tags</div>
                  <p>
                    Optimize your workflow by implementing a tagging system for
                    scenarios and indicators. This simplifies the search process
                    but also facilitates a more structured and efficient
                    distribution of components. Tags serve as versatile labels,
                    enabling you to categorize and locate specific elements
                    effortlessly, thereby enhancing the overall organization and
                    accessibility of your resources.
                  </p>
                </div>
              </div>
            </OwlCarousel>
          </div>
        </div>
      </section>

      <section className="skills" id="skills">
        <div className="max-width">
          <h2 className="title">Our Advantages</h2>
          <div className="skills-content">
            <div className="column left">
              <div className="text">
                Dynamic Financial Insights: Unleashing Collaborative Excellence.
              </div>
              <p>
                Distinguishing itself through an intuitive visualization, robust
                scenario analysis capabilities, and dynamic chart customization,
                our application excels in providing users with a real-time,
                comprehensive overview of diverse financial instruments.
                Seamlessly integrating real-time data and performance
                indicators, the user-friendly interface ensures an easy and
                enjoyable experience while retaining maximum flexibility for
                various financial instruments. Moreover, our application&#39;s
                unique strength lies in fostering a collaborative environment,
                promoting efficient interaction, and facilitating the exchange
                of ideas among users.
              </p>
              <a href="#" className="about-img">
                Try it
              </a>
            </div>
            <div className="column right">
              <div className="bars">
                <div className="info">
                  <span>Intuitive Visualization</span>
                </div>
              </div>

              <div className="line html"></div>
              <div className="bars">
                <div className="info">
                  <span>Scenario Analysis Capabilities</span>
                </div>
              </div>
              <div className="line html"></div>
              <div className="bars">
                <div className="info">
                  <span>Dynamic Chart Customization</span>
                </div>
              </div>
              <div className="line html"></div>
              <div className="bars">
                <div className="info">
                  <span>Real-time Data Integration</span>
                </div>
                <div className="line html"></div>
                <div className="bars">
                  <div className="info">
                    <span>Indicator Performance Insights</span>
                  </div>
                </div>
                <div className="line html"></div>
                <div className="bars">
                  <div className="info">
                    <span>Collaborative Environment</span>
                  </div>
                </div>
                <div className="line html"></div>
                <div className="bars">
                  <div className="info">
                    <span>User-Friendly Interface</span>
                  </div>
                </div>
                <div className="line html"></div>
                <div className="bars">
                  <div className="info">
                    <span>Versatility for Various Financial Instruments</span>
                  </div>
                </div>
                <div className="line html"></div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="teams" id="teams">
        <div className="max-width">
          <h2 className="title" style={{ color: 'white' }}>
            Team
          </h2>
          <OwlCarousel className="owl-theme" {...options}>
            <div className="item">
              <div className="card">
                <div className="box">
                  <div className="flex">
                    <div>
                      <h2 className="text px-2">Stan</h2>
                      <p className="px-2">Lead Developer</p>
                    </div>
                    <img src="/stan-photo.JPG" alt="" className="about-img" />
                  </div>
                  <br />
                  <p>
                    Stan is a full-stack developer with a passion for building
                    scalable, high-performance applications. Stan has worked on
                    a variety of projects, including web applications, mobile
                    apps, and desktop apps.
                  </p>
                </div>
              </div>
            </div>
            <div className="item">
              <div className="card">
                <div className="box">
                  <div className="flex">
                    <div>
                      <h2 className="text px-2">Andrey</h2>
                      <p className="px-2">Founder and CEO</p>
                    </div>
                    <img src="/placeholder.jpeg" alt="" className="about-img" />
                  </div>
                  <br />
                  <p>
                    Andrey is a seasoned professional with a passion for
                    financial analysis and data visualization. He has a keen
                    interest in developing innovative tools to help financial
                    professionals make better decisions.
                  </p>
                </div>
              </div>
            </div>
            <div className="item">
              <div className="card">
                <div className="box">
                  <div className="flex">
                    <div>
                      <h2 className="text px-2">Alexey</h2>
                      <p className="px-2">Developer</p>
                    </div>
                    <img src="/alex-photo.jpg" alt="" className="about-img" />
                  </div>
                  <br />
                  <p>
                    Alexey is a software engineer from Ukraine with a curiosity
                    for solving complex problems. He has experience in
                    developing web applications in React and is passionate about
                    learning new technologies.
                  </p>
                </div>
              </div>
            </div>
          </OwlCarousel>
        </div>
      </section>

      <section className="contact" id="contact">
        <div className="max-width">
          <h2 className="title">Contact us</h2>
          <div className="contact-content">
            <div className="column left">
              <div className="text">Get in Touch</div>
              <p></p>
              <div className="icons">
                <div className="row">
                  <i className="fas fa-user"></i>
                  <div className="info">
                    <div className="sub-title">Stan Huseletov</div>
                  </div>
                </div>
                <div className="row">
                  <i className="fas fa-map-marker-alt"></i>
                  <div className="info">
                    <div className="sub-title">London, UK</div>
                  </div>
                </div>
                <div className="row">
                  <i className="fas fa-envelope"></i>
                  <div className="info">
                    <div className="sub-title">alexey@chartit360.com</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="column right">
              <div className="text">Message us</div>
              <form action="https://formspree.io/f/mwkgezgz" method="POST">
                <div className="fields">
                  <div className="field name">
                    <input
                      type="text"
                      placeholder="Name"
                      name="Name"
                      required
                    />
                  </div>
                  <div className="field email">
                    <input
                      type="email"
                      placeholder="Email"
                      name="email_id"
                      required
                    />
                  </div>
                </div>
                <div className="field">
                  <input
                    type="text"
                    placeholder="Subject"
                    name="subject"
                    required
                  />
                </div>
                <div className="field textarea">
                  <textarea
                    placeholder="Message..."
                    name="message"
                    required
                  ></textarea>
                </div>
                <div className="button">
                  <button type="submit">Send message</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
      <footer>
        <span>
          <span className="far fa-copyright"></span>
        </span>
      </footer>

      {/* <link href="https://assets.calendly.com/assets/external/widget.css" rel="stylesheet" /> */}
      {/* <script src="https://assets.calendly.com/assets/external/widget.js" type="text/javascript" async></script> */}
      {/* <script type="text/javascript">
    window.onload = function() { Calendly.initBadgeWidget({ url: 'https://calendly.com/pulkit-singh/meet-with-pulkit', text: 'Schedule a meet with him', color: '#fa0000', textColor: '#ffffff' }); }
    </script> */}
    </div>
  )
}

export default MarketingSite
