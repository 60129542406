import React from 'react'
import {
  ICoreScenarioIndicatorsSection,
  IScenarioCoreIndicator
} from '../utils/interfaces'
import { FunctionalButton } from './_components'
import { updateScenarioCoreIndicators } from '../utils/fetch'
import { isEmpty } from '../utils/functions'
import CoreScenarioIndicatorsCard from './CoreScenarioIndicatorsCard'

const CoreScenarioIndicatorsSection = ({
  indicatorsData,
  fid,
  indicatorsParameters,
  refreshFunction,
  entityAllKeys,
  scenarioCoreIndicators
}: ICoreScenarioIndicatorsSection) => {
  const [coreIndicators, setCoreIndicators] = React.useState<
    IScenarioCoreIndicator[]
  >(isEmpty(scenarioCoreIndicators) ? [] : JSON.parse(scenarioCoreIndicators))
  const handleUpdateScenarioCoreIndicators = async () => {
    const result = await updateScenarioCoreIndicators({
      fid,
      coreIndicators:
        coreIndicators.length === 0 ? '' : JSON.stringify(coreIndicators)
    })
    if (result) {
      refreshFunction()
    }
    return result
  }

  const handleUpdateCoreIndicators = (
    indicator: IScenarioCoreIndicator,
    insert: boolean
  ) => {
    let newCoreIndicators = coreIndicators.filter(
      (el) => el.id !== indicator.id
    )
    if (insert) {
      newCoreIndicators = [...newCoreIndicators, indicator]
    }
    setCoreIndicators(newCoreIndicators)
  }

  if (!indicatorsData || !indicatorsParameters) return null

  return (
    <div className="core-indicators">
      <span className="small inline banner-strip info p-1">
        This is completely independent from the indicators in-depth popups below
        and this section was initially planned to be used for setup for
        composite indicator.
      </span>
      <div className="flex gap-2 middle py-2">
        <h4 className="m-0">Configure Core Indicators</h4>

        <FunctionalButton
          functionToExecute={handleUpdateScenarioCoreIndicators}
          className="primary inline wider"
          initialButtonState={'Update Database'}
          doesReset
          refreshOnComplete={{
            exists: true,
            refreshFunction
          }}
        />
      </div>

      {entityAllKeys.map((indicator) => {
        const data = indicatorsData.data.find(
          (el: any) => el.title === indicator.title
        )
        const indicatorParameters = indicatorsParameters.find(
          (el) => el.fid === indicator.title
        )
        const parameters =
          coreIndicators.length === 0
            ? undefined
            : coreIndicators.find((el) => el.name === indicator.name)
        if (!data || !indicatorParameters || data.length === 0) return null

        return (
          <CoreScenarioIndicatorsCard
            key={indicator.title}
            indicatorData={data}
            indicatorParameters={indicatorParameters}
            parameters={parameters}
            updateCoreIndicators={handleUpdateCoreIndicators}
            dates={indicatorsData.dates}
          />
        )
      })}
    </div>
  )
}

export default CoreScenarioIndicatorsSection
