import React, { useEffect, useState } from 'react'
import { fetchTrendLineById } from '../utils/fetch'
import {
  ITrendline,
  IBasicIndicator,
  IDataMapped,
  ICalculateDeviationsParamsReturn,
  ICalculatedIndicator,
  IForecast,
  IFetchedDataEntry,
  IMiniTrendline
} from '../utils/interfaces'
import {
  AccessDenied,
  Spinner,
  TabbingSwitch
} from '../helperComponents/_components'
import { getAllValuesGroupedByKey } from '../utils/transformingData'

import { transformFetchedRangedDetailsScenariotoRangeInputs } from '../utils/functions'
import { calculateDeviationsParams } from '../charts/_googleChartFunctions'
import DataTableNew from '../helperComponents/DataTableNew'
import { HighChartsLinearChart } from '../charts/HighChartsReact'

const MiniTrendline = ({ trendlineId }: IMiniTrendline) => {
  try {
    const [trendlineParameters, setTrendlineParameters] = React.useState(
      {} as ITrendline
    )
    const [selectedTrendlineTab, setSelectedTrendlineTab] =
      useState('valueChart')
    const [fetchedAllIndicators, setFetchedAllIndicators] = useState(
      [] as (IBasicIndicator | ICalculatedIndicator | ITrendline)[]
    )
    const [initialTrendline, setInitialTrendline] = React.useState(
      {} as ITrendline
    )
    const [accessAllowed, setAccessAllowed] = React.useState(false)
    const [transformedEntry, setTransformedEntry] =
      React.useState<IDataMapped>()
    const [chartTitles, setChartTitles] = useState<
      {
        [key: string]: string
      }[]
    >([])

    const [pageLoading, setPageLoading] = React.useState(false)

    const [fullDateRange, setFullDateRange] = useState<string[]>([])
    const [chartData, setChartData] = useState<IFetchedDataEntry[]>([])
    const [residualsDataSet, setResidualsDataSet] = useState<
      IFetchedDataEntry[]
    >([])

    const [rangeSelected, setRangeSelected] = useState<string[]>([])

    const handleScenarioTabChange = (option: any) => {
      setSelectedTrendlineTab(option)
    }

    const resetModals = () => {
      window.collapseSidePanel(true)
    }

    const setUpCharts = (analysed: ICalculateDeviationsParamsReturn) => {
      setChartData(analysed.chartData)
      setResidualsDataSet(analysed.residualsDataSet)
      setTrendlineParameters(analysed.trendline)
      setRangeSelected(
        transformFetchedRangedDetailsScenariotoRangeInputs(
          analysed.trendline.range_chosen_deviation ||
            analysed.trendline.range_complete
        )
      )
    }

    const initialFetch = async (trendlineId: string, noClose?: boolean) => {
      try {
        if (!noClose) {
          resetModals()
          setPageLoading(true)
        }
        const {
          parameters,
          data,
          fullDates,
          allIndicators
        }: {
          parameters: ITrendline
          data: any
          fullDates: string[]
          allIndicators: (IBasicIndicator | ICalculatedIndicator | ITrendline)[]
          forecastsAssosiated: IForecast[]
        } = await fetchTrendLineById(trendlineId)
        if (!parameters || !data) {
          setPageLoading(false)
          setAccessAllowed(false)
          return
        }
        const transformed = getAllValuesGroupedByKey(data)
        setTransformedEntry(transformed)
        setInitialTrendline(parameters)
        setFetchedAllIndicators(allIndicators)
        setTrendlineParameters(parameters)
        setChartTitles([
          {
            [parameters.base_indicator as string]: parameters.title
          }
        ])
        setFullDateRange(fullDates)

        const analysed = calculateDeviationsParams({
          entry: {
            data: transformed.data[0]
          },
          fullDates,
          trendline: parameters,
          initial: true
        })

        setUpCharts(analysed)

        if (!noClose) {
          setPageLoading(false)
        }
        setAccessAllowed(true)
      } catch (err: any) {
        console.error(err)
        window.switchFunctions.home()
      }
    }

    useEffect(() => {
      if (pageLoading) return
      initialFetch(trendlineId)
    }, [trendlineId])

    if (pageLoading) return <Spinner />
    else if (!accessAllowed && !pageLoading) return <AccessDenied />
    else if (
      accessAllowed &&
      !pageLoading &&
      trendlineParameters &&
      transformedEntry
    ) {
      return (
        <div className="entity">
          <TabbingSwitch
            options={[
              {
                label: 'Value Chart',
                active: selectedTrendlineTab === 'valueChart',
                onClick: () => handleScenarioTabChange('valueChart')
              },
              {
                label: 'Deviation Chart',
                active: selectedTrendlineTab === 'devChart',
                onClick: () => handleScenarioTabChange('devChart')
              },
              {
                label: 'Table',
                active: selectedTrendlineTab === 'dataTable',
                onClick: () => handleScenarioTabChange('dataTable')
              },
              {
                label: 'Details',
                active: selectedTrendlineTab === 'details',
                onClick: () => handleScenarioTabChange('details')
              }
            ]}
            fit
          />
          <div
            className="entity-chart"
            style={{
              display: selectedTrendlineTab === 'valueChart' ? 'block' : 'none'
            }}
          >
            <HighChartsLinearChart
              chartOptions={{
                xAxisTitle: 'Month',
                yAxisTitle: 'Value',
                title: 'Boundaries',
                oneAxis: true,
                reactive: true,
                rangeSelected
              }}
              chartData={{
                fetchedData: chartData,
                filteredKeys: [
                  transformedEntry.data[0].title,
                  'Trendline Values'
                ],
                fullDates: fullDateRange,
                titles: chartTitles,
                allKeys: [transformedEntry.data[0].title, 'Trendline Values']
              }}
              componentOptions={{
                noTrendlines: true,
                inversed: trendlineParameters.inverse,
                own:
                  !initialTrendline.is_own && !trendlineParameters.admin_access,
                parametersFetched: trendlineParameters
              }}
            />
          </div>
          <div
            className="entity-chart"
            style={{
              display: selectedTrendlineTab === 'devChart' ? 'block' : 'none'
            }}
          >
            <HighChartsLinearChart
              chartOptions={{
                xAxisTitle: 'Month',
                yAxisTitle: 'Value',
                title: 'Residuals',
                reactive: true,
                rangeSelected,
                oneAxis: true
              }}
              chartData={{
                titles: chartTitles,
                fetchedData: residualsDataSet,
                fullDates: fullDateRange,
                filteredKeys: ['Dev.'],
                allKeys: ['Dev.']
              }}
              componentOptions={{
                noTrendlines: true,
                seriesColors: {
                  1: { color: '#b64a4a' },
                  2: { color: '#eeaabe' },
                  3: { color: 'b1df9f' },
                  4: { color: '#519238' }
                },
                inversed: trendlineParameters.inverse,
                own:
                  !initialTrendline.is_own && !trendlineParameters.admin_access,
                parametersFetched: trendlineParameters
              }}
            />
          </div>
          <div
            className="entity-data"
            style={{
              display: selectedTrendlineTab === 'dataTable' ? 'block' : 'none'
            }}
          >
            <p style={{ position: 'sticky', left: '1em' }}>Values</p>
            <DataTableNew
              tableMode="medium"
              fullDates={fullDateRange}
              rangeSelected={rangeSelected}
              data={getAllValuesGroupedByKey(chartData)}
              showOther={true}
              filteredKeys={[
                transformedEntry.data[0].title,
                'Trendline Values'
              ]}
              chartKeys={[transformedEntry.data[0].title, 'Trendline Values']}
              fetchedIndicators={fetchedAllIndicators}
              dataMode={trendlineParameters.data_mode}
              componentMode="trendline"
              // selectedDataMappedMode={selectedDataMappedMode}
              // noCalcCondition={
              //   (key: string) =>
              //   entityAllKeys.find(
              //     (item: IEntityAllKeys) => item.title === key
              //   )?.type !== 'trendline'
              // }
            />
            <p style={{ position: 'sticky', left: '1em' }}>Deviations</p>
            <DataTableNew
              tableMode="medium"
              fullDates={fullDateRange}
              rangeSelected={rangeSelected}
              data={getAllValuesGroupedByKey(residualsDataSet)}
              showOther={true}
              filteredKeys={['Dev.']}
              chartKeys={['Dev.']}
              fetchedIndicators={fetchedAllIndicators}
              dataMode={trendlineParameters.data_mode}
              componentMode="trendline"
              // selectedDataMappedMode={selectedDataMappedMode}
              // noCalcCondition={
              //   (key: string) =>
              //   entityAllKeys.find(
              //     (item: IEntityAllKeys) => item.title === key
              //   )?.type !== 'trendline'
              // }
            />
          </div>
          <div
            style={{
              display: selectedTrendlineTab === 'details' ? 'block' : 'none'
            }}
          >
            <div className="p-2 flex gap-3">
              <h2 className="m-0">{initialTrendline.title}</h2>
              <button
                onClick={() =>
                  window.switchFunctions.trendline(initialTrendline.fid)
                }
                className="no-btn no-hover border wide"
              >
                Visit Trendline
              </button>
            </div>
            <p className="px-2">{initialTrendline.meaning}</p>
          </div>
        </div>
      )
    } else return <AccessDenied />
  } catch (error) {
    console.error('Error rendering trendline page:', error)
    return <AccessDenied />
  }
}

export default MiniTrendline
