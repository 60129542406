import React from 'react'
import { CollapsibleSidePanelProps } from '../utils/interfaces'
import { FunctionalButton } from './_components'

const CollapsibleSidePanel: React.FC<CollapsibleSidePanelProps> = ({
  isOpen,
  onToggle,
  functionalButton,
  children
}) => {
  return (
    <div className={`collapsible-side-panel ${isOpen ? 'open' : ''}`}>
      <div className="panel-content">
        {children}
        <div className="flex-row col-12 space-evenly">
          {/* <button onClick={onToggle} className="col-5 secondary">
            Close
          </button> */}
          {functionalButton && (
            <FunctionalButton
              initialButtonState={functionalButton.initialButtonState}
              iconSaveMode={functionalButton.iconSaveMode}
              combinedButtonTitle={functionalButton.combinedButtonTitle}
              disabled={functionalButton.disabled}
              functionToExecute={functionalButton.functionToExecute}
              className={'primary col-5'}
              refreshOnComplete={functionalButton.refreshOnComplete}
              doesReset={functionalButton.doesReset}
            />
          )}
        </div>
      </div>
    </div>
  )
}

export default CollapsibleSidePanel
