// import { generateShades } from './functions'

const CALCULATED_TYPES = {
  offset: 'CAGR',
  delayed: 'Growth From an Initial Point',
  delayedTotal: 'Growth From an Initial Point (Total)',
  calculated: 'Equation',
  deviations: 'Deviations'
}

const TYPES_DICT = {
  scenario: 's',
  indicator: 'i',
  collection: 'c',
  memo: 'm',
  trendline: 't',
  calculated: 'o',
  search: 'a',
  upload: 'u',
  home: 'h',
  forecast: 'f',
  channel: 'r',
  external: 'e'
}

const TYPES_DICT_REVERSE = Object.keys(TYPES_DICT)
  .map((key) => ({
    [TYPES_DICT[key as keyof typeof TYPES_DICT]]: key
  }))
  .reduce((acc, val) => ({ ...acc, ...val }), {})

// const CHART_COLOURS = {
//   primary: generateShades('#0d6efd', 10, 5),
//   secondary: generateShades('#dc3545', 10, 5)
// }

export { CALCULATED_TYPES, TYPES_DICT, TYPES_DICT_REVERSE }
