import React, { useState } from 'react'
import { IBasicIndicator } from '../utils/interfaces'
import { FunctionalButton } from './_components'
import { computeGapAnalysis } from '../utils/transformingData'
import { Checkbox } from 'pretty-checkbox-react'

const GapAnalysis = ({
  indicators,
  indicatorId,
  indicatorData,
  refreshFunction
}: {
  indicators: IBasicIndicator[]
  indicatorData: any
  indicatorId: string
  refreshFunction: () => void
}) => {
  const [indicatorsNames] = useState(
    indicators
      .filter((indicator) => indicator.fid !== indicatorId)
      .map((indicator) => indicator.fid)
  )
  const [baseIndicator, setBaseIndicator] = useState('')
  const [analysisMode, setAnalysisMode] = useState('')
  const [answer, setAnswer] = useState('')
  const [showExplanation, setShowExplanation] = useState(false)

  const handleComputeGapAnalysis = async () => {
    setAnswer('')
    const computeAnswer = await computeGapAnalysis({
      baseIndicator,
      analysisMode,
      indicatorData,
      indicatorId
    })
    if (!computeAnswer || computeAnswer === '') {
      return false
    }
    setAnswer(computeAnswer)
    return true
  }

  return (
    <div className="gap-analysis">
      <div className="gap-analysis-settings">
        <select
          className="wide"
          value={analysisMode}
          onChange={(e) => setAnalysisMode(e.target.value)}
        >
          <option value="" disabled>
            Select analysis mode
          </option>
          <option value="relativeAverage">Relative Average</option>
          <option value="relativeMedian">Relative Median</option>
          <option value="absoluteAverage">Absolute Average</option>
          <option value="absoluteMedian">Absolute Median</option>
          <option value="standardDeviation">Standard Deviation</option>
          <option value="coefficientOfVariation">
            Coefficient of Variation
          </option>
        </select>
        <select
          value={baseIndicator}
          className="wide"
          onChange={(e) => setBaseIndicator(e.target.value)}
        >
          <option value="" disabled>
            Select base indicator
          </option>
          {indicatorsNames.map((indicatorName, index) => (
            <option key={index} value={indicatorName}>
              {indicatorName.includes(
                localStorage.getItem('userId')?.toLowerCase() || ''
              )
                ? indicatorName
                    .split('_')
                    .slice(0, -1)
                    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                    .join(' ')
                : indicatorName
                    .split('_')
                    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                    .join(' ')}
            </option>
          ))}
        </select>
        <FunctionalButton
          className="wide primary"
          functionToExecute={handleComputeGapAnalysis}
          initialButtonState={'Compute'}
          doesReset
          disabled={baseIndicator === '' || analysisMode === ''}
        />
        <Checkbox
          shape="curve"
          color="info-o"
          animation="pulse"
          checked={showExplanation}
          onChange={() => setShowExplanation(!showExplanation)}
        >
          Explanation
        </Checkbox>
      </div>

      {answer !== '' && (
        <div>
          <hr />
          <div className="gap-analysis-answer">
            Result: <span>{answer}</span>
          </div>
          <hr />
        </div>
      )}
      {showExplanation && (
        <div className="gap-analysis-explanation">
          <h3>Compute Gap Analysis Function Explanation</h3>

          <h5>Data Preparation</h5>
          <p>
            It retrieves <code>baselineIndicatorData</code> from the backend. If
            this data is empty or not present, it returns false.
          </p>

          <h5>Analysis Modes</h5>
          <ul>
            <li>
              <strong>Relative Average:</strong> Calculates the average of the
              relative difference between the <code>indicatorData</code> and the{' '}
              <code>baselineIndicatorData</code>. The relative difference is
              computed as{' '}
              <code>((indicatorDataItem - baseData) / baseData)</code>. The
              result is formatted as a percentage string.
            </li>
            <li>
              <strong>Relative Median:</strong> Similar to the relative average,
              but calculates the median of the relative differences.
            </li>
            <li>
              <strong>Absolute Average:</strong> Computes the average of the
              absolute differences between the <code>indicatorData</code> and
              the <code>baselineIndicatorData</code>, without normalizing by the
              base data.
            </li>
            <li>
              <strong>Absolute Median:</strong> Similar to the absolute average,
              but calculates the median of the absolute differences.
            </li>
            <li>
              <strong>Standard Deviation:</strong> Calculates the standard
              deviation of the absolute differences between{' '}
              <code>indicatorData</code> and <code>baselineIndicatorData</code>.
              The standard deviation is a measure of the amount of variation or
              dispersion in a set of values.
            </li>
            <li>
              <strong>Coefficient of Variation:</strong> Computes the
              coefficient of variation, which is the ratio of the standard
              deviation to the mean. This provides a standardized measure of
              dispersion relative to the mean.
            </li>
          </ul>

          <h5>Common Calculations</h5>
          <p>
            In each analysis mode, the function iterates over the{' '}
            <code>baselineIndicatorData</code> and calculates a value (
            <code>calculated</code>) based on the selected mode. For median
            calculations, the data array is sorted, and the median value is
            determined. For standard deviation and coefficient of variation, the
            average and variance are calculated first. The standard deviation is
            the square root of the variance, and the coefficient of variation is
            the ratio of the standard deviation to the mean.
          </p>

          <h5>Formatting and Returning Results</h5>
          <p>
            The calculated values are converted to strings, and the function
            returns a portion of these strings (first 5 characters) to provide a
            standardized output format.
          </p>

          <p>
            In summary, <code>computeGapAnalysis</code> is a versatile function
            that computes different statistical measures (average, median,
            standard deviation, coefficient of variation) in both relative and
            absolute terms, based on the provided indicator data and analysis
            mode.
          </p>
        </div>
      )}
    </div>
  )
}

export default GapAnalysis
