import React, { useState } from 'react'

const PasswordPrompt = ({
  onPasswordSuccess
}: {
  onPasswordSuccess: () => void
}) => {
  const [password, setPassword] = useState('')
  const [error, setError] = useState('')
  const PASSWORD = 'marketing'

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault()
    if (password === PASSWORD) {
      onPasswordSuccess()
    } else {
      setError('Incorrect password')
    }
  }

  return (
    <div className="login-container">
      <form onSubmit={handleSubmit} className="login-form">
        <div className="form-field">
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Enter password"
            className="form-input"
          />
        </div>
        <button type="submit" className="login-button primary">
          Submit
        </button>
      </form>
      {error && <p className="error-text">{error}</p>}
    </div>
  )
}

export default PasswordPrompt
