import React, { Fragment, useEffect, useRef, useState } from 'react'
import {
  modifyCollectionEntries,
  deleteIndicatorByName,
  fetchChosenIndicator,
  updateIndicarorDetails,
  updateRangeSelected,
  updateSelectedColumnsEntity,
  updateEntityInverse,
  createNewForecast,
  updateDbIndicator,
  fetchExtraData
  // updateIndicatorDataMode
} from '../utils/fetch'
import {
  // ICollection,
  ICollectionEntry,
  IBasicIndicator,
  ITrendline,
  IEntityAllKeys,
  IIndicatorPage,
  ICalculatedIndicator,
  IMappedDataEntry,
  IDataMapped,
  IForecast
} from '../utils/interfaces'
import PopupModal from '../helperComponents/PopUpModal'
import {
  TabbingSwitch,
  AccessDenied,
  FunctionalButton,
  Spinner,
  EntityHeader,
  ScrollTableButtons
  // FunctionalDropdown
  // FunctionalToggle
} from '../helperComponents/_components'
import {
  Icon3Dots // IconCollapse,
  // IconExpand
  // IconExpandMore
} from '../helperComponents/Icons'
import {
  addToVisitedPages,
  evaluateLocalStoragePageMode,
  generateRandomId,
  findMatchPercentage,
  transformFetchedRangedDetailsScenariotoRangeInputs
} from '../utils/functions'
// import DataMapped from '../helperComponents/DataMapped'
import {
  convertExcelDateToHTMLDate,
  exportToExcel,
  generateIndicatorsKeysObjects,
  getAllValuesGroupedByKey
} from '../utils/transformingData'
import { HighChartsLinearChart } from '../charts/HighChartsReact'
import CustomContextMenu from '../helperComponents/CustomContextMenu'
import UploadPage from '../helperComponents/UploadPage'

import RangeSlider2 from '../helperComponents/RangeSlider2'
import EntityTags from '../helperComponents/EntityTags'
import AllIndicatorsTableDisplay from '../helperComponents/AllIndicatorsTableDisplay'
import DataTableNew from '../helperComponents/DataTableNew'
import EntitySharingControls from '../helperComponents/EntitySharingControls'
import ChatbotComponent from '../helperComponents/ChatbotComponent'
import { jsPDF } from 'jspdf'
import { Trendlines } from './Trendlines'
import CreateTrendline from '../helperComponents/CreateTrendline'
import PopupModalNew from '../helperComponents/PopUpModalNew'
import RenameMajorEntity from '../helperComponents/RenameMajorEntity'

const IndicatorPage = ({
  indicatorId,
  userTags,
  readonly,
  favourites
}: IIndicatorPage) => {
  try {
    // const [selectedDataMappedMode, setSelectedDataMappedMode] = useState<
    //   'value' | 'arrow' | 'off'
    // >(window.globalSettings.rises_and_drops_in_sd.state)
    const [pageMode, setPageMode] = useState<
      'basic' | 'trendline' | 'forecast'
    >('basic')
    const fetchedIndicatorParameters = useRef<IBasicIndicator>(
      {} as IBasicIndicator
    )

    // const [fetchedTrendlines, setFetchedTrendlines] = React.useState<
    //   IBasicIndicator[]
    // >([])
    const [
      fullFetchedIndicatorsParameters,
      setFullFetchedIndicatorsParameters
    ] = React.useState<ITrendline[]>([])
    // const [indicatorDataMode, setIndicatorDataMode] = useState<string>('')
    const files = useRef<File[] | null>(null)
    // eslint-disable-next-line
    const fetchedAllIndicators = useRef<
      (IBasicIndicator | ICalculatedIndicator | ITrendline)[]
    >([])

    const [ownerInfo, setOwnerInfo] = useState({
      profileImage: '',
      username: ''
    })
    const [trendlinesFetched, setTrendlinesFetched] = useState<boolean>(false)
    const [modalIndicatorEditData, setModalIndicatorEditData] =
      React.useState(false)
    const [accessAllowed, setAccessAllowed] = React.useState(false)
    const fetchedIndicatorData = useRef<any>([])

    const fetchedDataTransformed = useRef({} as IDataMapped)

    const fullDates = useRef<string[]>([])
    const [readOnly, setReadOnly] = useState<boolean>(readonly)
    // const [dataMappedCellWidth, setDataMappedCellWidth] = useState<number>(50)
    const [detailsTabMode, setDetailsTabMode] = useState<string>('details')
    const [pageLoading, setPageLoading] = React.useState(false)
    const [modalDeleteIndicator, setModalDeleteIndicator] =
      React.useState(false)
    const defaultContextMenuSettings = {
      top: 0,
      left: 0,
      show: false
    }

    const [contextMenuDetails, setContextMenuDetails] = useState(
      defaultContextMenuSettings
    )
    const [
      modalIndicatorNameAndDescription,
      setModalIndicatorNameAndDescription
    ] = useState(false)

    const [selectedDataBoundaries, setSelectedDataBoundaries] = useState<
      string[]
    >([])
    // const [dataSectionCollapsed, setDataSectionCollapsed] =
    //   useState<boolean>(false)

    const [entityAllKeys, setEntityAllKeys] = useState<IEntityAllKeys[]>([])
    const [entityChartKeys, setEntityChartKeys] = useState<IEntityAllKeys[]>([])
    // const [initialChartKeys, setInitialChartKeys] = useState<IEntityAllKeys[]>(
    //   []
    // )

    const [entityDataKeys, setEntityDataKeys] = useState<IEntityAllKeys[]>([])
    // const [modalChartExpanded, setModalChartExpanded] = useState<boolean>(true)
    const [modalIndicatorRange, setModalIndicatorRange] =
      useState<boolean>(false)
    const [dateRangeSliderChanged, setDateRangeSliderChanged] =
      useState<boolean>(false)

    // const [fetchedCollections, setFetchedCollections] = useState<ICollection[]>(
    //   []
    // )
    const [isCurrentIndicatorFavourite, setIsCurrentIndicatorFavourite] =
      useState(false)

    const resetContextMenuDetails = () => {
      if (contextMenuDetails.show) {
        setContextMenuDetails(defaultContextMenuSettings)
      }
    }

    const resetModals = () => {
      window.collapseSidePanel(true)
      setModalDeleteIndicator(false)
      setModalIndicatorNameAndDescription(false)
      localStorage.removeItem('openPopupId')
      setContextMenuDetails(defaultContextMenuSettings)
      // setDataSectionCollapsed(false);
      setModalIndicatorEditData(false)
      setModalIndicatorRange(false)
    }

    const filterEntityDataKeys = (key: IEntityAllKeys, chartMode?: boolean) => {
      if (chartMode) {
        setEntityChartKeys((prevKeys) => {
          const keyIds = prevKeys.map((item) => item.id)
          if (keyIds.includes(key.id)) {
            return prevKeys.filter((item) => item.id !== key.id)
          } else {
            return [...prevKeys, key]
          }
        })
        return
      }

      setEntityDataKeys((prevKeys) => {
        const keyIds = prevKeys.map((item) => item.id)
        if (keyIds.includes(key.id)) {
          return prevKeys.filter((item) => item.id !== key.id)
        } else {
          return [...prevKeys, key]
        }
      })
    }

    const filterEntityChartKeys = (key: IEntityAllKeys) => {
      setEntityChartKeys((prevKeys) => {
        const keyIds = prevKeys.map((item) => item.id)
        if (keyIds.includes(key.id)) {
          return prevKeys.filter((item) => item.id !== key.id)
        } else {
          return [...prevKeys, key]
        }
      })
    }

    const handleContextMenuDetails = (e: any) => {
      e.preventDefault()
      if (contextMenuDetails.show) {
        setContextMenuDetails(defaultContextMenuSettings)
        return
      }
      resetModals()
      setContextMenuDetails({
        show: true,
        top: e.pageY,
        left: e.pageX + 10
      })
    }

    const filterEntityKeysBasedOnPageMode = (keys: IEntityAllKeys[]) => {
      if (pageMode === 'trendline') {
        return keys.filter(
          (item) => item.type === 'trendline' || item.type === 'indicator'
        )
      } else if (pageMode === 'forecast') {
        return keys.filter((item) => item.type === 'forecast')
      }

      return keys.filter(
        (item) => item.type === 'actuals' || item.type === 'indicator'
      )
    }

    const checkIfIndicatorIsFavourite = () => {
      if (!favourites || favourites.indicators.length === 0) {
        return false
      }

      return favourites.indicators.some(
        (item: ICollectionEntry) => item.id === indicatorId
      )
    }

    const fetchIndicator = async (indicatorId: string, noClose?: boolean) => {
      try {
        setTrendlinesFetched(false)
        if (!noClose) {
          resetModals()
          setPageLoading(true)
        }
        const response = await fetchChosenIndicator(indicatorId)
        if (!response) {
          setPageLoading(false)
          setAccessAllowed(false)
          return
        }
        const fetchedParameters: IBasicIndicator =
          response.fetchedIndicatorParameters[0]
        const { trendlines, forecasts } = response
        const allParams = [
          ...response.fetchedIndicatorParameters,
          ...trendlines,
          ...forecasts.parameters
        ]

        // if frequency is monthly, we need to remove all but the first day of the month in each month in fulldates
        // const actualFullDates = response.fullDates.filter(
        //   (date: string, index: number) =>
        //     frequency === 'monthly'
        //       ? date.split('T')[0].split('-')[2] === '01'
        //       : false
        // )

        const transformed = getAllValuesGroupedByKey(
          response.fetchedIndicatorData
        )

        const transformedRange =
          transformFetchedRangedDetailsScenariotoRangeInputs(
            fetchedParameters.range_chosen
          )
        const isFavourite = checkIfIndicatorIsFavourite()
        let { allKeys, allChartKeys, allDataKeys } =
          generateIndicatorsKeysObjects({
            indicatorsParameters: allParams,
            selectedChart: fetchedParameters.chart_columns_selected,
            selectedData: fetchedParameters.columns_selected,
            dataMode: fetchedParameters.data_mode,
            backupVisibleKeys: [indicatorId]
          })

        allChartKeys = allChartKeys.filter((_) => !_.disabled)
        allDataKeys = allDataKeys.filter((_) => !_.disabled)

        fetchedAllIndicators.current = response.allIndicators
        fetchedIndicatorParameters.current = fetchedParameters
        // setIndicatorDataMode(fetchedParameters.data_mode)
        fetchedIndicatorData.current = response.fetchedIndicatorData
        fullDates.current = response.fullDates
        setReadOnly(!fetchedParameters.is_own)
        setFullFetchedIndicatorsParameters(allParams)
        setOwnerInfo({
          profileImage: response.owner.profile_image,
          username: response.owner.username
        })
        setSelectedDataBoundaries(transformedRange)
        fetchedDataTransformed.current = transformed
        setIsCurrentIndicatorFavourite(isFavourite)
        setEntityAllKeys(allKeys)
        setEntityChartKeys(allChartKeys)
        // setInitialChartKeys(allChartKeys)
        setEntityDataKeys(allDataKeys)

        if (!noClose) {
          setPageLoading(false)
        }
        setAccessAllowed(true)
      } catch (err: any) {
        console.error(err)
        window.switchFunctions.home()
      }
    }

    const fetchTrendlines = async (force = false) => {
      const keys = Object.keys(fetchedIndicatorData.current[0]).filter(
        (key) => key !== 'date' && key !== 'id' && key !== indicatorId
      )

      const filteredKeys = entityChartKeys
        .filter((key) => key.type === 'trendline')
        .map((key) => key.title)

      const trendlinesIds = filteredKeys
        .map((key) => [`${key} Value`, `${key} Deviation`])
        .flat()
      const match = findMatchPercentage(keys, trendlinesIds)

      if ((match > 50 || trendlinesFetched) && !force) {
        setTrendlinesFetched(true)
        return
      }
      setPageLoading(true)
      const additionalData = [
        ...fullFetchedIndicatorsParameters.filter(
          (item) => item.type === 'trendline' && filteredKeys.includes(item.fid)
        ),
        fetchedIndicatorParameters.current
      ]
      const data = await fetchExtraData(
        [],
        additionalData,
        fetchedIndicatorParameters.current.frequency
      )

      fetchedIndicatorData.current = data
      setPageLoading(false)
    }

    const refreshIndicator = () => {
      if (pageLoading) return

      fetchIndicator(indicatorId)
    }

    const refreshIndicatorNoClose = (contextMenu?: boolean) => {
      if (pageLoading) return
      fetchIndicator(indicatorId, true).then(() => {
        if (contextMenu) {
          // setTimeout(() => {
          resetContextMenuDetails()
          // }, 500)
        }
      })
    }

    const handleSwitchPageMode = async (
      selectedOption: 'basic' | 'trendline' | 'forecast'
    ) => {
      resetModals()
      if (
        selectedOption === 'trendline' &&
        fetchedIndicatorData.current.length
      ) {
        await fetchTrendlines()
      }

      if (selectedOption === 'basic') {
        // ensure the indicator key is included in the chart keys and data keys
        const indicatorKey = entityAllKeys.find(
          (item) => item.title === indicatorId
        )

        setTrendlinesFetched(false)

        if (indicatorKey) {
          setEntityChartKeys([...entityChartKeys, indicatorKey])
          setEntityDataKeys([...entityDataKeys, indicatorKey])
        }
        addToVisitedPages({
          fid: indicatorId,
          type: 'indicator',
          pageMode: selectedOption
        })
      } else {
        addToVisitedPages({
          fid: indicatorId,
          type: 'indicatorTrendlines',
          pageMode: selectedOption
        })
      }

      localStorage.setItem(
        'indicatorPageMode',
        `${selectedOption}---${indicatorId}`
      )
      setPageMode(selectedOption)

      return true
    }

    const addIndicatorToShown = async (fid: string) => {
      const res = await updateSelectedColumnsEntity({
        selectedChartList: [
          ...entityChartKeys.map((item) => item.title),
          fid
        ].join(', '),
        selectedDataList: [
          ...entityChartKeys.map((item) => item.title),
          fid
        ].join(', '),
        entityId: indicatorId,
        entityType: 'indicator'
      })

      if (res) {
        return fid
      }
    }

    const applyRangeChange = async (lowerValue: string, upperValue: string) => {
      const result = await updateRangeSelected({
        rangeSelected: `[${lowerValue},${upperValue})`,
        entityId: indicatorId,
        entityType: 'indicator'
      })

      if (result) {
        refreshIndicatorNoClose()
      }

      return result
    }

    const handleRename = async ({
      newName,
      newDescription,
      newShortDescription
    }: any) => {
      const res = await updateIndicarorDetails({
        indicatorId,
        newName,
        newDescription,
        newShortDescription
      })

      if (res) {
        setModalIndicatorNameAndDescription(false)
        refreshIndicator()
      }

      return res
    }

    // const applyMappedCellWidthChange = (e: any) => {
    //   if (
    //     e.target.value >= 20 &&
    //     e.target.value <= 150 &&
    //     e.target.value.toString().slice(0, 1) !== '0' &&
    //     !isNaN(e.target.value)
    //   ) {
    //     const value = e.target.value
    //     setDataMappedCellWidth(value)
    //     const tickWidth = 17 * (value / 50)

    //     // Assuming 'value' and 'tickWidth' are defined and have numeric types
    //     // 1. Set width for elements with classes .date-tick and .tick
    //     const ticks: any = document.querySelectorAll('.date-tick, .tick')
    //     ticks.forEach(
    //       (tick: { style: { width: string } }) =>
    //         (tick.style.width = `${value}px`)
    //     )

    //     // 2. Set min-width for elements with classes .date-tick and .tick
    //     ticks.forEach(
    //       (tick: { style: { minWidth: string } }) =>
    //         (tick.style.minWidth = `${value}px`)
    //     )

    //     // 3. Set padding-left to 0 for elements with the selector .ticks-entry .ticks span
    //     const spanElements: any = document.querySelectorAll(
    //       '.ticks-entry .ticks span'
    //     )
    //     spanElements.forEach(
    //       (span: { style: { paddingLeft: string } }) =>
    //         (span.style.paddingLeft = '0')
    //     )

    //     // 4. Set width for images inside elements with the class .tick
    //     const tickImages: any = document.querySelectorAll('.tick img')
    //     tickImages.forEach(
    //       (img: any) =>
    //         (img.style.width = `${tickWidth > 17 ? 17 : tickWidth}px`)
    //     )
    //   }
    // }

    const handleExportData = () => {
      try {
        if (
          !fetchedDataTransformed ||
          !fetchedDataTransformed.current.data ||
          !fetchedDataTransformed.current.data[0]
        ) {
          return false
        }

        const dates = fetchedDataTransformed.current.data[0].dates
        const values = fetchedDataTransformed.current.data[0].values
        const data = values.map((item: any, index: number) => ({
          date: dates[index],
          [fetchedIndicatorParameters.current.title]: item
        }))

        exportToExcel(
          data,
          `${fetchedIndicatorParameters.current.title} - ${new Date()
            .toLocaleDateString()
            .split('/')
            .join('-')
            .slice(0, 10)}`
        )

        return true
      } catch (err: any) {
        console.error(err)
      }
      return false
    }

    const analyseWithAI = async () => {
      const exportName = `${fetchedIndicatorParameters.current.title}.pdf`

      const combineDataForExport = () => {
        if (!fetchedDataTransformed || !fetchedIndicatorParameters) return null

        const description =
          'File description: ' + fetchedIndicatorParameters.current.meaning
        const data = fetchedDataTransformed.current.data.map((item: any) => {
          const titles = fullFetchedIndicatorsParameters.find(
            (indicator) => indicator.fid === item.title
          )
          const title = titles ? titles.title : item.title

          return {
            title,
            data: item.values
          }
        })
        return { description, data }
      }

      const combinedData = combineDataForExport()

      if (combinedData) {
        const doc = new jsPDF() // eslint-disable-line
        doc.setFontSize(12)

        doc.text(combinedData.description, 10, 10)

        let y = 20
        combinedData.data.forEach((item: any) => {
          doc.text(item.title + ': ' + JSON.stringify(item.data), 10, y)
          y += 10
        })

        const blob = doc.output('blob')

        const file = new File([blob], exportName, {
          type: 'application/pdf'
        })

        files.current = [file]
        setDetailsTabMode('ai')

        return true
      } else {
        return false
      }
    }

    const handleCreateForecast = async () => {
      let res = await createNewForecast(indicatorId)

      if (!res) {
        return false
      }

      res = await addIndicatorToShown(res)

      window.switchFunctions.forecast(res)
      return true
    }

    const handleUpdateData = async (data: IMappedDataEntry) => {
      try {
        const res = await updateDbIndicator({
          indicatorName: fetchedIndicatorParameters.current.fid,
          replace: true,
          data: {
            dates: data.dates,
            data: data.values
          }
        })

        if (res) {
          refreshIndicatorNoClose()
        }

        return res
      } catch (error) {
        console.error('Error updating data:', error)
        return false
      }
    }

    const handleNewIndicator = async (indicatorId: string) => {
      if (pageLoading) return
      const pageMode = evaluateLocalStoragePageMode(indicatorId)
      if (indicatorId) {
        await fetchIndicator(indicatorId)
      }
      if (pageMode === 'trendline' && fetchedIndicatorData.current.length) {
        await fetchTrendlines()
      } else {
        setTrendlinesFetched(false)
      }
      setPageMode(pageMode)
    }

    const handleCurrentPageIndexChange = async (index: number) => {
      const currentPage = window.visitedPages[index - 1]
      if (
        currentPage.pageMode === 'trendline' &&
        fetchedIndicatorData.current.length
      ) {
        await fetchTrendlines()
      }
      if (
        currentPage.fid === indicatorId &&
        currentPage.pageMode !== pageMode
      ) {
        setPageMode((currentPage.pageMode as any) || 'basic')
      }
    }

    useEffect(() => {
      handleNewIndicator(indicatorId)
    }, [indicatorId])

    useEffect(() => {
      handleCurrentPageIndexChange(window.currentPageIndex)
    }, [window.currentPageIndex])

    if (pageLoading) return <Spinner />
    else if (!accessAllowed && !pageLoading) return <AccessDenied />
    else if (accessAllowed && !pageLoading && fetchedIndicatorParameters) {
      return (
        <div className="entity indicator">
          <EntityHeader
            breadcrumbs={[
              {
                title: 'Indicator',
                onClick: () => handleSwitchPageMode('basic'),
                active: pageMode === 'basic'
              },
              ...(pageMode === 'trendline'
                ? [
                    {
                      title: 'Trendlines Analysis',
                      onClick: () => handleSwitchPageMode('trendline'),
                      active: pageMode === 'trendline'
                    }
                  ]
                : [])
            ]}
            titleClick={() => window.switchFunctions.indicator(indicatorId)}
            subtitle="Indicator"
            ownerInfo={ownerInfo}
            title={fetchedIndicatorParameters.current.title}
            description={fetchedIndicatorParameters.current.meaning}
          />

          <div className="entity-blocks">
            <div className="container w-12">
              <div className="entity-parameters justify-content-start">
                {!readOnly && pageMode === 'trendline' && (
                  <PopupModalNew
                    title="Create Trendline"
                    size="largest"
                    fid={generateRandomId()}
                    handleSubmit={() => undefined}
                    saveButtonExists={false}
                    inner
                    fullHeight
                    noChanges
                    className="p-0"
                    buttonFragment={{
                      props: {
                        className:
                          'inline no-btn default-text no-hover no-border m-2',
                        functionToExecute: () => undefined,
                        noReturn: true,
                        doesReset: true,
                        initialButtonState: 'Generate Trendline',
                        iconPlusMode: true
                      }
                    }}
                  >
                    <CreateTrendline
                      fetchedDataTransformed={
                        fetchedDataTransformed.current.data.filter(
                          (item) =>
                            item.title ===
                            fetchedIndicatorParameters.current.fid
                        )[0]
                      }
                      fetchedIndicatorParameters={
                        fetchedIndicatorParameters.current
                      }
                      indicatorId={indicatorId}
                      fullDates={fullDates.current}
                      source="indicator"
                      source_fid={indicatorId}
                      closeModal={resetModals}
                    />
                  </PopupModalNew>
                )}

                {!readOnly && pageMode !== 'trendline' && (
                  <FunctionalButton
                    className="inline no-btn default-text no-hover no-border"
                    fakeLoading
                    noReturn
                    functionToExecute={() => handleSwitchPageMode('trendline')}
                    doesReset
                    initialButtonState={'Trendlines Analysis'}
                    iconTrendline
                  />
                )}
                {!readOnly && pageMode === 'basic' && (
                  <FunctionalButton
                    className="inline no-btn default-text no-hover no-border"
                    noReturn
                    functionToExecute={() => {
                      analyseWithAI()
                      setContextMenuDetails(defaultContextMenuSettings)
                    }}
                    doesReset
                    initialButtonState={'AI Analysis'}
                    iconAI
                  />
                )}
                {!readOnly && pageMode !== 'basic' && (
                  <PopupModalNew
                    buttonFragment={{
                      props: {
                        className:
                          'inline no-btn default-text no-hover no-border',
                        noReturn: true,
                        functionToExecute: () => undefined,
                        doesReset: true,
                        initialButtonState: 'Change Selection',
                        iconListMode: true
                      }
                    }}
                    fid={generateRandomId()}
                    noChanges
                    size="large"
                    title="Add/remove indicators"
                    handleSubmit={async () =>
                      await updateSelectedColumnsEntity({
                        selectedChartList: [
                          ...entityChartKeys.map((item) => item.title),
                          indicatorId
                        ]
                          .filter(
                            (item, index, self) => self.indexOf(item) === index
                          )
                          .join(', '),
                        selectedDataList: [
                          ...entityDataKeys.map((item) => item.title),
                          indicatorId
                        ]
                          .filter(
                            (item, index, self) => self.indexOf(item) === index
                          )
                          .join(', '),
                        entityId: indicatorId,
                        entityType: 'indicator'
                      })
                    }
                    saveButtonExists
                    refreshOnComplete={{
                      exists: true,
                      refreshFunction: refreshIndicator
                    }}
                  >
                    <div className="flex-column center middle col-12 gap-3 indicators-view-panel">
                      <AllIndicatorsTableDisplay
                        allKeys={filterEntityKeysBasedOnPageMode(entityAllKeys)}
                        entityDataKeys={filterEntityKeysBasedOnPageMode(
                          entityDataKeys
                        )}
                        allIndicators={fetchedAllIndicators.current}
                        entityChartKeys={filterEntityKeysBasedOnPageMode(
                          entityChartKeys
                        )}
                        filterEntityDataKeys={filterEntityDataKeys}
                        filterEntityChartKeys={filterEntityChartKeys}
                      />
                    </div>
                  </PopupModalNew>
                )}
                {!readOnly && pageMode === 'forecast' && (
                  <FunctionalButton
                    className="inline no-btn default-text no-hover no-border m-2"
                    functionToExecute={handleCreateForecast}
                    doesReset
                    initialButtonState={'Generate Forecast'}
                    iconPlusMode
                  />
                )}
                {pageMode === 'basic' && (
                  <button
                    className="no-btn flex no-hover"
                    onClick={handleContextMenuDetails}
                  >
                    <Icon3Dots />
                  </button>
                )}
              </div>
            </div>
            {pageMode === 'basic' && (
              <Fragment>
                <div
                  className="container w-6 large no-scrollbar"
                  onClick={resetContextMenuDetails}
                >
                  <div className="entity-data no-scrollbar">
                    <div className="entity-parameters">
                      <TabbingSwitch
                        options={[
                          {
                            label: 'Details',
                            onClick: () => setDetailsTabMode('details'),
                            active: detailsTabMode === 'details'
                          },
                          {
                            label: 'Data',
                            onClick: () => setDetailsTabMode('data'),
                            active: detailsTabMode === 'data',
                            exists: !readOnly
                          },
                          {
                            label: 'AI',
                            onClick: () => setDetailsTabMode('ai'),
                            active: detailsTabMode === 'ai'
                          },
                          {
                            label: 'Tags',
                            onClick: () => setDetailsTabMode('tags'),
                            active: detailsTabMode === 'tags',
                            exists: !readOnly
                          },
                          {
                            label: 'Sharing',
                            onClick: () => setDetailsTabMode('sharing'),
                            active: detailsTabMode === 'sharing',
                            exists: !readOnly
                          }
                        ]}
                        numberVisible={7}
                        fit
                        className="no-border col-12"
                      />
                    </div>
                    {detailsTabMode === 'data' && (
                      <div className="col-12">
                        <UploadPage
                          fullFetchedIndicatorsParameters={[
                            fetchedIndicatorParameters.current
                          ]}
                          refreshFunction={refreshIndicator}
                          singleIndicatorMode={{
                            exists: true,
                            indicatorId
                          }}
                          mode="table"
                          customData={(() => {
                            const data =
                              fetchedDataTransformed.current.data.find(
                                (item: IMappedDataEntry) =>
                                  item.title ===
                                  fetchedIndicatorParameters.current.fid
                              )
                            if (!data) {
                              return { dates: [], values: [], title: '' }
                            }

                            return {
                              dates: data.dates.map((date: string) =>
                                convertExcelDateToHTMLDate(date)
                              ),
                              values: data.values,
                              title: data.title
                            }
                          })()}
                          customPostFunction={handleUpdateData}
                        />
                      </div>
                    )}
                    {detailsTabMode === 'details' && (
                      <textarea
                        className="description default-text p-2"
                        contentEditable={false}
                        readOnly
                        value={
                          fetchedIndicatorParameters.current.meaning ||
                          'No description provided'
                        }
                      />
                    )}
                    <div
                      style={{
                        display: detailsTabMode === 'ai' ? 'block' : 'none'
                      }}
                      className="flex-row col-12"
                    >
                      <ChatbotComponent
                        page="entity"
                        instructions="Examine this document detailing the indicator alongside its trendlines and deliver a comprehensive analysis of its contents. Evaluate the significance of each trendlines values and conduct a comparative analysis among them. Spotlight any distinctive characteristics inherent in both the indicator and its trendlines."
                        assistantOn={true}
                        providedFiles={files.current}
                        clearFiles={() => (files.current = null)}
                      />
                    </div>
                    {detailsTabMode === 'tags' && (
                      <div className="description flex-row col-12">
                        <div className="entity-info-block col-12 default-text">
                          {!readOnly && (
                            <EntityTags
                              type="indicator"
                              allTags={userTags}
                              entityTags={
                                fetchedIndicatorParameters.current.tags || []
                              }
                              entityId={indicatorId}
                              functionRefresh={refreshIndicatorNoClose}
                            />
                          )}
                        </div>
                      </div>
                    )}
                    {detailsTabMode === 'sharing' && (
                      <div className="description flex-row col-12">
                        <div className="entity-info-block col-12 default-text">
                          <FunctionalButton
                            className="secondary inline"
                            functionToExecute={handleExportData}
                            disabled={readOnly}
                            initialButtonState={'Export Data'}
                          />
                          <EntitySharingControls
                            entityType="indicator"
                            entityId={indicatorId}
                            ChannelContentPiece={
                              fetchedIndicatorParameters.current
                                .ChannelContentPiece
                            }
                            refreshFunction={refreshIndicatorNoClose}
                            initialDescription={
                              fetchedIndicatorParameters.current.meaning
                            }
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className="container w-6 large scroll no-scrollbar">
                  <div className="entity-chart">
                    <HighChartsLinearChart
                      chartOptions={{
                        xAxisTitle: 'Month',
                        yAxisTitle: 'Value',
                        title: fetchedIndicatorParameters.current.title,
                        reactive: true,
                        mediumSize: true
                        // reactive: initialChartKeys !== entityChartKeys
                      }}
                      chartData={{
                        fetchedData: fetchedIndicatorData.current,
                        fullDates: fullDates.current,
                        titles: filterEntityKeysBasedOnPageMode(
                          entityChartKeys
                        ).map((item: IEntityAllKeys) => ({
                          [item.title]: item.name
                        })),
                        filteredKeys: filterEntityKeysBasedOnPageMode(
                          entityChartKeys
                        ).map((item) => item.title),
                        allKeys: filterEntityKeysBasedOnPageMode(
                          entityChartKeys
                        ).map((item) => item.title)
                        // deviations: fullFetchedIndicatorsParameters
                      }}
                      componentOptions={{
                        own:
                          !readOnly &&
                          fetchedIndicatorParameters &&
                          !fetchedIndicatorParameters.current.admin_access,
                        parametersFetched: fetchedIndicatorParameters.current
                      }}
                    />
                  </div>
                </div>
                <div
                  className="container w-12 fit"
                  onClick={resetContextMenuDetails}
                >
                  <div className="container w-12 no-borders flex entity-parameters">
                    <ScrollTableButtons />
                  </div>
                  <div className="container w-12 scroll-x no-borders">
                    <DataTableNew
                      tableMode="medium"
                      fullDates={fullDates.current}
                      readOnly={readOnly}
                      data={fetchedDataTransformed.current}
                      filteredKeys={filterEntityKeysBasedOnPageMode(
                        entityDataKeys
                      ).map((key) => key.title)}
                      chartKeys={entityAllKeys.map((key) => key.title)}
                      fetchedIndicators={fullFetchedIndicatorsParameters}
                      dataMode={fetchedIndicatorParameters.current.data_mode}
                      componentMode="indicator"
                    />
                  </div>
                </div>
              </Fragment>
            )}
            {pageMode === 'trendline' && (
              <Trendlines
                functions={{}}
                entityChartKeys={entityChartKeys.filter(
                  (key) => key.type === 'trendline' || key.title === indicatorId
                )}
                entityDataKeys={entityDataKeys.filter(
                  (key) => key.type === 'trendline' || key.title === indicatorId
                )}
                entityAllKeys={entityAllKeys.filter(
                  (key) => key.type === 'trendline' || key.title === indicatorId
                )}
                fullDates={fullDates.current}
                forecastsAssosiated={
                  fetchedAllIndicators.current.filter(
                    (item) => item.type === 'forecast'
                  ) as IForecast[]
                }
                selectedDataBoundaries={selectedDataBoundaries}
                data={fetchedIndicatorData.current}
                trendlines={fullFetchedIndicatorsParameters.filter(
                  (item) => item.type === 'trendline'
                )}
                actuals={fullFetchedIndicatorsParameters.filter((item) =>
                  entityChartKeys
                    .filter(
                      (key) =>
                        key.type === 'actuals' || key.type === 'indicator'
                    )
                    .map((key) => key.title)
                    .includes(item.fid)
                )}
                readOnly={readOnly}
                userTags={userTags}
                allIndicators={fetchedAllIndicators.current}
                indicatorPageDependent={true}
              />
            )}
          </div>
          <CustomContextMenu
            contextMenuSettings={contextMenuDetails}
            menu={[
              {
                onClick: () => {
                  resetModals()
                  setModalIndicatorNameAndDescription(true)
                },
                title: 'Edit Details',
                noAccess: readOnly
              },
              {
                onClick: () => {
                  resetModals()
                  setModalIndicatorEditData(true)
                },
                title: 'Edit Data',
                noAccess: readOnly
              },
              {
                onClick: () => {
                  resetModals()
                  setModalIndicatorRange(true)
                },
                noAccess: readOnly,
                title: 'Edit Range'
              },
              {
                onClick: () => {
                  resetModals()
                  window.switchFunctions.upload()
                },
                title: 'Create/Clone Indicator',
                noAccess: readOnly
              },
              {
                onClick: () =>
                  modifyCollectionEntries({
                    entityType: 'indicator',
                    entityId: indicatorId,
                    action: isCurrentIndicatorFavourite ? 'remove' : 'add',
                    entityName: fetchedIndicatorParameters.current.title,
                    collectionId: favourites === undefined ? 0 : favourites.id
                  }),
                title: isCurrentIndicatorFavourite
                  ? 'Remove from My Library'
                  : 'Add to My Library',
                functionalButtonSettings: {
                  exists: true,
                  refreshFunction: () => refreshIndicatorNoClose(true),
                  doesReset: true
                },
                noAccess: fetchedIndicatorParameters.current.is_own
              },
              //               <FunctionalButton
              //   iconAI
              //   initialButtonState={'AI Analysis'}
              //   combinedButtonTitle
              //   className="primary wide"
              //   functionToExecute={analyseWithAI}
              // />

              // {pageMode !== 'basic' && (
              //   <FunctionalButton
              //     iconBackwardsMode
              //     initialButtonState={'Back'}
              //     combinedButtonTitle
              //     className="teriary wide"
              //     functionToExecute={() => handleSwitchPageMode('basic')}
              //     noReturn
              //   />
              // )}
              {
                title: 'Forecasts Analysis',
                onClick: () => handleSwitchPageMode('forecast'),
                noAccess: !fetchedIndicatorParameters.current.is_own || readOnly
              },
              {
                onClick: async () =>
                  await updateEntityInverse({
                    fid: fetchedIndicatorParameters.current.fid,
                    type: 'indicator'
                  }),
                title: fetchedIndicatorParameters.current.inverse
                  ? 'Toggle Not Inverse'
                  : 'Toggle Inverse',
                functionalButtonSettings: {
                  exists: true,
                  refreshFunction: () => refreshIndicatorNoClose(true),
                  doesReset: true
                },
                noAccess: readOnly
              },
              {
                onClick: () => {
                  resetModals()
                  setModalDeleteIndicator(true)
                },
                title: 'Delete',
                noAccess: readOnly
              }
            ]}
          />

          {modalIndicatorEditData && !readOnly && (
            <PopupModal
              isOpen={modalIndicatorEditData}
              onClose={() => setModalIndicatorEditData(false)}
              title="Edit Indicator Details"
              size={'large'}
              handleSubmit={() => undefined}
              saveButtonExists={false}
              noChanges={true}
            >
              <UploadPage
                fullFetchedIndicatorsParameters={[
                  fetchedIndicatorParameters.current
                ]}
                refreshFunction={refreshIndicator}
                singleIndicatorMode={{
                  exists: true,
                  indicatorId
                }}
                mode="table"
                customData={(() => {
                  const data = fetchedDataTransformed.current.data.find(
                    (item: IMappedDataEntry) =>
                      item.title === fetchedIndicatorParameters.current.fid
                  )
                  if (!data) return { dates: [], values: [], title: '' }

                  return {
                    dates: data.dates.map((date: string) =>
                      convertExcelDateToHTMLDate(date)
                    ),
                    values: data.values,
                    title: data.title
                  }
                })()}
                customPostFunction={handleUpdateData}
              />
            </PopupModal>
          )}
          {modalDeleteIndicator && (
            <PopupModal
              isOpen={modalDeleteIndicator && !readOnly}
              onClose={() => setModalDeleteIndicator(false)}
              title="Are you sure you want to delete this indicator?"
              size="small"
              handleSubmit={() => undefined}
              saveButtonExists={false}
              inner
            >
              <div className="flex-row center middle margin-1">
                <FunctionalButton
                  className="destructive"
                  functionToExecute={async () =>
                    await deleteIndicatorByName(indicatorId)
                  }
                  initialButtonState={'Delete'}
                  refreshOnComplete={{
                    exists: true,
                    refreshFunction: () =>
                      window.switchFunctions.deleted(indicatorId)
                  }}
                />
              </div>
            </PopupModal>
          )}

          {modalIndicatorNameAndDescription && (
            <PopupModal
              isOpen={modalIndicatorNameAndDescription && !readOnly}
              onClose={resetModals}
              title="Edit Indicator Details"
              size="large"
              handleSubmit={() => undefined}
              inner
            >
              <RenameMajorEntity
                functionToExecute={handleRename}
                title={fetchedIndicatorParameters.current.title}
                description={fetchedIndicatorParameters.current.meaning}
                short_description={
                  fetchedIndicatorParameters.current.short_description
                }
              />
            </PopupModal>
          )}
          {modalIndicatorRange && (
            <PopupModal
              isOpen={modalIndicatorRange && !readOnly}
              onClose={() => setModalIndicatorRange(false)}
              title="Indicator Range"
              size="medium"
              handleSubmit={() => undefined}
              saveButtonExists={false}
              customMessage="If you leave this page, the changes will not be saved."
              noChanges={!dateRangeSliderChanged}
            >
              <RangeSlider2
                dataArray={fullDates.current}
                initialDetails={transformFetchedRangedDetailsScenariotoRangeInputs(
                  fetchedIndicatorParameters.current.range_complete
                )}
                selectedDetails={selectedDataBoundaries}
                dateSliderMode={true}
                iconMode={'save'}
                customStyles={{
                  width: 400,
                  padding: '6px 20px 6px 5px'
                }}
                onApply={applyRangeChange}
                handleChange={() =>
                  !dateRangeSliderChanged &&
                  setDateRangeSliderChanged(!dateRangeSliderChanged)
                }
              />
            </PopupModal>
          )}
        </div>
      )
    } else return <AccessDenied />
  } catch (error) {
    console.error('Error rendering indicator page:', error)
    return <AccessDenied />
  }
}

export default React.memo(IndicatorPage)
