import React from 'react'
import {
  ICoreScenarioIndicatorsCard,
  IScenarioCoreIndicator
} from '../utils/interfaces'
import { Accordion, Card } from 'react-bootstrap'
import RangeSlider2 from './RangeSlider2'
import {
  isEmpty,
  transformFetchedRangedDetailsScenariotoRangeInputs
} from '../utils/functions'
import { computeExponentialAndLinearStats } from '../utils/maths'
import { Checkbox } from 'pretty-checkbox-react'
import {
  findMaxChartIndex,
  findMinChartIndex
} from '../charts/_googleChartFunctions'

const CoreScenarioIndicatorsCard = ({
  indicatorData,
  indicatorParameters,
  parameters,
  updateCoreIndicators,
  dates
}: ICoreScenarioIndicatorsCard) => {
  const [checked, setChecked] = React.useState(parameters !== undefined)

  const handleUpdateIndicatorDeviationParameters = (
    lowerValue: string,
    upperValue: string
  ) => {
    if (parameters) {
      const minElIndex = findMinChartIndex(lowerValue, dates)
      const maxElIndex = findMaxChartIndex(upperValue, dates)
      const { a, b, residualsStdDev } = computeExponentialAndLinearStats({
        values: [...indicatorData.values].slice(minElIndex, maxElIndex),
        isRelative: parameters.isRelative,
        chartMode: parameters.standard_deviation_chart_mode
      })
      const newParameters: IScenarioCoreIndicator = {
        ...parameters,
        range_chosen_deviation: `[${lowerValue},${upperValue})`,
        standard_deviation_parameters: {
          a,
          b
        },
        standard_deviation: residualsStdDev
      }
      updateCoreIndicators(newParameters, true)
      return true
    }
    return false
  }

  const handleUpdateIndicatorParameters = () => {
    if (!checked) {
      const newParameters: IScenarioCoreIndicator = {
        id: indicatorParameters.fid,
        name: indicatorParameters.title,
        standard_deviation: indicatorParameters.standard_deviation,
        isRelative: indicatorParameters.standard_deviation_relative,
        range_chosen_deviation: indicatorParameters.range_chosen_deviation,
        standard_deviation_parameters: isEmpty(
          indicatorParameters.standard_deviation_parameters
        )
          ? { a: '', b: '' }
          : JSON.parse(indicatorParameters.standard_deviation_parameters),
        standard_deviation_chart_mode:
          indicatorParameters.standard_deviation_chart_mode
        // add correct new parameters
      }
      updateCoreIndicators(newParameters, true)
    } else {
      updateCoreIndicators(
        {
          id: indicatorParameters.fid,
          name: '',
          standard_deviation: 0,
          isRelative: false,
          range_chosen_deviation: '',
          standard_deviation_parameters: {
            a: 0,
            b: 0
          },
          standard_deviation_chart_mode:
            indicatorParameters.standard_deviation_chart_mode
        },
        false
        // add correct new parameters
      )
    }
    setChecked(!checked)
    return true
  }

  return (
    <Accordion>
      <Card>
        <Accordion.Item eventKey="0">
          <Accordion.Header>
            {indicatorParameters.title}
            {parameters && (
              <span className="banner-strip success inline col-1 m-0 mx-2">
                Core
              </span>
            )}
          </Accordion.Header>
          <Accordion.Body>
            <Checkbox
              checked={checked}
              color="success-o"
              animation="pulse"
              onChange={handleUpdateIndicatorParameters}
            >
              {checked ? 'Remove from' : 'Add to'} Core Indicators
            </Checkbox>
            {parameters && (
              <div className="parameters">
                <span className="banner-strip success small">
                  Calculate new (specific to this scenario) standard deviation
                  with the slider below
                </span>
                {parameters.standard_deviation_parameters &&
                  parameters.standard_deviation_parameters.a &&
                  parameters.standard_deviation_parameters.b && (
                    <span>
                      Standard Deviation: {parameters.standard_deviation}
                      <br />
                      Standard Deviation equation:{' '}
                      {parameters.standard_deviation_parameters.a.toFixed(5)} *
                      e^(
                      {parameters.standard_deviation_parameters.b.toFixed(5)} *
                      x)
                    </span>
                  )}
                <RangeSlider2
                  dataArray={dates}
                  dateSliderMode={true}
                  selectedDetails={transformFetchedRangedDetailsScenariotoRangeInputs(
                    parameters.range_chosen_deviation
                  )}
                  initialDetails={transformFetchedRangedDetailsScenariotoRangeInputs(
                    indicatorParameters.range_complete
                  )}
                  onApply={handleUpdateIndicatorDeviationParameters}
                  iconMode="calculate"
                />
              </div>
            )}
          </Accordion.Body>
        </Accordion.Item>
      </Card>
    </Accordion>
  )
}

export default CoreScenarioIndicatorsCard
