import React, { useEffect, useMemo, useRef } from 'react'
import Highcharts, { SeriesOptionsType } from 'highcharts'
import { IDataMapped } from '../utils/interfaces'
import accessibility from 'highcharts/modules/accessibility'
import { Spinner } from '../helperComponents/_components'

// Define the props for the Sparkline component
interface SparklineProps {
  data: number[][]
  type?: string
  chartOptions?: Highcharts.Options
}

// Define the Sparkline component
const Sparkline: React.FC<SparklineProps> = ({
  data,
  type = 'area',
  chartOptions = {}
}) => {
  const chartRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    accessibility(Highcharts)

    if (chartRef.current) {
      const defaultOptions: Highcharts.Options = {
        chart: {
          borderWidth: 0,
          type,
          margin: [2, 0, 2, 0],
          width: 120,
          height: 30,
          style: {
            overflow: 'visible'
          }
        },
        title: {
          text: ''
        },
        credits: {
          enabled: false
        },
        xAxis: {
          labels: {
            enabled: false
          },
          title: {
            text: null
          },
          startOnTick: false,
          endOnTick: false,
          tickPositions: []
        },
        yAxis: {
          endOnTick: false,
          startOnTick: false,
          labels: {
            enabled: false
          },
          title: {
            text: null
          },
          tickPositions: [0]
        },
        legend: {
          enabled: false
        },
        tooltip: {
          hideDelay: 0,
          outside: true,
          shared: true,
          // given that the point x is the utc timestamp, we need to convert it to a date
          formatter: function () {
            return (
              `<b>${new Date(this.points![0].point.x).toLocaleDateString()}</b><br/>` +
              this.points![0].point.y
            )
          },
          pointFormat: '<b>{point.y}.000</b>'
        },
        plotOptions: {
          series: {
            animation: false,
            lineWidth: 1,
            shadow: false,
            states: {
              hover: {
                lineWidth: 1
              }
            },
            marker: {
              radius: 1,
              states: {
                hover: {
                  radius: 2
                }
              }
            }
          },
          column: {
            negativeColor: '#910000',
            borderColor: 'silver'
          }
        },
        series: [
          {
            data,
            pointStart: 1
          }
        ] as SeriesOptionsType[]
      }

      const options: Highcharts.Options = Highcharts.merge(
        defaultOptions,
        chartOptions
      )

      const chart = Highcharts.chart(chartRef.current, options)

      // Cleanup chart on component unmount
      return () => {
        chart.destroy()
      }
    }
  }, [data, type, chartOptions])

  return <div ref={chartRef} />
}

// Define the data structure for the table rows
interface SparklineData {
  title: string
  fid: string
  data: number[][]
  changes: number[][]
}

// Create a parent component to render the table and sparklines
const SparklineTable = ({
  data,
  titles,
  filteredKeys
}: {
  data: IDataMapped
  titles: {
    [key: string]: string
  }
  filteredKeys: string[]
}) => {
  const convertToSparklineData = (
    data: IDataMapped,
    titles: {
      [key: string]: string
    },
    filteredKeys: string[],
    numberOfDates: number = 20
  ): SparklineData[] =>
    data.data
      .filter((entry) => filteredKeys.includes(entry.title))
      .map((entry) => {
        const last10Entries = entry.values.slice(-numberOfDates)
        const last10Dates = entry.dates
          .slice(-numberOfDates)
          .map((date) => new Date(date).getTime())

        const title = Object.keys(titles).includes(entry.title)
          ? titles[entry.title]
          : entry.title
        const data: number[][] = []
        const changes: number[][] = []

        last10Entries.forEach((value, i) => {
          data.push([last10Dates[i], Number(value)])
          changes.push([
            last10Dates[i],
            Number(value) - Number(last10Entries[i - 1])
          ])
        })

        return {
          title,
          data,
          changes,
          fid: entry.title
        }
      })

  const sparklineData: SparklineData[] = useMemo(
    () => convertToSparklineData(data, titles, filteredKeys),
    [data, titles, filteredKeys]
  )

  if (sparklineData.length === 0) {
    return (
      <Spinner
        options={{
          noMessage: true,
          noSpinner: true
        }}
      />
    )
  }

  return (
    <div id="scrolling-container">
      <table id="table-sparkline">
        <thead>
          <tr>
            <th>Title</th>
            <th>Data</th>
          </tr>
        </thead>
        <tbody id="tbody-sparkline">
          {sparklineData.map((row, index) => (
            <tr key={index}>
              <th
                onClick={() => window.switchFunctions.trendline(row.fid)}
                className="pointer"
              >
                {row.title}
              </th>
              <td>
                <Sparkline data={row.data} />
                <Sparkline data={row.changes} type="column" />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}

export { SparklineTable }
