import React, { useMemo, useState } from 'react'
import { InlineSpinner, TableValue } from './_components'

import {
  IForecast,
  IResidualWithDate,
  ITicksWrapper,
  ITransformedTick,
  ITrendline
} from '../utils/interfaces'
import { transformTicksWrapperEntryIntoTicksData } from '../utils/transformingData'
import {
  isEmpty,
  transformFetchedRangedDetailsScenariotoRangeInputsSlash
} from '../utils/functions'
// import { findMinChartIndex } from '../charts/_googleChartFunctions'
import {
  IconCalculate,
  IconTrendline,
  IconFuture,
  IconOther,
  IconSettings,
  IconUpload
} from './Icons'

const getDataAverage = (data: any[]) => {
  try {
    data = data.filter((item) => !isEmpty(item))
    const sum = data.reduce((acc, curr) => acc + curr, 0)
    return sum / data.length
  } catch (error) {
    return 1000
  }
}

const TableRowNew = ({
  data,
  dates,
  indicatorParameterFetched,
  selectedColumns,
  componentMode,
  fullDates,
  readOnly = false,
  selectedDataMappedMode,
  noCalc,
  tableMode,
  dataMode,
  isActiveMode,
  onValuesClick
}: ITicksWrapper) => {
  if (!data || !indicatorParameterFetched) {
    return (
      <tr>
        <td>
          <InlineSpinner />
        </td>
      </tr>
    )
  }

  const [chartMode, setChartMode] = useState<string>('exponential')
  const [relative, setRelative] = useState<string>('abs')
  const [linksCount, setLinksCount] = useState<number>(0)
  const [fromDate, setFromDate] = useState<string>('N/A')
  const [toDate, setToDate] = useState<string>('N/A')

  const functionOnClick = () => {
    if (Object.keys(indicatorParameterFetched).includes('onclick')) {
      return (
        indicatorParameterFetched as {
          onclick: (fid: string) => void
          fid: string
        }
      ).onclick(indicatorParameterFetched.fid)
    }
    const type = indicatorParameterFetched.type
    const title = indicatorParameterFetched.title
    if (readOnly) return () => undefined
    if (!type || type === 'other') return () => undefined

    if (type === 'trendline' && dataMode === 'deviations') {
      return () => undefined
    }

    if (title === 'Dev.') {
      return () => undefined
    }

    if (type === 'trendline') {
      return window.switchFunctions.trendline(indicatorParameterFetched.fid)
    }

    if (type === 'calculated') {
      return window.switchFunctions.calculated(indicatorParameterFetched.fid)
    }

    if (type === 'forecast') {
      return window.switchFunctions.forecast(indicatorParameterFetched.fid)
    }

    if (type === 'actuals') {
      return window.switchFunctions.indicator(indicatorParameterFetched.fid)
    }

    if (type === 'indicator') {
      return window.switchFunctions.indicator(indicatorParameterFetched.fid)
    }

    return () => undefined
  }

  // const functionOnIndicatorClick = () => {
  //   const trendlineIndicator = indicatorParameterFetched as ITrendline

  //   if (trendlineIndicator.base_indicator) {
  //     if (trendlineIndicator.source === 'calculated') {
  //       window.switchFunctions.calculated(trendlineIndicator.base_indicator)
  //     } else {
  //       window.switchFunctions.indicator(trendlineIndicator.base_indicator)
  //     }
  //   }
  // }

  const transformedData: ITransformedTick[] = useMemo(() => {
    const type = indicatorParameterFetched.type

    if (type === 'trendline') {
      const params = indicatorParameterFetched as ITrendline
      setChartMode(
        Object.keys(params).includes('standard_deviation_chart_mode')
          ? 'exponential'
          : params.standard_deviation_chart_mode
      )
      setRelative(
        Object.keys(params).includes('standard_deviation_relative')
          ? 'rel'
          : 'abs'
      )

      const countItems = (item: string) =>
        isEmpty(item) ? 0 : item.split(', ').length

      setLinksCount(
        countItems(params.relation_indicators) +
          countItems(params.relation_scenarios) +
          countItems(params.relation_calculated)
      )
      setFromDate(
        transformFetchedRangedDetailsScenariotoRangeInputsSlash(
          indicatorParameterFetched.range_chosen_deviation ||
            indicatorParameterFetched.range_complete
        )[0].slice(2, 7)
      )
      setToDate(
        transformFetchedRangedDetailsScenariotoRangeInputsSlash(
          indicatorParameterFetched.range_chosen_deviation ||
            indicatorParameterFetched.range_complete
        )[1].slice(2, 7)
      )
    } else if (type === 'forecast') {
      const params = indicatorParameterFetched as IForecast

      const fromDate = isEmpty(params.first_date)
        ? ''
        : params.first_date.slice(2, 7)
      const toDate = isEmpty(params.last_date)
        ? ''
        : params.last_date.slice(2, 7)

      setFromDate(fromDate)
      setToDate(toDate)
      // } else {
      //   const firstDate = new Date(data.dates[0])
      //   const lastDate = new Date(data.dates[data.dates.length - 1])

      //   if (!firstDate || !lastDate) return

      //   const fromDate = firstDate.toLocaleString('default', { month: '2-digit' })
      //   const fromYear = firstDate.getFullYear()
      //   const toDate = lastDate.toLocaleString('default', { month: '2-digit' })
      //   const toYear = lastDate.getFullYear().toString().slice(2, 4)

      //   setFromDate(`${fromDate}-${fromYear}`)
      //   setToDate(`${toDate}-${toYear}`)
    }

    let vals: ITransformedTick[] = []

    if (type === 'trendline' && getDataAverage(data.values) < 10) {
      const boundaries = window.globalSettings.boundaries_in_sd
      const risesAndDrops = window.globalSettings.rises_and_drops_in_sd
      const majorDrop = Number(risesAndDrops[1])
      const minorDrop = Number(risesAndDrops[2])
      const minorRise = Number(risesAndDrops[3])
      const majorRise = Number(risesAndDrops[4])
      const firstBoundary = Number(boundaries[1])
      const secondBoundary = Number(boundaries[2])
      const thirdBoundary = Number(boundaries[3])
      const fourthBoundary = Number(boundaries[4])
      const fifthBoundary = Number(boundaries[5])

      const residualsOverStandardDevData: IResidualWithDate[] = data.values.map(
        (value, index) => ({
          value,
          date: data.dates[index]
        })
      )

      vals = transformTicksWrapperEntryIntoTicksData({
        entry: {
          values: data.values,
          title: data.title,
          dates: data.dates
        },
        percentageDifferences: {
          majorRise,
          minorRise,
          minorDrop,
          majorDrop
        },
        parameters: indicatorParameterFetched as ITrendline,
        zoneBoundaries: [
          firstBoundary,
          secondBoundary,
          thirdBoundary,
          fourthBoundary,
          fifthBoundary
        ],
        residualsOverStandardDevData
      })
    } else {
      vals = data.dates.map((date, index) => ({
        id: index,
        title: data.title,
        direction: '',
        zone: '',
        weight: '',
        value: data.values[index],
        difference: data.values[index],
        date
      }))
    }

    // sort the vals by date in ascending order
    vals = vals.sort((a, b) => {
      return new Date(a.date).getTime() - new Date(b.date).getTime()
    })

    return vals
  }, [])

  if (!transformedData || transformedData.length === 0) {
    return (
      <tr>
        <td>
          <InlineSpinner />
        </td>
      </tr>
    )
  }

  return (
    <tr className={`${indicatorParameterFetched.type || ''} pointer`}>
      <td
        className={`left options icon ${isActiveMode ? 'active' : ''}`}
        onClick={functionOnClick}
      >
        <span
          className="options icon p-1"
          title={`Indicator type: ${
            {
              actuals: 'Actuals',
              trendline: 'Deviations',
              forecast: 'Forecast'
            }[indicatorParameterFetched.type]
          }`}
        >
          {indicatorParameterFetched.type === 'actuals' && <IconUpload />}
          {indicatorParameterFetched.type === 'trendline' && <IconTrendline />}
          {indicatorParameterFetched.type === 'forecast' && <IconFuture />}
          {indicatorParameterFetched.type === 'calculated' &&
            !Object.keys(indicatorParameterFetched).includes('onclick') && (
              <IconCalculate />
            )}
          {indicatorParameterFetched.type === 'calculated' &&
            Object.keys(indicatorParameterFetched).includes('onclick') && (
              <IconSettings />
            )}
          {indicatorParameterFetched.type === 'other' && <IconOther />}
        </span>
      </td>
      {selectedColumns.includes('base') && (
        <td
          onClick={functionOnClick}
          className={`large options base truncate-text ${isActiveMode ? 'active' : ''}`}
          style={{
            overflow: noCalc ? 'visible' : 'hidden',
            position: 'relative'
          }}
          title={
            indicatorParameterFetched.title +
            (indicatorParameterFetched.inverse ? ' (inversed)' : '')
          }
        >
          {indicatorParameterFetched.title}
        </td>
      )}
      {selectedColumns.includes('title') && (
        <td
          className={`large options base truncate-text ${isActiveMode ? 'active' : ''}`}
          title={indicatorParameterFetched.title}
          onClick={functionOnClick}
        >
          {indicatorParameterFetched.title}
        </td>
      )}
      {selectedColumns.includes('from') && tableMode === 'full' && !noCalc && (
        <td className="options" onClick={functionOnClick}>
          {fromDate}
        </td>
      )}
      {selectedColumns.includes('to') && tableMode === 'full' && !noCalc && (
        <td className="options" onClick={functionOnClick}>
          {toDate}
        </td>
      )}
      {selectedColumns.includes('mode') && !noCalc && (
        <td className="options" onClick={functionOnClick}>
          {chartMode}
        </td>
      )}
      {selectedColumns.includes('state') && !noCalc && (
        <td className="options" onClick={functionOnClick}>
          {relative}
        </td>
      )}
      {selectedColumns.includes('inverse') && !noCalc && (
        <td className="options">
          {typeof indicatorParameterFetched.inverse === 'boolean' ||
          isEmpty(indicatorParameterFetched.inverse)
            ? 'false'
            : indicatorParameterFetched.inverse}
        </td>
      )}
      {selectedColumns.includes('deviation') && !noCalc && (
        <td className="options" onClick={functionOnClick}>
          {indicatorParameterFetched.standard_deviation}
        </td>
      )}
      {selectedColumns.includes('links') && !noCalc && (
        <td className="" onClick={functionOnClick}>
          {linksCount}
        </td>
      )}
      {noCalc && <td className="" onClick={functionOnClick}></td>}
      {noCalc && <td className="" onClick={functionOnClick}></td>}
      {noCalc && <td className="" onClick={functionOnClick}></td>}
      {transformedData.map((item: ITransformedTick, index) => (
        <TableValue
          key={index}
          valuesPainted={
            (selectedDataMappedMode ||
              window.globalSettings.rises_and_drops_in_sd.state) === 'value'
          }
          arrowsPainted={
            (selectedDataMappedMode ||
              window.globalSettings.rises_and_drops_in_sd.state) === 'arrow'
          }
          direction={item.direction}
          zone={item.zone}
          weight={item.weight}
          date={item.date}
          title={item.title}
          value={Number(item.value)}
          difference={item.difference}
          onValuesClick={onValuesClick}
        />
      ))}
    </tr>
  )
}

export default TableRowNew
