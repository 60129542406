import {
  compareDates,
  isEmpty,
  transformFetchedRangedDetailsScenariotoRangeInputs
} from '../utils/functions'
import {
  ICalculateDeviationsParams,
  ICalculateDeviationsParamsReturn,
  IGoogleChartWithRangeFilterData,
  IMappedDataEntry,
  IResidualWithDate,
  ITreeChartDataInput,
  ITrendline
} from '../utils/interfaces'
import { computeExponentialAndLinearStats } from '../utils/maths'
import {
  findClosestMidnight,
  getAllValuesGroupedByKey,
  orderRowsByDates,
  transformRawDataToGoogleChartInputData
} from '../utils/transformingData'

const findMaxChartIndex = (date: string | Date, dates: (string | Date)[]) => {
  const maxIndexArray = [
    dates.findIndex((d) => compareDates(date, d)),
    dates.length
  ]
    .filter((item) => item !== -1)
    // .reverse()
    .sort((a, b) => b - a)

  return maxIndexArray.length === 1
    ? maxIndexArray[0]
    : maxIndexArray[0] - dates.length <= 1
      ? maxIndexArray[0]
      : maxIndexArray[1] - dates.length <= 1
        ? maxIndexArray[1]
        : dates.length
}

const findMinChartIndex = (date: string | Date, dates: (string | Date)[]) => {
  const index = dates.findIndex((d) => compareDates(date, d))
  return index === -1 ? 0 : index
}

const findChartRangeBoundaries = (
  range: string[],
  dates: (string | Date)[]
) => {
  return [
    findMinChartIndex(range[0], dates),
    findMaxChartIndex(range[1], dates)
  ]
}

const findSelectedChartRangeBoundaries = (
  range: string[],
  dates: (string | Date)[]
) => {
  // if the last item is selected, we need to show the last item
  const min = findMinChartIndex(range[0], dates)
  const max = findMinChartIndex(range[1], dates)
  if (max + 1 === dates.length) {
    return [min, max + 1]
  }
  return [min, max]
}

const transformDeviationDataSet = (residuals: IResidualWithDate[]) =>
  residuals.map((residual, index: number) => ({
    date: residual.date,
    'Dev.': residual.value,
    id: index + 1
  }))

function convertJsonToTreeChartData(
  json: ITreeChartDataInput | string,
  dataOptions: {
    titles: {
      name: string
      title: string
    }[]
  }
) {
  const inputData = typeof json === 'string' ? (json = JSON.parse(json)) : json

  let stringifiedJSON = JSON.stringify(inputData)

  dataOptions.titles.forEach((title) => {
    stringifiedJSON = stringifiedJSON.replace(title.name, title.title)
  })
  const parsedJSON = JSON.parse(stringifiedJSON)

  const chartData: any = []
  // Helper function to traverse the tree
  function traverse(node: ITreeChartDataInput, parentId: string | null) {
    if (node.id) {
      // Add the current node with its parent to the chart data
      chartData.push([node.id, parentId || ''])
    }
    // Traverse children if they exist
    if (node.children && node.children.length > 0) {
      node.children.forEach((child: any) => traverse(child, node.id))
    }
  }
  // Start traversing from the root
  traverse(parsedJSON, null)
  return chartData
}

const calculateAAGR = (b: number) => {
  // Calculate the monthly growth factor
  const G_monthly = Math.exp(b)
  // Calculate the annual growth factor
  const G_annual = Math.pow(G_monthly, 12)
  // Calculate the AAGR as a percentage
  const AAGR = (G_annual - 1) * 100

  return AAGR
}

const mapDataToFullDates = ({
  entry,
  fullDates
}: {
  entry: {
    data: IMappedDataEntry
  }
  fullDates: string[]
}): IResidualWithDate[] => {
  let result: {
    date: string
    value: number | null | string
  }[] = fullDates.map((date) => ({
    date,
    value: 'empty'
  }))

  // adding safety check for when the entry.data is undefined
  if (!entry || !entry.data) {
    return result
  }

  entry.data.dates.forEach((date: string | Date, index: number) => {
    let val: any = entry.data.values[index]

    if (isEmpty(val)) {
      // find the last non-empty value
      for (let i = index - 1; i >= 0; i--) {
        if (!isEmpty(entry.data.values[i])) {
          val = entry.data.values[i]
          break
        }
      }
    }

    const indexInFullDates = findMinChartIndex(date, fullDates)
    result[indexInFullDates] = {
      date: date as string,
      value: val
    }
  })

  result = result.map((item) => {
    if (item.value === 'empty') {
      for (let i = result.indexOf(item) - 1; i >= 0; i--) {
        if (result[i].value !== 'empty') {
          item.value = result[i].value
          break
        }
      }
    }
    return item
  })

  return result
}

const calculateDeviationsParams = ({
  entry,
  trendline,
  initial,
  noCalc,
  fullDates,
  sliced,
  multiple
}: ICalculateDeviationsParams): ICalculateDeviationsParamsReturn => {
  try {
    if (!entry || !entry.data || !trendline || !fullDates) {
      return {
        chartData: [],
        residualsOverStandardDevData: [],
        residualsDataSet: [],
        trendline: trendline as ITrendline,
        AAGR: 0,
        a: 0,
        b: 0,
        deviation: 0
      }
    }

    const mappedData = mapDataToFullDates({ entry, fullDates })
    const dates = mappedData.map((item) => findClosestMidnight(item.date))
    const values = mappedData
      .map((item) => item.value)
      .map((item) =>
        item === null
          ? null
          : typeof item === 'string' && item.includes('%')
            ? Number(item.replace('%', '')) / 100
            : Number(item)
      )

    const isRelative = trendline.standard_deviation_relative
    let a: any, b: any, residualsStdDev: any
    const range = transformFetchedRangedDetailsScenariotoRangeInputs(
      trendline.range_chosen_deviation || trendline.range_complete
    )
    const firstValueIndex = findMinChartIndex(mappedData[0].date, fullDates)
    const firstRangeIndex = findMinChartIndex(range[0], fullDates)
    const indexAdjustment = firstValueIndex - firstRangeIndex
    let jsonParsedParams = isEmpty(trendline.standard_deviation_parameters)
      ? {
          a: 0,
          b: 0
        }
      : JSON.parse(trendline.standard_deviation_parameters)

    // Fail safe for when the parameters are not parsed correctly
    if (typeof jsonParsedParams === 'string') {
      jsonParsedParams = JSON.parse(jsonParsedParams)
    }

    if (
      !noCalc &&
      (!initial ||
        jsonParsedParams.a === 0 ||
        jsonParsedParams.b === 0 ||
        isNaN(jsonParsedParams.a) ||
        isNaN(jsonParsedParams.b) ||
        isEmpty(jsonParsedParams.a) ||
        isEmpty(jsonParsedParams.b))
    ) {
      const response = computeExponentialAndLinearStats({
        values: mappedData
          .slice(...findSelectedChartRangeBoundaries(range, dates))
          .map((item) => item.value)
          .map((item) =>
            item === null
              ? null
              : typeof item === 'string' && item.includes('%')
                ? Number(item.replace('%', '')) / 100
                : Number(item)
          ),
        isRelative,
        chartMode: trendline.standard_deviation_chart_mode
      })
      a = response.a
      b = response.b
      residualsStdDev = Number(response.residualsStdDev)
    } else {
      a = jsonParsedParams.a
      b = jsonParsedParams.b
      residualsStdDev = Number(trendline.standard_deviation)
    }

    trendline = {
      ...trendline,
      standard_deviation: residualsStdDev,
      standard_deviation_parameters: JSON.stringify({
        a,
        b
      })
    }

    let chartData: any[] = []
    const newResidualsOverStandardDevData: IResidualWithDate[] = []

    const chartMode = isEmpty(trendline.standard_deviation_chart_mode)
      ? 'exponential'
      : trendline.standard_deviation_chart_mode.includes('average')
        ? 'average'
        : trendline.standard_deviation_chart_mode

    chartData = orderRowsByDates(
      values.map((item, index) => {
        const periodIndex = index + indexAdjustment // rebasing the index

        let formulaValue: number

        if (noCalc) {
          formulaValue = 0
        } else if (chartMode === 'exponential') {
          formulaValue = a * Math.exp(b * periodIndex)
        } else if (chartMode === 'linear') {
          formulaValue = a * periodIndex + b
        } else if (chartMode === 'cagr') {
          formulaValue = b * Math.pow(1 + a, periodIndex)
          // } else if (
          //   chartMode === 'average' &&
          //   numberOfMonthsForAverage !== undefined &&
          //   index > numberOfMonthsForAverage
          // ) {
          //   formulaValue = 0
          //   for (let i = 0; i < numberOfMonthsForAverage; i++) {
          //     formulaValue += Number(values[index - i])
          //   }
          //   formulaValue /= numberOfMonthsForAverage
        } else {
          formulaValue = a * Math.exp(b * periodIndex)
        }

        const diff = Number(item) - formulaValue

        const newResidual =
          Number(formulaValue) === 0
            ? 0
            : isRelative
              ? diff / Number(formulaValue)
              : diff

        newResidualsOverStandardDevData.push({
          date: dates[index],
          value: newResidual / residualsStdDev
        })

        item = item === null ? null : Number(item)

        if (sliced && (isEmpty(item) || isNaN(Number(item)))) {
          return {
            date: dates[index],
            [entry.data.title]: null,
            'Trendline Values': null,
            id: index
          }
        }

        return {
          date: dates[index],
          [entry.data.title]: item,
          'Trendline Values': formulaValue,
          id: index
        }
      })
    )

    let newResiduals: any = transformDeviationDataSet(
      newResidualsOverStandardDevData
    )

    newResiduals = orderRowsByDates(newResiduals)

    const AAGR = calculateAAGR(b)

    if (multiple) {
      chartData = chartData.map((row) => {
        row[trendline!.title + ' Value'] = row['Trendline Values']

        delete row['Trendline Values']

        return row
      })
      newResiduals = newResiduals.map((row: any) => {
        row[`${trendline!.title} Deviation`] = row['Dev.']

        delete row['Dev.']
        return row
      })
    }
    // else {
    //   chartData = chartData.map((row) => {
    //     row[trendline!.title.split('@')[0]] = row[trendline!.title]
    //     row[trendline!.title] = row['Trendline Values']

    //     delete row['Trendline Values']

    //     return row
    //   })
    //   console.log(chartData)
    // }

    // chartData = chartData.map((row) => {
    //   row.date = new Date(row.date).toISOString()
    //   return row
    // })

    return {
      chartData,
      residualsOverStandardDevData: orderRowsByDates(
        newResidualsOverStandardDevData
      ),
      residualsDataSet: newResiduals,
      trendline,
      AAGR,
      a,
      b,
      deviation: residualsStdDev
    }
  } catch (e) {
    console.error(e)
    return {
      chartData: [],
      residualsOverStandardDevData: [],
      residualsDataSet: [],
      trendline: trendline as ITrendline,
      AAGR: 0,
      a: 0,
      b: 0,
      deviation: 0
    }
  }
}

const findFirstVaueIndex = (data: any[], idToCheck: string) =>
  data.findIndex((item) => !isEmpty(item[idToCheck]) && !isNaN(item[idToCheck]))

const findLastIndex = (data: any[], idToCheck: string) =>
  data.length -
  1 -
  data
    .slice()
    .reverse()
    .findIndex((item) => !isEmpty(item[idToCheck]) && !isNaN(item[idToCheck]))

const cutOffNulls = (data: any[], idToCheck: string) => {
  const firstValueIndex = findFirstVaueIndex(data, idToCheck)
  const lastValueIndex = findLastIndex(data, idToCheck)

  return data.slice(firstValueIndex, lastValueIndex + 1)
}

const getTransformedKeys = (keys: any, chartData: any) => {
  if (!keys || !chartData || !chartData.titles) return []
  return keys.map((key: any) => {
    const matchingTitle = chartData.titles.find(
      (titleObj: any) => Object.keys(titleObj)[0] === key
    )
    return matchingTitle ? matchingTitle[key] : key
  })
}

const getRelativeChart = ({
  chartData,
  chart
}: {
  chartData: IGoogleChartWithRangeFilterData
  chart: any
}) => {
  try {
    const newTransformedData = transformRawDataToGoogleChartInputData({
      inputData: chartData,
      mode: 'relativeToFirstValue'
    })

    const keysToTransform = newTransformedData[0]
    const transformedKeys = getTransformedKeys(keysToTransform, chartData)

    const data = [transformedKeys, ...newTransformedData.slice(1)]

    return data
  } catch (e) {
    console.error(e)
    return chartData
  }
}

const getAbsoluteChart = ({
  chartData
}: {
  chartData: IGoogleChartWithRangeFilterData
}) => {
  const newTransformedData = transformRawDataToGoogleChartInputData({
    inputData: { ...chartData },
    mode: 'absolute'
  })

  const keysToTransform = newTransformedData[0]
  const transformedKeys = getTransformedKeys(keysToTransform, chartData)

  const data = [transformedKeys, ...newTransformedData.slice(1)]

  return data
}

const getDifferencesChart = ({
  chartData
}: {
  chartData: IGoogleChartWithRangeFilterData
}) => {
  const data = transformRawDataToGoogleChartInputData({
    inputData: { ...chartData },
    mode: 'differences'
  })

  return data
}

const getDeviationsChart = ({
  chartData
}: {
  chartData: IGoogleChartWithRangeFilterData
}) => {
  const deviations = chartData.deviations
  if (!deviations) return null
  const newTransformedData: {
    [key: string]: IResidualWithDate[]
  } = {}

  const groupdByKey = getAllValuesGroupedByKey(chartData.fetchedData)

  chartData.filteredKeys.forEach((key) => {
    const deviation = deviations.find((deviation) => deviation.fid === key)
    if (!deviation) return
    const data: IMappedDataEntry | undefined = groupdByKey.data.find(
      (data: any) => data.title === key
    )

    if (!data) {
      newTransformedData[key] = []
      return
    }

    const analysed = calculateDeviationsParams({
      trendline: deviation,
      initial: true,
      entry: {
        data
      },
      fullDates: chartData.fullDates,
      noCalc: deviation.type !== 'trendline'
    })

    newTransformedData[key] = analysed.residualsOverStandardDevData
  })

  // combine rows by common dates
  let finalRows: {
    [key: string]: string | null | number | Date
  }[] = []

  chartData.fullDates.forEach((date) => {
    const newRow: any = { date }
    chartData.filteredKeys.forEach((key) => {
      const correspondingValue = newTransformedData[key].find((item) =>
        compareDates(item.date, date)
      )
      const value =
        correspondingValue !== undefined &&
        !isEmpty(correspondingValue?.value) &&
        typeof correspondingValue.value === 'number' &&
        !isNaN(correspondingValue.value)
          ? correspondingValue.value
          : null

      newRow[key] = value
    })
    finalRows.push(newRow)
  })

  // filter out the rows where all values except date are null
  finalRows = finalRows.filter((row) =>
    Object.values(row)
      .slice(1)
      .some((value) => value !== null)
  )

  // find all keys with the value null in all rows
  const nullKeys = chartData.filteredKeys.filter((key) =>
    finalRows.every((row) => row[key] === null)
  )
  // remove the key with the value null in all rows
  nullKeys.forEach((nullKey) => {
    finalRows = finalRows.map((row) => {
      delete row[nullKey]
      return row
    })
  })

  const filteredKeys = chartData.filteredKeys.filter(
    (key) => !nullKeys.includes(key)
  )

  const transformedKeys = getTransformedKeys(filteredKeys, chartData)

  const finalData = [
    ['date', ...transformedKeys],
    ...finalRows.map((row) => {
      const dateString = new Date(row.date as string)
      const newRow: any[] = [dateString]
      filteredKeys.forEach((key: string) => {
        newRow.push(row[key])
      })
      return newRow
    })
  ]

  return finalData
}

const getDeviationsTrendlinesChart = ({
  chartData
}: {
  chartData: IGoogleChartWithRangeFilterData
}) => {
  const deviations = chartData.deviations
  if (!deviations) return null
  const newTransformedData: {
    [key: string]: IResidualWithDate[]
  } = {}

  const groupdByKey = getAllValuesGroupedByKey(chartData.fetchedData)

  chartData.filteredKeys.forEach((key) => {
    const deviation = deviations.find((deviation) => deviation.fid === key)
    if (!deviation) return
    const data: IMappedDataEntry | undefined = groupdByKey.data.find(
      (data: any) => data.title === key
    )

    if (!data) {
      newTransformedData[key] = []
      return
    }

    const analysed = calculateDeviationsParams({
      trendline: deviation,
      initial: true,
      entry: {
        data
      },
      fullDates: chartData.fullDates,
      noCalc: deviation.type !== 'trendline'
    })

    newTransformedData[key] = analysed.chartData.map((item: any) => ({
      date: item.date,
      value: item['Trendline Values']
    }))
  })

  // combine rows by common dates
  let finalRows: {
    [key: string]: string | null | number | Date
  }[] = []

  chartData.fullDates.forEach((date) => {
    const newRow: any = { date }
    chartData.filteredKeys.forEach((key) => {
      const correspondingValue = newTransformedData[key].find((item) =>
        compareDates(item.date, date)
      ) as any
      const value =
        correspondingValue !== undefined &&
        !isEmpty(correspondingValue['Trendline Values']) &&
        typeof correspondingValue['Trendline Values'] === 'number' &&
        !isNaN(correspondingValue['Trendline Values'])
          ? correspondingValue['Trendline Values']
          : null

      newRow[key] = value
    })
    finalRows.push(newRow)
  })

  // filter out the rows where all values except date are null
  finalRows = finalRows.filter((row) =>
    Object.values(row)
      .slice(1)
      .some((value) => value !== null)
  )

  // find all keys with the value null in all rows
  const nullKeys = chartData.filteredKeys.filter((key) =>
    finalRows.every((row) => row[key] === null)
  )
  // remove the key with the value null in all rows
  nullKeys.forEach((nullKey) => {
    finalRows = finalRows.map((row) => {
      delete row[nullKey]
      return row
    })
  })

  const filteredKeys = chartData.filteredKeys.filter(
    (key) => !nullKeys.includes(key)
  )

  const transformedKeys = getTransformedKeys(filteredKeys, chartData)

  const finalData = [
    ['date', ...transformedKeys],
    ...finalRows.map((row) => {
      const dateString = new Date(row.date as string)
      const newRow: any[] = [dateString]
      filteredKeys.forEach((key: string) => {
        newRow.push(row[key])
      })
      return newRow
    })
  ]

  return finalData
}

export {
  convertJsonToTreeChartData,
  findChartRangeBoundaries,
  findMinChartIndex,
  findMaxChartIndex,
  calculateDeviationsParams,
  calculateAAGR,
  findSelectedChartRangeBoundaries,
  cutOffNulls,
  getRelativeChart,
  getAbsoluteChart,
  getDifferencesChart,
  getDeviationsChart,
  getDeviationsTrendlinesChart,
  getTransformedKeys
}
