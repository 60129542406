import React, { useEffect, useState } from 'react'
import {
  CreateTrendlineProps,
  ICalculateDeviationsParamsReturn,
  ITrendline
} from '../utils/interfaces'
import { calculateDeviationsParams } from '../charts/_googleChartFunctions'
import { HighChartsLinearChart } from '../charts/HighChartsReact'
import { FunctionalButton, Spinner } from './_components'
import RangeSlider2 from './RangeSlider2'
import {
  findFrequencyFromTheName,
  isEmpty,
  transformFetchedRangedDetailsScenariotoRangeInputs
} from '../utils/functions'
import { Checkbox } from 'pretty-checkbox-react'
import { createNewTrendline } from '../utils/fetch'

const CreateTrendline = ({
  fetchedDataTransformed,
  fetchedIndicatorParameters,
  indicatorId,
  source_fid,
  source,
  closeModal,
  fullDates
}: CreateTrendlineProps) => {
  const [data, setData] = useState<ICalculateDeviationsParamsReturn>()
  const [computationMode, setComputationMode] = useState<string>(
    fetchedIndicatorParameters.standard_deviation_chart_mode || 'exponential'
  )
  const [selectedDataBoundaries, setSelectedDataBoundaries] = useState<
    string[]
  >([])

  const handleCalculateTrendlineParameters = (
    parameters: ITrendline,
    initial: boolean
  ) => {
    const firstDate = new Date(fullDates[0])
    const lastDate = new Date(fullDates[fullDates.length - 1])
    const newDateString = `[${firstDate.toISOString().split('T')[0]},${lastDate.toISOString().split('T')[0]})`

    if (isEmpty(parameters.range_chosen)) {
      parameters.range_chosen = newDateString
    }

    if (isEmpty(parameters.range_chosen_deviation)) {
      parameters.range_chosen_deviation = newDateString
    }

    if (isEmpty(parameters.range_complete)) {
      parameters.range_complete = newDateString
    }

    const analysed = calculateDeviationsParams({
      entry: { data: fetchedDataTransformed },
      fullDates: fetchedDataTransformed.dates,
      trendline: parameters as ITrendline,
      initial,
      multiple: false
    })

    // console.log({
    //   a: analysed.a,
    //   b: analysed.b
    // })

    setData(analysed)
  }

  const handleComplete = async (preview: boolean) => {
    const analysedTrendlineData = data?.trendline
    if (!analysedTrendlineData) return false

    const parameters = {
      range_complete: analysedTrendlineData.range_complete,
      range_chosen: analysedTrendlineData.range_chosen,
      range_chosen_deviation: analysedTrendlineData.range_chosen_deviation,
      standard_deviation_parameters:
        analysedTrendlineData.standard_deviation_parameters,
      standard_deviation: analysedTrendlineData.standard_deviation,
      standard_deviation_relative:
        analysedTrendlineData.standard_deviation_relative,
      standard_deviation_chart_mode: computationMode,
      meaning: fetchedIndicatorParameters.meaning,
      short_description: fetchedIndicatorParameters.short_description,
      tags: [],
      type: fetchedIndicatorParameters.type,
      frequency: findFrequencyFromTheName(analysedTrendlineData.frequency),
      data_mode: analysedTrendlineData.data_mode,
      inverse: analysedTrendlineData.inverse,
      title:
        source === 'external'
          ? `${fetchedIndicatorParameters.title} @ ${new Date().toLocaleString()}`
          : 'default'
    }

    if (preview) {
      const previewParameters = {
        ...parameters,
        fid: 'random',
        relation_scenarios: '',
        relation_indicators: '',
        relation_calculated: '',
        base_indicator: '',
        cellular_calculation_mode:
          analysedTrendlineData.cellular_calculation_mode,
        cellular_calculation_mode_months:
          analysedTrendlineData.cellular_calculation_mode_months,
        source,
        forecast_attached: '',
        data_attached: analysedTrendlineData.data_attached,
        id: fetchedIndicatorParameters.id,
        user_id: fetchedIndicatorParameters.user_id,
        category: fetchedIndicatorParameters.category,
        standard_deviation_parameters: JSON.stringify({ a: 0, b: 0 }),
        is_own: fetchedIndicatorParameters.is_own,
        is_public: fetchedIndicatorParameters.is_public,
        columns_selected: fetchedIndicatorParameters.columns_selected,
        chart_columns_selected:
          fetchedIndicatorParameters.chart_columns_selected,
        ChannelContentPiece: fetchedIndicatorParameters.ChannelContentPiece
      }
      const transformedRange =
        transformFetchedRangedDetailsScenariotoRangeInputs(
          analysedTrendlineData.range_chosen_deviation
        )

      handleCalculateTrendlineParameters(previewParameters, false)

      setSelectedDataBoundaries(transformedRange)
      return true
    } else {
      const result = await createNewTrendline({
        indicatorFid: indicatorId,
        sourceInfo: {
          source,
          source_fid
        },
        params: parameters
      })

      if (result) {
        window.switchFunctions.trendline(result)
        return true
      }
      return false
    }
  }

  useEffect(() => {
    handleCalculateTrendlineParameters(
      fetchedIndicatorParameters as ITrendline,
      false
    )
  }, [])

  if (data === undefined) {
    return <Spinner />
  }

  return (
    <div className="entity-blocks">
      <div className="container w-12 no-scrollbar no-borders">
        <div className="flex-column">
          <div className="flex gap-2 middle m-0 p-2 col-12 space-between">
            <RangeSlider2
              dataArray={fetchedDataTransformed.dates}
              initialDetails={transformFetchedRangedDetailsScenariotoRangeInputs(
                fetchedIndicatorParameters?.range_chosen_deviation
              )}
              selectedDetails={selectedDataBoundaries}
              dateSliderMode={true}
              iconMode={'calculate'}
              customStyles={{
                width: 400,
                padding: '6px 20px 6px 5px'
              }}
              noSlider={true}
              noApplyButton={true}
              onApply={() => undefined}
              handleChange={(value: string[]) => {
                const newRange = value.map((item) => item.split('T')[0])
                const newRangeForDb = `[${newRange[0]},${newRange[1]})`
                setData({
                  ...data,
                  trendline: {
                    ...data.trendline,
                    range_chosen_deviation: newRangeForDb
                  }
                })
              }}
            />
          </div>

          <div className="flex gap-2 middle m-0 p-2 col-12">
            <label>Method:</label>
            <select
              value={computationMode || 'exponential'}
              onChange={(e) => setComputationMode(e.target.value)}
            >
              <option value="exponential">Exponential</option>
              <option value="linear">Linear</option>
              {/* <option value="cagr">CAGR</option> */}
              {/* <option value="average">Moving Average</option> */}
            </select>
          </div>

          <div className="flex col-12 margin-1 ">
            <Checkbox
              checked={data.trendline.standard_deviation_relative}
              onChange={() => {
                setData({
                  ...data,
                  trendline: {
                    ...data.trendline,
                    standard_deviation_relative:
                      !data.trendline.standard_deviation_relative
                  }
                })
              }}
              color="success-o"
              animation="pulse"
              shape="curve"
              disabled={false}
            >
              Relative
            </Checkbox>
            <Checkbox
              checked={data.trendline.inverse}
              onChange={() => {
                setData({
                  ...data,
                  trendline: {
                    ...data.trendline,
                    inverse: !data.trendline.inverse
                  }
                })
              }}
              color="success-o"
              animation="pulse"
              shape="curve"
              disabled={false}
            >
              Inverse colours
            </Checkbox>
          </div>
        </div>

        <div className="flex col-12 p-2 align-items-start center gap-3">
          <FunctionalButton
            className="teriary wide"
            initialButtonState={'Discard'}
            iconDelete
            combinedButtonTitle
            functionToExecute={closeModal}
            doesReset
          />
          <FunctionalButton
            className="secondary wide"
            initialButtonState={'Preview'}
            functionToExecute={() => handleComplete(true)}
            doesReset
          />
          <FunctionalButton
            className="primary wide"
            initialButtonState={'Submit'}
            combinedButtonTitle
            iconSaveMode
            functionToExecute={() => handleComplete(false)}
            doesReset
          />
        </div>
      </div>
      <div className="container w-6 auto no-scrollbar">
        <div className="entity-chart">
          <HighChartsLinearChart
            chartOptions={{
              xAxisTitle: 'Month',
              yAxisTitle: 'Value',
              title: 'New Trendline Chart',
              reactive: true,
              mediumSize: true,
              oneAxis: true
            }}
            chartData={{
              fetchedData: data.chartData || [],
              fullDates: fetchedDataTransformed.dates,
              titles: [{ title: '' }],
              filteredKeys: ['Trendline Values', indicatorId],
              allKeys: Array.isArray(data.trendline.chart_columns_selected)
                ? data.trendline.chart_columns_selected
                : [data.trendline.chart_columns_selected || '']
            }}
          />
        </div>
      </div>
      <div className="container w-6 auto no-scrollbar">
        <div className="entity-chart">
          <HighChartsLinearChart
            chartOptions={{
              xAxisTitle: 'Month',
              yAxisTitle: 'Value',
              title: 'Residuals Chart',
              reactive: true,
              mediumSize: true,
              oneAxis: true
            }}
            chartData={{
              fetchedData: data.residualsDataSet || [],
              fullDates: fetchedDataTransformed.dates,
              titles: [{ title: '' }],
              filteredKeys: [' Deviation'],
              allKeys: Array.isArray(data.trendline.chart_columns_selected)
                ? data.trendline.chart_columns_selected
                : [data.trendline.chart_columns_selected || '']
            }}
          />
        </div>
      </div>
    </div>
  )
}

export default CreateTrendline
