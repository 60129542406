import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import {
  IQuickSearchEntity,
  IChannelContentPiece,
  IThumbnail,
  ICustomContextMenuSettings
} from '../utils/interfaces'
import {
  getChannelDataById,
  getMemoLink,
  initialSharing,
  subscribeToChannel,
  unsubscribeFromChannel,
  updateUserSettings
} from '../utils/fetch'
import PopupModal from '../helperComponents/PopUpModal'
import EntitySharingControls from '../helperComponents/EntitySharingControls'
import { capitalise } from '../utils/functions'
import {
  EntityHeader,
  InlineSpinner,
  Spinner,
  Thumbnails
} from '../helperComponents/_components'
import QuickSearch from '../helperComponents/QuickSearch'
import {
  Icon3Dots,
  IconUploadNew,
  // ImagePlaceHolderNoImage,
  TickFail,
  TickSuccess
} from '../helperComponents/Icons'
import CustomContextMenu from '../helperComponents/CustomContextMenu'

const ChannelPage = ({
  entities,
  channelId
}: {
  entities: IQuickSearchEntity[]
  channelId: string
}) => {
  const channelContentPieces = useRef<IChannelContentPiece[]>([])
  const [subscribersCount, setSubscribersCount] = useState<number>(0)
  const contextMenuDefaultState = {
    show: false,
    top: 0,
    left: 0 - 500
  }
  const [contextMenuDetails, setContextMenuDetails] =
    useState<ICustomContextMenuSettings>(contextMenuDefaultState)
  const resetContextMenuDetails = () =>
    contextMenuDetails.show && setContextMenuDetails(contextMenuDefaultState)
  const [username, setUsername] = useState<string>('')
  const [entityInFocus, setEntityInFocus] =
    useState<IChannelContentPiece | null>(null)
  const [loading, setLoading] = useState(true)
  const [modalAddNew, setModalAddNew] = useState(false)
  const [entityToShare, setEntityToShare] = useState<IQuickSearchEntity | null>(
    null
  )
  const [modalChannelSettings, setModalChannelSettings] = useState(false)
  const [imageLink, setImageLink] = useState<string>('')
  const [isOwn, setIsOwn] = useState<boolean>(false)
  const [subscribed, setSubscribed] = useState<boolean>(false)
  const [uploadState, setUploadState] = useState<
    'loading' | 'error' | 'success' | 'ready'
  >('ready')

  const initialiseSharing = async () => {
    if (!entityToShare || !isOwn) return false

    const res = await initialSharing({
      sharingTier: 0,
      entityId: entityToShare.id,
      entityType: entityToShare.type.toLowerCase()
    })

    return res
  }

  const reset = () => {
    setEntityInFocus(null)
    setModalAddNew(false)
    resetContextMenuDetails()
    setEntityToShare(null)
    setModalChannelSettings(false)
    setUploadState('ready')
  }

  const handleContextMenuDetails = (e: any) => {
    e.preventDefault()

    if (contextMenuDetails.show) {
      resetContextMenuDetails()
      return
    }

    reset()
    setContextMenuDetails({
      show: true,
      top: e.pageY,
      left: e.pageX + 10
    })
  }

  const updateProfileInformation = async () => {
    if (!isOwn) return false

    const res = await updateUserSettings({
      ...window.globalSettings,
      username,
      profile_image: imageLink
    })

    if (res) {
      window.location.reload()
      setModalChannelSettings(false)
    }

    return res
  }

  const initialFetch = useCallback(
    async (channelId: string) => {
      setLoading(true)
      reset()
      const res = await getChannelDataById(channelId)
      const {
        username,
        profile_image,
        data,
        isOwn,
        isSubscribed,
        subscribersCount
      } = res
      setSubscribersCount(subscribersCount)
      channelContentPieces.current = data
      setUsername(username)
      setIsOwn(isOwn)
      setSubscribed(isSubscribed)
      setImageLink(profile_image)
      setLoading(false)
    },
    [channelId]
  )

  const refreshChannel = async () => {
    await initialFetch(channelId)
  }

  const transformedEntities: IQuickSearchEntity[] = useMemo(
    () =>
      entities
        .filter(
          (entity) =>
            !channelContentPieces.current.some(
              (ref) => ref.source_fid === entity.id
            ) && entity.is_own
        )
        .map((entity) => ({
          ...entity,
          functionToExecute: {
            function: () => setEntityToShare(entity),
            title: 'Initialise Sharing'
          }
        })),
    [entities]
  )

  const handleAddImage = async (file: File) => {
    const imageType = file.type.split('/')[1]
    const link = await getMemoLink(file, imageType)

    if (!link) {
      setUploadState('error')
      return
    }

    setUploadState('success')
    setImageLink(link)
    return link
  }

  useEffect(() => {
    refreshChannel()
  }, [channelId])

  if (loading) {
    return <Spinner />
  }

  return (
    <div className="entity channel default-text">
      <EntityHeader
        subtitle="Channel"
        ownerInfo={{ profileImage: imageLink, username }}
        title={username}
        description={`${subscribersCount} subscribers`}
      />
      <div className="entity-blocks">
        <div className="container w-12 fit">
          {' '}
          <div className="entity-parameters justify-content-start">
            <button
              className="no-btn flex no-hover"
              onClick={handleContextMenuDetails}
            >
              <Icon3Dots />
            </button>
          </div>
        </div>

        <div className="container w-12 fit">
          <Thumbnails
            itemsInRow={4}
            thumbnails={[
              {
                title: 'Add New',
                subtitle: '',
                image: '',
                details: 'Click to share a new entity to your channel',
                onClick: () => setModalAddNew(true),
                tags: [],
                addNewMode: true
              },
              ...channelContentPieces.current
                .filter((entity) => (isOwn ? entity.is_own : !entity.is_own))
                .map(
                  (entity): IThumbnail => ({
                    title: entity.title,
                    subtitle: capitalise(entity.source_type),
                    image: entity.link || '',
                    details: entity.short_description || '',
                    onClick: () =>
                      window.switchFunctions[
                        entity.source_type as keyof typeof window.switchFunctions
                      ](entity.source_fid),
                    editFunction: isOwn
                      ? () => setEntityInFocus(entity)
                      : undefined,
                    ownerInfo: entity.owner_info,
                    tags: entity.tags
                  })
                )
            ].filter((entity) => (!isOwn ? entity.title !== 'Add New' : true))}
          />
        </div>
      </div>
      {entityInFocus !== null && isOwn && (
        <PopupModal
          isOpen={entityInFocus !== null}
          onClose={() => setEntityInFocus(null)}
          title={`Edit Sharing for ${entityInFocus.title}`}
          size="large"
          noChanges
        >
          <EntitySharingControls
            entityType="scenario"
            entityId={''}
            ChannelContentPiece={entityInFocus}
            refreshFunction={refreshChannel}
          />
        </PopupModal>
      )}
      {modalAddNew && transformedEntities && isOwn && (
        <PopupModal
          isOpen={modalAddNew}
          onClose={() => setModalAddNew(false)}
          title="Choose an entity to share to your channel"
          size="large"
          noChanges
          handleSubmit={initialiseSharing}
          saveButtonExists
          refreshOnComplete={{
            exists: true,
            refreshFunction: refreshChannel
          }}
          saveButtonDisabled={!entityToShare}
        >
          {entityToShare && (
            <h2 className="col-12 p-2">{entityToShare.name} selected</h2>
          )}
          <QuickSearch entities={transformedEntities} typeSwitcher={true} />
        </PopupModal>
      )}
      <CustomContextMenu
        contextMenuSettings={contextMenuDetails}
        menu={[
          {
            onClick: () => {
              reset()
              setModalChannelSettings(!modalChannelSettings)
            },
            title: 'Edit Details',
            noAccess: !isOwn
          },
          {
            onClick: () => {
              reset()
              subscribed
                ? unsubscribeFromChannel(channelId)
                : subscribeToChannel(channelId)
              refreshChannel()
            },
            title: subscribed ? 'Unsubscribe' : 'Subscribe',
            noAccess: isOwn
          },
          {
            onClick: () => {
              reset()
              navigator.clipboard.writeText(window.location.href)
              return true
            },
            title: 'Copy URL'
          }
        ]}
      />

      {modalChannelSettings && isOwn && (
        <PopupModal
          isOpen={modalChannelSettings}
          onClose={() => setModalChannelSettings(false)}
          title="Channel Settings"
          size="large"
          noChanges
          saveButtonExists
          handleSubmit={updateProfileInformation}
          saveButtonDisabled={!username}
        >
          <div className="profile">
            <div className="profile-image">
              {imageLink && (
                <img className="" src={imageLink} alt="Thumbnail" />
              )}
              <div className="edit-text pointer">
                <div className={'file-upload flex middle'}>
                  <label htmlFor="file-input" className="file-input">
                    {
                      {
                        loading: <InlineSpinner />,
                        error: <TickFail />,
                        success: <TickSuccess />,
                        ready: <IconUploadNew />
                      }[uploadState]
                    }
                  </label>
                  <input
                    type="file"
                    id="file-input"
                    name="file-input"
                    onChange={async (e) => {
                      if (e.target.files) {
                        handleAddImage(e.target.files[0])
                      }
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="profile-info">
              <input
                className="name"
                type="text"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                placeholder="Your Channel Name"
              />
            </div>
          </div>
        </PopupModal>
      )}
    </div>
  )
}

export default ChannelPage
