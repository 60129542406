import React, { useState } from 'react'
import { isLoggedIn } from '../utils/functions'
import { LoginFormData } from '../utils/interfaces'
import { FunctionalButton } from '../helperComponents/_components'
import { handleFormSubmitLogin } from '../utils/fetch'

const LoginPage = () => {
  if (isLoggedIn()) {
    window.location.href = '/app'
  }
  const [formData, setFormData] = useState<LoginFormData>({
    username: '',
    password: ''
  })

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({ ...formData, [e.target.name]: e.target.value })
  }

  return (
    <div className="login-container">
      <div className="login-form">
        <div className="form-field">
          <label className="form-label">Username:</label>
          <input
            type="text"
            name="username"
            value={formData.username}
            onChange={handleInputChange}
            className="form-input"
          />
        </div>
        <div className="form-field">
          <label className="form-label">Password:</label>
          <input
            type="password"
            name="password"
            value={formData.password}
            onChange={handleInputChange}
            className="form-input"
          />
        </div>
        <FunctionalButton
          functionToExecute={() => handleFormSubmitLogin({ formData })}
          initialButtonState={'Login'}
          className="login-button primary"
          doesReset
        />
      </div>

      <p className="register-text">
        Do not have an account? Contact the administrator to register.
        {/* <button
          className="tab secondary"
          onClick={() => (window.location.href = '/register')}
        >
          {' '}
          Register now
        </button> */}
      </p>
    </div>
  )
}

export default LoginPage
