import React, { Fragment, useState } from 'react'
import { FunctionalButton } from './_components'

const RenameMajorEntity = ({
  functionToExecute,
  title,
  description,
  short_description
}: {
  functionToExecute: any
  title: string
  description: string
  short_description: string
}) => {
  const [newName, setNewName] = useState<string>(title)
  const [newDescription, setNewDescription] = useState<string>(description)
  const [newShortDescription, setNewShortDescription] =
    useState<string>(short_description)

  const undoNamingChanges = () => {
    setNewName(title)
    setNewDescription(description)
    setNewShortDescription(short_description)
  }

  const handleRename = async () => {
    const res = await functionToExecute({
      newName,
      newDescription,
      newShortDescription
    })

    return res
  }

  return (
    <Fragment>
      <input
        className="input center middle flex col-12"
        type="text"
        value={newName}
        onChange={(e) => setNewName(e.target.value)}
        placeholder="Indicator name"
      />
      <textarea
        rows={5}
        value={newShortDescription}
        onChange={(e) => setNewShortDescription(e.target.value)}
        placeholder="Short description"
      />
      <textarea
        rows={10}
        value={newDescription}
        onChange={(e) => setNewDescription(e.target.value)}
        placeholder="Indicator description"
      />
      <div className="flex gap-3">
        <button
          className="no-btn border center middle full"
          onClick={undoNamingChanges}
        >
          Undo
        </button>
        <FunctionalButton
          className="primary border center middle full"
          functionToExecute={handleRename}
          initialButtonState="Save"
          iconSaveMode
          combinedButtonTitle
          disabled={newName === ''}
        />
      </div>
    </Fragment>
  )
}

export default RenameMajorEntity
