import React, { useMemo } from 'react'
import { ICustomContextMenu } from '../utils/interfaces'
import { FunctionalButton } from './_components'
import { IconMoreLeft } from './Icons'

const CustomContextMenu = ({
  contextMenuSettings,
  menu
}: ICustomContextMenu) => {
  return (
    <div
      className="custom-context-menu"
      style={{
        top: contextMenuSettings.top,
        left: contextMenuSettings.left,
        display: contextMenuSettings.show ? 'flex' : 'none'
      }}
    >
      {menu.map((menuItem, index) => {
        if (menuItem.noAccess) return null

        if (menuItem.submenu && menuItem.submenu.length > 0) {
          const [submenuOpen, setSubmenuOpen] = React.useState(false)
          const submenuPotision = useMemo(() => {
            let top: any = `calc(${contextMenuSettings.top}px - 100% + 24px * ${index})`

            if (
              contextMenuSettings.submenuPosition &&
              contextMenuSettings.submenuPosition === 'bottom'
            ) {
              top = `calc(${contextMenuSettings.top}px - 30px * (${index - 1}))`
            }

            return {
              top,
              left: 'calc(100% + 0.5px)'
            }
          }, [contextMenuSettings, index])

          return (
            <div key={menuItem.title} className="submenu">
              <button
                className={`flex align-center space-between no-btn wider center select t-left ${
                  menuItem.className || ''
                }`}
                onMouseEnter={() => setSubmenuOpen(true)}
                onMouseLeave={() => setSubmenuOpen(false)}
              >
                {menuItem.title}
                <IconMoreLeft />
              </button>
              {submenuOpen && menuItem.submenu.length > 0 && (
                <div
                  onMouseEnter={() => setSubmenuOpen(true)}
                  onMouseLeave={() => setSubmenuOpen(false)}
                  className="submenu-items"
                  style={{
                    display: submenuOpen ? 'flex' : 'none',
                    top: submenuPotision.top || 'auto',
                    left: submenuPotision.left || 'auto'
                  }}
                >
                  {menuItem.submenu.map((item) => {
                    if (item.noAccess) return null
                    if (
                      item.functionalButtonSettings &&
                      item.functionalButtonSettings.exists
                    ) {
                      return (
                        <FunctionalButton
                          key={item.title}
                          className={`flex align-center space-between no-btn wider center select t-left ${
                            item.className || ''
                          }`}
                          functionToExecute={item.onClick}
                          initialButtonState={item.title}
                          refreshOnComplete={item.functionalButtonSettings}
                          doesReset={item.functionalButtonSettings.doesReset}
                        />
                      )
                    }
                    return (
                      <button
                        key={item.title}
                        className={`flex align-center space-between no-btn wider center select t-left ${
                          item.className || ''
                        }`}
                        onClick={item.onClick}
                      >
                        {item.title}
                      </button>
                    )
                  })}
                </div>
              )}
            </div>
          )
        }

        if (
          menuItem.functionalButtonSettings &&
          menuItem.functionalButtonSettings.exists
        ) {
          return (
            <FunctionalButton
              key={menuItem.title}
              className={`flex align-center space-between default-text no-btn wider center select t-left ${
                menuItem.className || ''
              }`}
              functionToExecute={menuItem.onClick}
              initialButtonState={menuItem.title}
              refreshOnComplete={menuItem.functionalButtonSettings}
            />
          )
        }
        return (
          <button
            key={menuItem.title}
            className={`flex align-center space-between default-text no-btn wider center select t-left ${
              menuItem.className || ''
            }`}
            onClick={menuItem.onClick}
          >
            {menuItem.title}
          </button>
        )
      })}
    </div>
  )
}

export default CustomContextMenu
