import React, { useEffect, useMemo, useState } from 'react'
import Chart, { GoogleChartWrapper } from 'react-google-charts'
import iconDownload from '../assets/icon-download.png'
import { ILinearChartProps, ITreeChartDataInput } from '../utils/interfaces'
import {
  findClosestMidnight,
  getMaxDifferences
} from '../utils/transformingData'
// import { Checkbox } from 'pretty-checkbox-react'
import { filterOutDevFromName, isEmpty } from '../utils/functions'
import {
  convertJsonToTreeChartData,
  getAbsoluteChart,
  getDeviationsChart,
  getDeviationsTrendlinesChart,
  getDifferencesChart,
  getRelativeChart
} from '../charts/_googleChartFunctions'
import { Spinner, TabbingSwitch } from '../helperComponents/_components'
import { IconCaptureImage } from '../helperComponents/Icons'
// import { CHART_COLOURS } from '../utils/_constants'

const XLSX = require('xlsx')

const downloadExcel = (data: any, filename: any) => {
  const ws = XLSX.utils.aoa_to_sheet(data)
  const wb = XLSX.utils.book_new()
  XLSX.utils.book_append_sheet(wb, ws, 'Sheet1')
  XLSX.writeFile(wb, filename)
}

const GoogleChart: any = Chart
const GoogleChartWithRangeFilter = ({
  chartData,
  chartOptions,
  componentOptions
}: ILinearChartProps) => {
  try {
    if (!chartData || chartData.filteredKeys.length === 0 || !chartOptions) {
      return (
        <Spinner
          options={{
            customMessage: 'No data to display',
            noSpinner: true,
            noDots: true,
            noTransform: true
          }}
          customStyles={{
            marginTop: '40px'
          }}
        />
      )
    }
    const [chart, setChart] = useState<GoogleChartWrapper>()
    const initialMode = useMemo(() => {
      if (componentOptions?.onlyDeviations) {
        return 'deviations'
      }
      return 'absolute'
    }, [])

    const [chartMode, setChartMode] = useState<
      | 'absolute'
      | 'relativeToFirstValue'
      | 'logarithmic'
      | 'deviations'
      | 'differences'
      | 'deviationsTrendlines'
    >(initialMode)
    const [newChartOptions, setChartOptions] = useState<any>({
      ...chartOptions
    })
    const [loading, setLoading] = useState(true)
    // const [indicatorToPaint, setIndicatorToPaint] = useState('')

    const chartOptionsParams =
      (componentOptions && componentOptions.parametersFetched) || {}

    const possibleKeys = useMemo(() => {
      return [
        'scenario_name',
        'chart_columns_selected',
        'core_indicators',
        'range_chosen',
        'range_complete',
        'title',
        'type',
        'category',
        'meaning',
        'range_chosen',
        'range_chosen_deviation',
        'range_complete',
        'fid'
      ]
    }, [])

    const [transformedData, setTransformedData] = useState<any>([
      ['date', 'dummy'],
      [new Date('1992-01-01'), 1]
    ])

    const getChartOptions = (
      mode:
        | 'absolute'
        | 'relativeToFirstValue'
        | 'logarithmic'
        | 'deviations'
        | 'differences'
        | 'deviationsTrendlines',
      data?: any
    ) => {
      const oneAxis =
        chartOptions?.oneAxis ||
        mode === 'relativeToFirstValue' ||
        // mode === 'deviations' ||
        false
      let vAxisOptions: any = {
        textStyle: {
          bold: true
        },
        titleTextStyle: {
          bold: true
        },
        gridlines: {
          count: 3
        }
      }
      const seriesOptions: any = {}

      // if (mode === 'absolute') {
      if (!oneAxis) {
        const transformedDataUsed = data || transformedData
        const seriesPreferredVerticalAxis =
          getMaxDifferences(transformedDataUsed)

        // Assigning preferred vertical axis to each series and assigning colors
        seriesPreferredVerticalAxis.forEach((preferredAxis, index) => {
          seriesOptions[index] = {
            targetAxisIndex: preferredAxis
            // color:
            //   preferredAxis === 0
            //     ? CHART_COLOURS.primary[index]
            //     : CHART_COLOURS.secondary[index]
          }
        })
      } else {
        Object.keys({ ...chartData }.fetchedData)
          .sort()
          .forEach((key, index) => {
            seriesOptions[key] = {
              targetAxisIndex: 0
              // color: CHART_COLOURS.primary[index]
            }
          })
      }
      // Define vertical axes titles
      vAxisOptions = {
        ...vAxisOptions,
        0: { title: chartOptions.yAxisTitle },
        1: { title: 'Second Y Axis' }
      }
      // }

      if (
        mode === 'relativeToFirstValue' ||
        (Object.values(chartData.fetchedData[0])
          // has a percentage sign
          .some((value) => typeof value === 'string' && value.includes('%')) &&
          mode === 'absolute')
      ) {
        vAxisOptions = { ...vAxisOptions, format: '##%' }
      }

      if (mode === 'logarithmic') {
        vAxisOptions = { ...vAxisOptions, scaleType: 'log' }
      }

      return {
        ...chartOptions,
        vAxis: vAxisOptions,
        series: seriesOptions,
        title: '',
        hAxis: {
          // title: chartOptions.xAxisTitle,
          textStyle: {
            fontSize: 12,
            bold: true
          },
          titleTextStyle: {
            fontSize: 12,
            bold: true
          },
          gridlines: {
            count: 10
          }
        },
        animation: {
          startup: true,
          duration: 300,
          easing: 'inAndOut'
        },
        chartArea: {
          width: chartOptions.fullSize ? '90%' : '75%',
          height: '80%',
          backgroundColor: {
            // stroke: '#D5DBDB',
            strokeWidth: 1
          }
        },
        backgroundColor: {
          // fill: '#F8F9F9', // Light grey background
          strokeWidth: 0
        }
      }
    }

    const handleChartOptions = (
      data?: any,
      mode?:
        | 'absolute'
        | 'relativeToFirstValue'
        | 'logarithmic'
        | 'deviations'
        | 'differences'
        | 'deviationsTrendlines'
    ) => {
      const modeToPassDown = mode || chartMode
      const chartOptions = getChartOptions(modeToPassDown, data)
      setChartOptions(chartOptions)
    }

    const getFilteredParams = (data: any, keysToInclude: any) =>
      Object.entries(data)
        .filter(([key]) => keysToInclude.includes(key))
        .map(([key, value]) => [key, value])

    const fetchedParamsForExport = useMemo(() => {
      return getFilteredParams(chartOptionsParams, possibleKeys)
    }, [chartOptionsParams, possibleKeys])

    // const controls = useMemo(() => {
    //   // This will only recompute if one of the dependencies has changed.
    //   return componentOptions?.noRangeFilter
    //     ? []
    //     : [
    //         {
    //           controlType: 'ChartRangeFilter',
    //           options: {
    //             filterColumnIndex: 0,
    //             ui: {
    //               chartType:
    //                 chartMode === 'differences' ? 'ColumnChart' : 'LineChart',
    //               chartOptions: {
    //                 chartArea: {
    //                   width: '80%',
    //                   height: chartOptions.fullSize ? '50%' : '25%'
    //                 }
    //               }
    //             }
    //           },
    //           controlPosition: 'bottom',
    //           controlWrapperParams: {
    //             state: {
    //               range: {
    //                 start:
    //                   chartOptions.rangeSelected !== undefined
    //                     ? new Date(chartOptions.rangeSelected[0])
    //                     : chartData.fullDates !== undefined
    //                       ? new Date(chartData.fullDates[0])
    //                       : new Date('1992-01-01'),
    //                 end:
    //                   chartOptions.rangeSelected !== undefined
    //                     ? new Date(chartOptions.rangeSelected[1])
    //                     : chartData.fullDates !== undefined
    //                       ? new Date(
    //                           chartData.fullDates[
    //                             chartData.fullDates.length - 1
    //                           ]
    //                         )
    //                       : new Date('2023-10-02')
    //               },
    //               chartType: 'ColumnChart',
    //               chartOptions: {
    //                 chartArea: { width: '100%', height: '100%' }
    //               }
    //             }
    //           }
    //         }
    //       ]
    // }, [
    //   componentOptions,
    //   chartOptions.rangeSelected,
    //   chartData.fullDates,
    //   chartOptions.fullSize
    // ]) // Add all dependencies here

    const combineDataForExport = () => {
      const modifiedTransformedData = [...transformedData]

      if (
        modifiedTransformedData.length > 0 &&
        Array.isArray(modifiedTransformedData[0])
      ) {
        for (let i = 0; i < modifiedTransformedData[0].length; i++) {
          if (modifiedTransformedData[0][i].length > 8) {
            modifiedTransformedData[0][i] = modifiedTransformedData[0][i].slice(
              0,
              -9
            )
          }
        }
      }

      return [...fetchedParamsForExport, [], [], ...modifiedTransformedData]
    }

    const handleExport = (image?: boolean) => {
      const date = new Date()
      const shortDate = date.toISOString().split('T')[0]

      const chartModeLabel = chartMode === 'absolute' ? 'values' : chartMode
      const entityName =
        'scenario_name' in chartOptionsParams
          ? chartOptionsParams.scenario_name
          : 'title' in chartOptionsParams
            ? chartOptionsParams.title
            : 'Chart'

      const exportName = `${entityName} ${chartModeLabel} - Chartit Export on ${shortDate}`

      if (image && chart) {
        const chartObj = chart.getChart()

        if (typeof chartObj.getImageURI !== 'function') {
          return console.warn('getImageURI is not a function')
        }

        const imgUri = chartObj.getImageURI() as any

        const downloadLink = document.createElement('a')
        downloadLink.href = imgUri
        downloadLink.download = exportName
        document.body.appendChild(downloadLink)
        downloadLink.click()
        document.body.removeChild(downloadLink)

        return
      }
      const combinedData = combineDataForExport()

      if (combinedData) {
        downloadExcel(combinedData, exportName + '.xlsx')
      }
    }

    // const evaluateZonesForIndicator = (indicatorName: string, months: Date[]) => {
    //   if (!componentOptions?.retrieveIndicatorZones || indicatorName === '') {
    //     return
    //   }
    //   const zones = componentOptions?.retrieveIndicatorZones(
    //     indicatorName,
    //     months
    //   )
    //   handleChartPaint(zones, months)
    // }

    const preProcessData = (data: any) => {
      const formattedData = data.map((row: any, index: number) => {
        let newRow = [...row]
        if (index === 0) {
          // filter out the fucking retarded Dev. that is a hack on its own,
          // see the filterOutDevFromName function for further explanation
          newRow = newRow.map((part: string) =>
            part.includes('Dev.') ? filterOutDevFromName(part) : part
          )
        } else {
          newRow[0] = findClosestMidnight(newRow[0])
          const isAllNulls =
            [...newRow]
              .slice(1)
              .filter((_) => !(isEmpty(_) || _ === 0 || _ === '0')).length === 0
          if (isAllNulls) {
            return [newRow[0], [...newRow.slice(1).map((_) => null)]].flat()
          }
        }
        return newRow
      })

      return formattedData
    }

    const handleModeChange = (
      newMode:
        | 'absolute'
        | 'relativeToFirstValue'
        | 'logarithmic'
        | 'deviations'
        | 'differences'
        | 'deviationsTrendlines'
    ) => {
      try {
        newMode = newMode || chartMode || initialMode
        if (chartMode === newMode && newMode !== initialMode) return
        setChartMode(newMode)
        let data: any
        if (newMode === 'relativeToFirstValue') {
          data = getRelativeChart({ chartData, chart })
        } else if (newMode === 'deviations') {
          data = getDeviationsChart({ chartData })
        } else if (newMode === 'differences') {
          data = getDifferencesChart({ chartData })
        } else if (newMode === 'deviationsTrendlines') {
          data = getDeviationsTrendlinesChart({ chartData })
        } else {
          data = getAbsoluteChart({ chartData })
        }
        if (!data) {
          console.warn('no data', { data, newMode, chartMode, initialMode })
        }

        setTransformedData(preProcessData(data))
        handleChartOptions(data, newMode)
      } catch (e) {
        console.error('Error in handleModeChange', e)
        try {
          const data =
            initialMode === 'deviations'
              ? getDeviationsChart({ chartData })
              : getAbsoluteChart({ chartData })
          setTransformedData(preProcessData(data))
          handleChartOptions(data, initialMode)
        } catch (e) {
          console.error(
            'Error with the data and chartOptions in handleModeChange',
            e
          )
          setTransformedData([])
          return null
        }
      }
    }

    // const handleIndicatorToPaint = (indicatorName: string) => {
    //   const dataTable = chart.getDataTable()
    //   const months = dataTable.Ta.Wf.map((month: any) => month.c[0].v)

    //   handleChartOptions()
    //   setIndicatorToPaint(indicatorName)
    //   evaluateZonesForIndicator(indicatorName, months)
    // }

    useEffect(() => {
      // only trigger reset nce
      if (chartOptions.reactive) handleModeChange(chartMode)
    }, [chartData])

    useEffect(() => {
      if (chart && chartOptions.fullSize) {
        handleChartOptions()
      }
    }, [chart])

    useEffect(() => {
      if (loading && transformedData !== undefined) {
        setLoading(false)
      }
      setChartOptions((prev: any) => {
        return {
          ...prev,
          legend: {
            position: 'top',
            alignment: 'start',
            maxLines: 3,
            textStyle: {
              fontSize: 12
            }
          }
        }
      })
    }, [loading])

    useEffect(() => {
      handleModeChange(chartMode)
    }, [])

    if (loading) {
      return (
        <Spinner
          options={{
            noDots: true,
            noMessage: true
          }}
        />
      )
    }

    return (
      <div
        className="col-12"
        style={{
          height: '100%',
          position: 'relative'
        }}
      >
        <div className="entity-parameters chart">
          {(chartOptions.fullSize ||
            chartOptions.downloadButton ||
            chartOptions.mediumSize) && (
            <button
              className="icon download-chart"
              onClick={() => handleExport(false)}
            >
              <img src={iconDownload} alt="" className="icon inline" />
            </button>
          )}
          {(chartOptions.fullSize || chartOptions.mediumSize) && (
            <button
              className="icon capture-chart"
              onClick={() => handleExport(true)}
            >
              <IconCaptureImage />
            </button>
          )}
          {(chartOptions.fullSize || chartOptions.mediumSize) && (
            <TabbingSwitch
              numberVisible={3}
              className="justify-content-end"
              options={[
                {
                  label: 'Absolute',
                  active: chartMode === 'absolute',
                  onClick: () => handleModeChange('absolute')
                },
                {
                  label: 'Relative',
                  active: chartMode === 'relativeToFirstValue',
                  onClick: () => handleModeChange('relativeToFirstValue')
                },
                {
                  label: 'Logarithmic',
                  active: chartMode === 'logarithmic',
                  onClick: () => handleModeChange('logarithmic')
                }
              ]}
            />
          )}
          {/*
                {
                  label: 'Deviations',
                  active: chartMode === 'deviations',
                  onClick: () => handleModeChange('deviations'),
                  exists: chartData.deviations !== undefined
                },
                {
                  label: 'Deviations Trendlines',
                  active: chartMode === 'deviationsTrendlines',
                  onClick: () => handleModeChange('deviationsTrendlines')
                }
              ]}
            /> */}
        </div>

        <GoogleChart
          // loading={!transformedData}
          key={chartMode}
          chartType={chartMode === 'differences' ? 'ColumnChart' : 'LineChart'}
          width="100%"
          height={chartOptions.fullSize ? '60vh' : '40vh'}
          data={transformedData}
          options={{
            ...newChartOptions,
            legend: {
              position: 'top',
              alignment: 'start',
              maxLines: 3,
              textStyle: {
                fontSize: 10
              }
            },
            explorer: {
              actions: ['dragToZoom', 'rightClickToReset'],
              axis: 'horizontal',
              keepInBounds: true,
              maxZoomIn: 10.0
            }
          }}
          className="google-chart"
          chartEvents={[
            {
              eventName: 'ready',
              callback: ({
                chartWrapper
              }: {
                chartWrapper: GoogleChartWrapper
              }) => {
                setLoading(false)
                setChart(chartWrapper)
              }
            },
            {
              eventName: 'error',
              callback: (e: { id: string; message: string }) => {
                // setLoading(false)
                console.error(`Error in chart: ${e.message}`)
              }
            }
          ]}
          setOnLoadCallback={(chartWrapper: GoogleChartWrapper) => {
            setLoading(false)
            setChart(chartWrapper)
            console.info('Chart Mounted')
          }}
          // controls={controls}
        />
      </div>
    )
  } catch (e) {
    return (
      <h2 className="flex middle center w-100 h-100">
        Please recalculate. If the problem persists, please contact the
        administrator.
      </h2>
    )
  }
}

const MyTreeChart = ({
  jsonData,
  dataOptions
}: {
  jsonData: ITreeChartDataInput | string
  dataOptions: {
    titles: {
      name: string
      title: string
    }[]
  }
}) => {
  try {
    if (typeof jsonData === 'string' && !isEmpty(jsonData)) {
      return <h2>Invalid JSON</h2>
    }

    const data = convertJsonToTreeChartData(jsonData, dataOptions)
    const options = {
      allowHtml: true,
      size: 'medium', // Can be small, medium, or large
      fontName: 'Arial', // Font for the text
      fontSize: 14, // Size of the font
      nodeClass: 'myNodeStyle', // Custom CSS class for nodes
      selectedNodeClass: 'mySelectedNodeStyle', // Custom CSS class for selected node
      chartArea: { width: '100%', height: '100%' }, // Adjusts the chart area
      tooltip: { isHtml: true } // Enables HTML tooltips
    }

    return (
      <Chart
        chartType="OrgChart"
        data={data}
        options={options}
        width="100%"
        height="100%"
      />
    )
  } catch (e) {
    return <h2>Invalid JSON</h2>
  }
}

export { GoogleChartWithRangeFilter, MyTreeChart }
