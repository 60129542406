import React, { useMemo } from 'react'

const Updates = ({
  updates,
  fullHeight
}: {
  updates: {
    title: string
    date: string
    author: string
    status: string
    link: string | null
  }[]
  fullHeight?: boolean
}) => {
  const sortedUpdates = useMemo(() => {
    return updates.sort((a, b) => {
      return new Date(b.date).getTime() - new Date(a.date).getTime()
    })
  }, [updates])

  return (
    <div className={`news-container${fullHeight ? ' full' : ''}`}>
      <table>
        <thead>
          <tr>
            <th className="large">Update</th>
            <th>Date</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          {sortedUpdates.map((updateItem, index) => (
            <tr
              key={index}
              title={`By: ${updateItem.author}`}
              style={{
                cursor: updateItem.link ? 'pointer' : 'default'
              }}
              onClick={() =>
                updateItem.link
                  ? (window.location.href = updateItem.link)
                  : undefined
              }
              className={
                updateItem.status === 'Completed'
                  ? 'completed'
                  : updateItem.status === '(Live) Final Testing'
                    ? 'live-testing'
                    : 'in-progress'
              }
            >
              <td className="large">{updateItem.title}</td>
              <td>{updateItem.date}</td>
              <td>{updateItem.status}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}

export default Updates
