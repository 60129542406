import { Checkbox } from 'pretty-checkbox-react'
import React, { useState } from 'react'
import {
  IBasicIndicator,
  ICalculatedIndicator,
  IHomepageSection,
  IMemo,
  IScenarioItem,
  IQuickSearchEntity,
  IUserSettings
} from '../utils/interfaces'
import { TabbingSwitch } from '../helperComponents/_components'
import IndicatorsAndTrendlinesSearch from '../helperComponents/IndicatorsAndTrendlinesSearch'
import { IconClose } from '../helperComponents/Icons'
import { isEmpty } from '../utils/functions'
import QuickSearch from '../helperComponents/QuickSearch'

const GlobalSettings = ({
  fullFetchedIndicatorsParameters,
  memos,
  scenarios
}: {
  fullFetchedIndicatorsParameters:
    | IBasicIndicator[]
    | ICalculatedIndicator[]
    | any
  memos: IMemo[]
  scenarios: IScenarioItem[]
}) => {
  const initialSettings = window.globalSettings as IUserSettings
  const [userSettings, setUserSettings] =
    useState<IUserSettings>(initialSettings)
  const [memosBox, setMemosBox] = useState<string[]>(
    userSettings.homepage_memos
  )
  const [scenariosBox, setScenariosBox] = useState<string[]>(
    userSettings.homepage_scenarios
  )
  const [settingsModalMode, setSettingsModalMode] = useState<string>('zones')
  const [sections, setSections] = useState<IHomepageSection[]>(
    initialSettings.homepage_trendlines
  )

  const addSection = () => {
    const newSectionTitle = `section_${sections.length + 1}`

    setSections((prevSections) => [
      ...prevSections,
      {
        sectionTitle: newSectionTitle,
        trendlines: []
      }
    ])
  }
  const addMemo = (memo: string): boolean => {
    if (!memosBox.includes(memo)) {
      setMemosBox((prevMemos) => [...prevMemos, memo])
      return true
    }
    return false
  }
  const addScenario = (scenario: string): boolean => {
    if (!scenariosBox.includes(scenario)) {
      setScenariosBox((prevScenarios) => [...prevScenarios, scenario])
      return true
    }
    return false
  }

  const transformedMemos: IQuickSearchEntity[] = memos.map((memo: IMemo) => ({
    id: memo.fid,
    name: memo.title,
    type: 'Memo',
    functionToExecute: {
      title: 'Open',
      function: () => addMemo(memo.fid)
    },
    is_own: memo.is_own
  }))
  const transformedScenarios: IQuickSearchEntity[] = scenarios.map(
    (scenario: IScenarioItem) => ({
      id: scenario.fid,
      name: scenario.scenario_name,
      type: 'Scenario',
      functionToExecute: {
        title: 'Open',
        function: () => addScenario(scenario.fid)
      },
      is_own: scenario.is_own
    })
  )
  const removeSection = (sectionTitle: string) => {
    setSections((prevSections) =>
      prevSections.filter((section) => section.sectionTitle !== sectionTitle)
    )
  }

  const addTrendlineById = (id: any, sectionTitle: string): boolean => {
    const selectedIndicator = fullFetchedIndicatorsParameters.find(
      (indicator: any) => indicator.fid === id
    )

    if (!selectedIndicator) {
      return false
    }

    const trendlineExists = sections
      .find((indicator) => indicator.sectionTitle === sectionTitle)
      ?.trendlines.includes(selectedIndicator.fid)

    if (trendlineExists) {
      return false
    }

    setSections((prevIndicators) => {
      const updatedIndicators = prevIndicators.map((indicator) =>
        indicator.sectionTitle === sectionTitle
          ? {
              ...indicator,
              trendlines: [...indicator.trendlines, selectedIndicator.fid]
            }
          : indicator
      )

      return updatedIndicators
    })

    return true
  }

  const removeTreandlineById = (id: any, sectionTitle: string): boolean => {
    setSections((prevIndicators) => {
      const updatedIndicators = prevIndicators.map((indicator) => {
        if (indicator.sectionTitle === sectionTitle) {
          const updatedTrendlines = indicator.trendlines.filter(
            (trendline) => trendline !== id
          )

          return {
            ...indicator,
            trendlines: updatedTrendlines
          }
        }

        return indicator
      })

      return updatedIndicators
    })

    return true
  }

  const handleDragStart = (
    e: React.DragEvent<HTMLDivElement>,
    index: number,
    type: string
  ) => {
    e.dataTransfer.setData('application/reactflow', index.toString())
    e.dataTransfer.effectAllowed = 'move'
    e.dataTransfer.setData('type', type) // 'section' or 'trendline'
  }

  const handleDrop = (
    e: React.DragEvent<HTMLDivElement>,
    dropIndex: number | undefined,
    type: string,
    sectionIndex?: number
  ) => {
    e.preventDefault()
    const dragIndex = Number(e.dataTransfer.getData('application/reactflow'))
    const itemType = e.dataTransfer.getData('type')

    if (itemType === 'section') {
      const draggedSection = sections[dragIndex]
      const remainingSections = sections.filter(
        (_, index) => index !== dragIndex
      )
      const newSections = [
        ...remainingSections.slice(0, dropIndex),
        draggedSection,
        ...remainingSections.slice(dropIndex)
      ]
      setSections(newSections)
    }
    // else if (itemType === 'trendline' && sectionIndex !== undefined) {
    //     if (sectionIndex !== null) {
    //       const targetSection = sections[sectionIndex]
    //       const draggedTrendline = targetSection.trendlines[dragIndex]
    //       const remainingTrendlines = targetSection.trendlines.filter(
    //         (_, index) => index !== dragIndex
    //       )
    //       const newTrendlines = [
    //         ...remainingTrendlines.slice(0, dropIndex),
    //         draggedTrendline,
    //         ...remainingTrendlines.slice(dropIndex)
    //       ]
    //       setSections(
    //         sections.map((section, index) =>
    //           index === sectionIndex
    //             ? { ...section, trendlines: newTrendlines }
    //             : section
    //         )
    //       )
    //     }
    //   }
  }

  const handleDragOver = (e: { preventDefault: () => void }) => {
    e.preventDefault()
  }

  React.useEffect(() => {
    const newSettings = {
      ...userSettings,
      homepage_trendlines: sections
        .filter((section) => section.trendlines.length > 0)
        .map((section, index) => ({
          sectionTitle:
            section.sectionTitle === ''
              ? `Section ${index + 1}`
              : section.sectionTitle,
          trendlines: section.trendlines
        }))
    }
    setUserSettings(newSettings)
  }, [sections])

  React.useEffect(() => {
    const newSettings = {
      ...userSettings,
      homepage_memos: memosBox,
      homepage_scenarios: scenariosBox
    }
    setUserSettings(newSettings)
  }, [memosBox, scenariosBox])

  React.useEffect(() => {
    window.globalSettings = userSettings
  }, [userSettings])

  return (
    <div className="global-settings">
      <TabbingSwitch
        options={[
          {
            label: 'Data Zones',
            onClick: () => setSettingsModalMode('zones'),
            active: settingsModalMode === 'zones'
          },
          {
            label: 'Data Display',
            onClick: () => setSettingsModalMode('mapped'),
            active: settingsModalMode === 'mapped'
          },
          {
            label: 'General',
            onClick: () => setSettingsModalMode('general'),
            active: settingsModalMode === 'general'
          },
          {
            label: 'Homepage Trendlines',
            onClick: () => setSettingsModalMode('homepage_indicators'),
            active: settingsModalMode === 'homepage_indicators'
          },
          {
            label: 'Homepage Memos',
            onClick: () => setSettingsModalMode('homepage_memos'),
            active: settingsModalMode === 'homepage_memos'
          },
          {
            label: 'Homepage Scenarios',
            onClick: () => setSettingsModalMode('homepage_scenarios'),
            active: settingsModalMode === 'homepage_scenarios'
          }
        ]}
        numberVisible={6}
      />
      <hr />
      {settingsModalMode === 'mapped' && (
        <div>
          <div className="flex middle mx-2 col-12 flex-wrap space-between">
            <label className="mx-1 me-3">Columns: </label>
            {userSettings.data_mapped_all_columns.map((column: any) => (
              <Checkbox
                color="success-o"
                shape="curve"
                animation="pulse"
                className="my-1"
                key={column + '-data-mapped-checkbox'}
                checked={userSettings.data_mapped_selected_columns.includes(
                  column
                )}
                disabled={
                  userSettings.data_mapped_selected_columns.length === 1 &&
                  userSettings.data_mapped_selected_columns[0] === column
                }
                onChange={() =>
                  setUserSettings((prev) => {
                    if (prev.data_mapped_selected_columns.includes(column)) {
                      return {
                        ...prev,
                        data_mapped_selected_columns:
                          prev.data_mapped_selected_columns.filter(
                            (el: any) => el !== column
                          )
                      }
                    } else {
                      return {
                        ...prev,
                        data_mapped_selected_columns: [
                          ...prev.data_mapped_selected_columns,
                          column
                        ]
                      }
                    }
                  })
                }
              >{`${column.charAt(0).toUpperCase()}${column.slice(
                1
              )}`}</Checkbox>
            ))}
          </div>
        </div>
      )}
      {settingsModalMode === 'homepage_indicators' && (
        <div>
          <div>
            {sections.map((section, index) => (
              <div
                key={index}
                className="flex flex-column col-12 my-2 border p-2 gap-2 draggable"
                draggable
                onDragStart={(e) => handleDragStart(e, index, 'section')}
                onDragOver={handleDragOver}
                onDrop={(e) => handleDrop(e, index, 'section')}
              >
                <div className="flex col-12 middle justify-content-between">
                  <div className="flex middle gap-2 px-2">
                    <p className="m-0">Title:</p>
                    <input
                      className="m-0 wide"
                      type="text"
                      value={section.sectionTitle}
                      onChange={(e) => {
                        setSections((prevSections) =>
                          prevSections.map((prevSection, i) =>
                            i === index
                              ? { ...prevSection, sectionTitle: e.target.value }
                              : prevSection
                          )
                        )
                      }}
                    />
                  </div>
                  <button
                    className="no-btn no-hover icon t-large"
                    onClick={() => {
                      removeSection(section.sectionTitle)
                    }}
                  >
                    <IconClose />
                  </button>
                </div>

                <div className="flex flex-column col-12">
                  <div className="flex col-12 middle">
                    <IndicatorsAndTrendlinesSearch
                      indicators={fullFetchedIndicatorsParameters}
                      includedIndicators={
                        sections.find(
                          (indicator) =>
                            indicator.sectionTitle === section.sectionTitle
                        )?.trendlines || []
                      }
                      refreshIndicators={() => undefined}
                      direction="down"
                      addFunction={(id: string) =>
                        addTrendlineById(id, section.sectionTitle)
                      }
                      removeFunction={(id: string) =>
                        removeTreandlineById(id, section.sectionTitle)
                      }
                      altFunction={() => undefined}
                    />
                  </div>
                  {section.trendlines.length > 0 && (
                    <label className="margin-0-half px-1">
                      Selected trendlines:
                      {section.trendlines
                        .map((trendline) =>
                          fullFetchedIndicatorsParameters.find(
                            (indicator: any) => indicator.fid === trendline
                          )
                        )
                        .filter((_) => !isEmpty(_) as any)
                        .map((trendline, trendIndex) => (
                          <div className="flex middle gap-3" key={trendIndex}>
                            <p
                              className="m-0"
                              onClick={(e) => e.preventDefault()}
                            >
                              {`${trendIndex + 1}. ${trendline.title}`}
                            </p>
                            <button
                              className="no-btn no-hover icon"
                              onClick={() =>
                                removeTreandlineById(
                                  trendline.fid,
                                  section.sectionTitle
                                )
                              }
                            >
                              <IconClose />
                            </button>
                          </div>
                        ))}
                    </label>
                  )}
                </div>
              </div>
            ))}
          </div>
          <div className="flex center middle space-between">
            <button
              className="secondary m-1 wide border"
              onClick={() => {
                addSection()
              }}
            >
              Add section
            </button>
          </div>
        </div>
      )}
      {settingsModalMode === 'homepage_memos' && (
        <div className="flex flex-column col-12">
          <div className="flex col-12 middle">
            <QuickSearch entities={transformedMemos} />
          </div>
          <label className="margin-0-half px-1">
            Selected memos:
            {memosBox.map((memo, memoIndex) => (
              <div className="flex middle gap-3" key={memoIndex}>
                <p className="m-0">
                  {memos.find((m) => m.fid === memo)?.title}
                </p>
                <button
                  className="no-btn no-hover icon"
                  onClick={() =>
                    setMemosBox((prevMemos) =>
                      prevMemos.filter((prevMemo) => prevMemo !== memo)
                    )
                  }
                >
                  <IconClose />
                </button>
              </div>
            ))}
          </label>
        </div>
      )}
      {settingsModalMode === 'homepage_scenarios' && (
        <div className="flex flex-column col-12">
          <div className="flex col-12 middle">
            <QuickSearch entities={transformedScenarios} />
          </div>
          <label className="margin-0-half px-1">
            Selected scenarios:
            {scenariosBox.map((scenario, scenarioIndex) => (
              <div className="flex middle gap-3" key={scenarioIndex}>
                <p className="m-0">
                  {scenarios.find((m) => m.fid === scenario)?.scenario_name}
                </p>
                <button
                  className="no-btn no-hover icon"
                  onClick={() =>
                    setScenariosBox((prevScenarios) =>
                      prevScenarios.filter(
                        (prevScenarios) => prevScenarios !== scenario
                      )
                    )
                  }
                >
                  <IconClose />
                </button>
              </div>
            ))}
          </label>
        </div>
      )}
      {settingsModalMode === 'zones' && (
        <div className="flex justify-content-between col-12 flex-wrap">
          <div className="col-12">
            <p className="mx-2">Data Boundaries (in standard deviations):</p>
            <div className="flex col-12 mb-1">
              <div className="flex-column middle justify-content-center">
                <div className="flex middle m-1 col-12 justify-content-evenly">
                  <label className="mx-1 me-3">Boundary 1:</label>
                  <input
                    type="number"
                    className="narrow"
                    value={userSettings.boundaries_in_sd[5]}
                    min={userSettings.boundaries_in_sd[4]}
                    onChange={(e) => {
                      setUserSettings((prev) => ({
                        ...prev,
                        boundaries_in_sd: {
                          ...prev.boundaries_in_sd,
                          5: e.target.value
                        }
                      }))
                    }}
                  />
                </div>
                <div className="flex middle m-1 col-12 justify-content-evenly">
                  <label className="mx-1 me-3">Boundary 2:</label>
                  <input
                    type="number"
                    className="narrow"
                    value={userSettings.boundaries_in_sd[4]}
                    min={userSettings.boundaries_in_sd[3]}
                    max={userSettings.boundaries_in_sd[5]}
                    onChange={(e) => {
                      setUserSettings((prev) => ({
                        ...prev,
                        boundaries_in_sd: {
                          ...prev.boundaries_in_sd,
                          4: e.target.value
                        }
                      }))
                    }}
                  />
                </div>
                <div className="flex middle m-1 col-12 justify-content-evenly">
                  <label className="mx-1 me-3">Boundary 3:</label>
                  <input
                    type="number"
                    className="narrow"
                    value={userSettings.boundaries_in_sd[3]}
                    min={userSettings.boundaries_in_sd[2]}
                    max={userSettings.boundaries_in_sd[4]}
                    onChange={(e) => {
                      setUserSettings((prev) => ({
                        ...prev,
                        boundaries_in_sd: {
                          ...prev.boundaries_in_sd,
                          3: e.target.value
                        }
                      }))
                    }}
                  />
                </div>
                <div className="flex middle m-1 col-12 justify-content-evenly">
                  <label className="mx-1 me-3">Boundary 4:</label>
                  <input
                    type="number"
                    className="narrow"
                    value={userSettings.boundaries_in_sd[2]}
                    min={userSettings.boundaries_in_sd[1]}
                    max={userSettings.boundaries_in_sd[3]}
                    onChange={(e) => {
                      setUserSettings((prev) => ({
                        ...prev,
                        boundaries_in_sd: {
                          ...prev.boundaries_in_sd,
                          2: e.target.value
                        }
                      }))
                    }}
                  />
                </div>
                <div className="flex middle m-1 col-12 justify-content-evenly">
                  <label className="mx-1 me-3">Boundary 5:</label>
                  <input
                    type="number"
                    className="narrow"
                    value={userSettings.boundaries_in_sd[1]}
                    max={userSettings.boundaries_in_sd[2]}
                    onChange={(e) => {
                      setUserSettings((prev) => ({
                        ...prev,
                        boundaries_in_sd: {
                          ...prev.boundaries_in_sd,
                          1: e.target.value
                        }
                      }))
                    }}
                  />
                </div>
              </div>
              <div className="flex-column middle">
                <div className="flex middle m-1 col-12 justify-content-evenly">
                  <label className="mx-1 me-3">Color 1:</label>
                  <input
                    type="color"
                    className="narrow"
                    value={userSettings.colours_data_mapped[6]}
                    onChange={(e) => {
                      setUserSettings((prev) => ({
                        ...prev,
                        colours_data_mapped: {
                          ...prev.colours_data_mapped,
                          6: e.target.value
                        }
                      }))
                    }}
                  />
                </div>
                <div className="flex middle m-1 col-12 justify-content-evenly">
                  <label className="mx-1 me-3">Color 2:</label>
                  <input
                    type="color"
                    className="narrow"
                    value={userSettings.colours_data_mapped[5]}
                    onChange={(e) => {
                      setUserSettings((prev) => ({
                        ...prev,
                        colours_data_mapped: {
                          ...prev.colours_data_mapped,
                          5: e.target.value
                        }
                      }))
                    }}
                  />
                </div>
                <div className="flex middle m-1 col-12 justify-content-evenly">
                  <label className="mx-1 me-3">Color 3:</label>
                  <input
                    type="color"
                    className="narrow"
                    value={userSettings.colours_data_mapped[4]}
                    onChange={(e) => {
                      setUserSettings((prev) => ({
                        ...prev,
                        colours_data_mapped: {
                          ...prev.colours_data_mapped,
                          4: e.target.value
                        }
                      }))
                    }}
                  />
                </div>
                <div className="flex middle m-1 col-12 justify-content-evenly">
                  <label className="mx-1 me-3">Color 4:</label>
                  <input
                    type="color"
                    className="narrow"
                    value={userSettings.colours_data_mapped[3]}
                    onChange={(e) => {
                      setUserSettings((prev) => ({
                        ...prev,
                        colours_data_mapped: {
                          ...prev.colours_data_mapped,
                          3: e.target.value
                        }
                      }))
                    }}
                  />
                </div>
                <div className="flex middle m-1 col-12 justify-content-evenly">
                  <label className="mx-1 me-3">Color 5:</label>
                  <input
                    type="color"
                    className="narrow"
                    value={userSettings.colours_data_mapped[2]}
                    onChange={(e) => {
                      setUserSettings((prev) => ({
                        ...prev,
                        colours_data_mapped: {
                          ...prev.colours_data_mapped,
                          2: e.target.value
                        }
                      }))
                    }}
                  />
                </div>
                <div className="flex middle m-1 col-12 justify-content-evenly">
                  <label className="mx-1 me-3">Color 6:</label>
                  <input
                    type="color"
                    className="narrow"
                    value={userSettings.colours_data_mapped[1]}
                    onChange={(e) => {
                      setUserSettings((prev) => ({
                        ...prev,
                        colours_data_mapped: {
                          ...prev.colours_data_mapped,
                          1: e.target.value
                        }
                      }))
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="col-12">
            <div className="flex gap-3 col-12">
              <p className="mx-2">Rises and Drops (in std dev):</p>
            </div>
            <div className="flex col-12 flex-column">
              <div className="flex middle m-1 col-12 middle flex-start">
                <label className="mx-1 me-3">Cells</label>
                <select
                  className="narrow"
                  value={userSettings.rises_and_drops_in_sd.state}
                  onChange={(e) => {
                    setUserSettings((prev) => ({
                      ...prev,
                      rises_and_drops_in_sd: {
                        ...prev.rises_and_drops_in_sd,
                        state: e.target.value as 'arrow' | 'value' | 'off'
                      }
                    }))
                  }}
                >
                  <option value="arrow">Arrows</option>
                  <option value="value">Value</option>
                  <option value="off">Off</option>
                </select>
              </div>
              <div className="flex middle m-1 col-12 middle flex-start">
                <label className="mx-1 me-3">Major Drop</label>
                <input
                  type="number"
                  className="narrow"
                  value={userSettings.rises_and_drops_in_sd[1]}
                  max={userSettings.rises_and_drops_in_sd[2]}
                  onChange={(e) => {
                    setUserSettings((prev) => ({
                      ...prev,
                      rises_and_drops_in_sd: {
                        ...prev.rises_and_drops_in_sd,
                        1: e.target.value
                      }
                    }))
                  }}
                />
              </div>
              <div className="flex middle m-1 col-12 middle flex-start">
                <label className="mx-1 me-3">Minor Drop</label>
                <input
                  type="number"
                  className="narrow"
                  value={userSettings.rises_and_drops_in_sd[2]}
                  min={userSettings.rises_and_drops_in_sd[1]}
                  max={userSettings.rises_and_drops_in_sd[3]}
                  onChange={(e) => {
                    setUserSettings((prev) => ({
                      ...prev,
                      rises_and_drops_in_sd: {
                        ...prev.rises_and_drops_in_sd,
                        2: e.target.value
                      }
                    }))
                  }}
                />
              </div>
              <div className="flex middle m-1 col-12 middle flex-start">
                <label className="mx-1 me-3">Minor Rise</label>
                <input
                  type="number"
                  className="narrow"
                  value={userSettings.rises_and_drops_in_sd[3]}
                  min={userSettings.rises_and_drops_in_sd[2]}
                  max={userSettings.rises_and_drops_in_sd[4]}
                  onChange={(e) => {
                    setUserSettings((prev) => ({
                      ...prev,
                      rises_and_drops_in_sd: {
                        ...prev.rises_and_drops_in_sd,
                        3: e.target.value
                      }
                    }))
                  }}
                />
              </div>
              <div className="flex middle m-1 col-12 middle flex-start">
                <label className="mx-1 me-3">Major Rise</label>
                <input
                  type="number"
                  className="narrow"
                  value={userSettings.rises_and_drops_in_sd[4]}
                  min={userSettings.rises_and_drops_in_sd[3]}
                  onChange={(e) => {
                    setUserSettings((prev) => ({
                      ...prev,
                      rises_and_drops_in_sd: {
                        ...prev.rises_and_drops_in_sd,
                        4: e.target.value
                      }
                    }))
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      )}
      {settingsModalMode === 'general' && (
        <div
          className="flex-column p-2 gap-3 col-12"
          style={{
            fontSize: '1.2rem'
          }}
        >
          <div className="flex-row">
            <label className="mx-1">Default Open</label>
            <select
              className="wide"
              value={userSettings.default_open}
              onChange={(e) => {
                setUserSettings((prev) => ({
                  ...prev,
                  default_open: Number(e.target.value)
                }))
              }}
            >
              <option value="0">Collection</option>
              <option value="1">Scenario</option>
              <option value="2">Indicator</option>
              <option value="5">Calculated Indicator</option>

              <option value="4">Trendline</option>
              <option value="3">Memo</option>
            </select>
          </div>
          <Checkbox
            color="success-o"
            shape="curve"
            animation="pulse"
            className="mx-1 me-3"
            checked={userSettings.beta}
            onChange={() => {
              setUserSettings((prev) => ({
                ...prev,
                beta: !prev.beta
              }))
            }}
          >
            Beta
          </Checkbox>
        </div>
      )}
    </div>
  )
}

export default GlobalSettings
