import ReactDOM from 'react-dom/client'
import React from 'react'
import App from './App'

// Create a root using createRoot
const root = ReactDOM.createRoot(document.getElementById('root')!) // Risky if ID can be missing

// Render the App within the root
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
)
