import React, { useEffect } from 'react'
import closeIcon from '../assets/icon-close.png'
import { PopupModalProps } from '../utils/interfaces'
import { FunctionalButton } from './_components'

const PopupModal = ({
  isOpen,
  onClose,
  title,
  children,
  size,
  handleSubmit,
  saveButtonDisabled,
  saveButtonExists,
  undoButtonExists,
  handleUndo,
  titleButton,
  customMessage,
  className,
  noChanges,
  fullHeight,
  refreshOnComplete,
  inner,
  nested
}: PopupModalProps) => {
  const [closeConfirmation, setCloseConfirmation] = React.useState(false)
  const newOnClose = onClose !== undefined ? onClose : () => undefined
  const closeFunction = () =>
    closeConfirmation || noChanges ? newOnClose() : setCloseConfirmation(true)

  // add event lister to close modal on escape key press in a useEffect
  // remove event listener in a useEffect

  useEffect(() => {
    const handleEsc = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        closeFunction()
      }
    }
    window.addEventListener('keydown', handleEsc)
    return () => {
      window.removeEventListener('keydown', handleEsc)
    }
  }, [closeFunction])

  return (
    <div
      className={`popup-panel ${isOpen ? 'open' : 'closed'} ${!inner ? 'inner' : ''}${nested ? ' nested' : ''}${fullHeight ? ' full-height' : ''}`}
      style={{
        display: isOpen ? 'flex' : 'none'
      }}
    >
      <div className="overlay"></div>
      <div className={`panel-container ${size}`}>
        <div className="panel-header z-1">
          <div className="flex-row gap-5">
            <h2 className={titleButton?.exists ? 'z-1' : 'col-12 z-1'}>
              {title}
            </h2>
            {titleButton &&
              titleButton.exists &&
              !titleButton.notFunctional && (
                <FunctionalButton
                  className={titleButton.class}
                  functionToExecute={titleButton.onClick}
                  initialButtonState={titleButton.title}
                  doesReset
                />
              )}
            {titleButton && titleButton.exists && titleButton.notFunctional && (
              <button
                className={titleButton.class}
                onClick={titleButton.onClick}
              >
                {titleButton.title as React.ReactNode}
              </button>
            )}
          </div>
          <button className="icon no-btn z-1" onClick={closeFunction}>
            <img src={closeIcon} width="20px" alt="close" />
          </button>
        </div>
        <div className={`panel-content z-1 ${className}`}>
          {closeConfirmation && (
            <span className="banner-strip info center">
              {customMessage ||
                'Are you sure you want to close without saving?'}
            </span>
          )}
          {children}
        </div>
        <div className="flex center middle z-1 gap-1 submit-buttons">
          {undoButtonExists && handleUndo && (
            <FunctionalButton
              className="teriary inline wide center"
              functionToExecute={handleUndo}
              initialButtonState="Undo"
              doesReset
            />
          )}
          {saveButtonExists && handleSubmit && (
            <FunctionalButton
              className="primary inline wide center submit-button"
              functionToExecute={handleSubmit}
              disabled={saveButtonDisabled}
              initialButtonState="Confirm"
              iconSaveMode
              doesReset
              combinedButtonTitle
              refreshOnComplete={refreshOnComplete}
            />
          )}
        </div>
      </div>
    </div>
  )
}

export default PopupModal
