import React from 'react'
import { ISearchPage } from '../utils/interfaces'
import AdvancedSearch from './AdvancedSearch'
import { EntityHeader } from './_components'

const SearchPage = ({
  scenarios,
  indicators,
  collections,
  trendlines,
  memos,
  forecast,
  channelsContent,
  search,
  customPageDetails
}: ISearchPage) => {
  return (
    <div className="entity site-search-page default-text">
      <EntityHeader
        ownerInfo={{
          profileImage: '',
          username: 'You'
        }}
        title={
          customPageDetails ? customPageDetails.title : 'Advanced Search Page'
        }
        description={
          customPageDetails
            ? customPageDetails.description
            : 'Search for scenarios, indicators, collections, trendlines, memos, forecast, channels, and more'
        }
        subtitle={customPageDetails ? customPageDetails.subtitle : 'Search'}
      />
      <div className="entity-blocks">
        <AdvancedSearch
          scenarios={scenarios}
          channelsContent={channelsContent}
          indicators={indicators}
          collections={collections}
          trendlines={trendlines}
          forecast={forecast}
          search={search}
          memos={memos}
        />
      </div>
    </div>
  )
}

export default SearchPage
