import React, { useEffect, useState } from 'react'
import './css/style.scss'
import './css/index.css'
import '@djthoms/pretty-checkbox'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import MainApp from './majorComponents/MainApp'
// import RegisterPage from './authentication/RegisterPage'
import LoginPage from './authentication/LoginPage'
import LogoutPage from './authentication/LogoutPage'
import { AccessDenied } from './helperComponents/_components'
import MarketingSite from './majorComponents/MarketingSite'
import PasswordPrompt from './helperComponents/PasswordPrompt'
import AdminPage from './authentication/AdminPage'
import { checkIsAdmin } from './utils/fetch'

function App() {
  const token = localStorage.getItem('token')
  const location = window.location.pathname
  const params = new URLSearchParams(window.location.search)
  const access = params.get('access') || ''
  const userId = localStorage.getItem('userId')
  const isLoggedOut = token === 'undefined' || token === null || token === ''
  const [isAuthenticated, setIsAuthenticated] = useState(!isLoggedOut)

  if (isLoggedOut && location === '/app') {
    window.location.href = '/login'
  }

  const checkAccess = async () => {
    if (location === '/admin-controls') {
      const isAdmin = await checkIsAdmin()
      if (!isAdmin) {
        window.location.href = '/'
      }
    }
  }

  useEffect(() => {
    checkAccess()
    if ((access === 'marketing' && location === '/') || userId === 'Aw1Um0DR') {
      setIsAuthenticated(true)
    }
  }, [])

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/login" element={<LoginPage />} />
        <Route path="/app" element={<MainApp />} />

        {isAuthenticated && <Route path="/" element={<MarketingSite />} />}
        {!isAuthenticated && (
          <Route
            path="/"
            element={
              <PasswordPrompt
                onPasswordSuccess={() =>
                  (window.location.href = '?access=marketing')
                }
              />
            }
          />
        )}
        <Route path="/admin-controls" element={<AdminPage />} />
        <Route path="/logout" element={<LogoutPage />} />
        <Route path="*" element={<AccessDenied />} />
      </Routes>
    </BrowserRouter>
  )
}

export default App
