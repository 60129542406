import React, { Fragment, useEffect, useState } from 'react'
import ExcelUploadForm from './ExcelUploadForm'
import { IMappedDataEntry, IUploadPage } from '../utils/interfaces'
import {
  cloneIndicator,
  createNewIndicator,
  updateDbIndicator
} from '../utils/fetch'
import { Checkbox } from 'pretty-checkbox-react'
import { FunctionalButton } from './_components'

export default function UploadPage({
  fullFetchedIndicatorsParameters,
  singleIndicatorMode,
  customPostFunction,
  mode,
  customData
}: IUploadPage) {
  const [newIndicatorName, setNewIndicatorName] = useState<string>('')
  const [newIndicatorDescription, setNewIndicatorDescription] =
    useState<string>('')
  const [newIndicatorInversed, setNewIndicatorInversed] =
    useState<boolean>(false)
  const [replaceData, setReplaceData] = useState<boolean>(mode === 'table')
  const [newIndicatorCategory, setNewIndicatorCategory] = useState<string>('')
  const [newIndicatorIsTracking, setNewIndicatorIsTracking] =
    useState<boolean>(false)
  const [cloningIndicator, setCloningIndicator] = useState<boolean>(false)
  const [editingIndicator, setEditingIndicator] = useState<boolean>(false)
  const [coreIndicator, setCoreIndicator] = useState<boolean>(false)
  const [indicatorToClone, setIndicatorToClone] = useState<string>('')
  const [indicatorToEdit, setIndicatorToEdit] = useState<string>('')
  const [error, setError] = useState<boolean>()
  const [errorMessage, setErrorMessage] = useState<string>('')
  const [successMessage, setSuccessMessage] = useState<string>('')
  const [showSuccessMessage, setShowSuccessMessage] = useState<boolean>(false)

  const [dataFormatCheckPassed, setDataFormatCheckPassed] =
    useState<boolean>(false)
  const [convertedData, setConvertedData] = useState<
    IMappedDataEntry | undefined
  >(customData)

  const handleDataCheckReset = () => {
    setError(false)
    setDataFormatCheckPassed(false)
    setConvertedData(undefined)
  }

  const handleDataCheckFailed = (message: string) => {
    setConvertedData(undefined)
    setError(true)
    setShowSuccessMessage(false)
    setErrorMessage(message)
    setDataFormatCheckPassed(false)
  }

  const handleDataCheckSuccess = (data: IMappedDataEntry) => {
    if (Object.keys(data).length === 0) {
      setError(true)
    } else {
      setConvertedData(data)
      setError(false)
      setDataFormatCheckPassed(true)
      setSuccessMessage(JSON.stringify(data))
    }
  }

  const handleReplaceChange = () => {
    setReplaceData(!replaceData)
  }

  const checkSubmitButtonDisabled = () =>
    newIndicatorName.length === 0 && !editingIndicator

  const createIndicator = async () => {
    if (convertedData === undefined) return false
    const res = await createNewIndicator({
      indicatorName: newIndicatorName,
      indicatorMeaning: newIndicatorDescription,
      isInversed: newIndicatorInversed,
      indicatorCategory: newIndicatorCategory,
      data: {
        dates: convertedData.dates,
        data: convertedData.values.map((item: any) =>
          typeof item === 'string'
            ? item.trim().replaceAll(',', '')
            : item.replaceAll(',', '')
        )
      },
      isCore: coreIndicator
    })

    return res
  }

  const handleUpdateIndicator = async () => {
    if (convertedData === undefined) return false
    const res = await updateDbIndicator({
      indicatorName: indicatorToEdit,
      replace: replaceData,
      data: {
        dates: convertedData.dates,
        data: convertedData.values
      }
    })

    return res
  }

  const handleCloneIndicator = async () => {
    const res = await cloneIndicator({
      existingIndcatorTableName: indicatorToClone,
      newIndicatorName,
      isTracking: newIndicatorIsTracking
    })
    return res
  }

  const handlePostData = async () => {
    if (customPostFunction && convertedData) {
      return await customPostFunction(convertedData)
    }

    if (!cloningIndicator && !editingIndicator) {
      return await createIndicator()
    }

    if (cloningIndicator && !editingIndicator) {
      return await handleCloneIndicator()
    }

    if (editingIndicator && convertedData) {
      return await handleUpdateIndicator()
    }
  }

  singleIndicatorMode.exists &&
    useEffect(() => {
      setEditingIndicator(true)
    }, [])

  singleIndicatorMode.exists &&
    useEffect(() => {
      const initialValue = fullFetchedIndicatorsParameters[0].fid
      setIndicatorToEdit(initialValue)
    }, [])

  return (
    <div className="flex-column">
      {!singleIndicatorMode.exists && (
        <Fragment>
          <h1 className="margin-0-half center default-text">
            {cloningIndicator ? 'Clone an Existing' : 'Create a New'} Indicator
          </h1>
          <div className="flex middle p-3 gap-3 col-12 flex-wrap">
            <Checkbox
              color="success-o"
              animation="pulse"
              onChange={() => setCloningIndicator(!cloningIndicator)}
              checked={cloningIndicator}
            >
              Do you want to clone one of the exsisting indicators instead?
            </Checkbox>
            <Checkbox
              color="success-o"
              animation="pulse"
              onChange={() => setEditingIndicator(!editingIndicator)}
              checked={editingIndicator}
            >
              Do you want to edit data of one of your indicators instead?
            </Checkbox>
            <Checkbox
              color="success-o"
              animation="pulse"
              onChange={() => setCoreIndicator(!coreIndicator)}
              checked={coreIndicator}
            >
              Is this a CORE indicator?
            </Checkbox>
          </div>
        </Fragment>
      )}

      {singleIndicatorMode.exists && mode === 'upload' && (
        <Checkbox
          color="success-o"
          animation="pulse"
          onChange={handleReplaceChange}
          checked={replaceData}
        >
          Do you want to replace data?
        </Checkbox>
      )}

      <div className="upload-page-body">
        {coreIndicator && (
          <span className="banner-strip warning t-small default-text">
            Warning. This operation will require admin priviledges and will not
            succed if you are not an admin
          </span>
        )}
        {!editingIndicator && !singleIndicatorMode.exists && (
          <input
            type="text"
            value={newIndicatorName}
            onChange={(e) => setNewIndicatorName(e.target.value)}
            placeholder="Indicator Name"
          />
        )}
        {editingIndicator && (
          <select
            value={indicatorToEdit}
            className="widest"
            onChange={(e) => setIndicatorToEdit(e.target.value)}
          >
            {!singleIndicatorMode.exists && (
              <option value="select">Select an indicator</option>
            )}
            {fullFetchedIndicatorsParameters.map((item: any) => (
              <option key={item.id} value={item.fid}>
                {item.title}
              </option>
            ))}
          </select>
        )}

        {!cloningIndicator && (
          <Fragment>
            <ExcelUploadForm
              handleDataCheckFailed={handleDataCheckFailed}
              handleDataCheckSuccess={handleDataCheckSuccess}
              handleDataCheckReset={handleDataCheckReset}
              mode={mode}
              customData={customData}
            />
            {dataFormatCheckPassed && (
              <button
                onClick={() => setShowSuccessMessage(!showSuccessMessage)}
                className="wider teriary"
              >
                {showSuccessMessage ? 'Hide Data Preview' : 'Show Data Preview'}
              </button>
            )}
            {dataFormatCheckPassed && !error && (
              <span className="banner-strip success">Data Check Passed.</span>
            )}
            {showSuccessMessage && successMessage !== '' && (
              <span
                style={{
                  fontSize: '0.6rem'
                }}
              >
                Dates: {JSON.parse(successMessage).dates.join(', ')} <br />
                Data: {JSON.parse(successMessage).values.join(', ')}
              </span>
            )}
            {error && (
              <span className="banner-strip danger ">
                Error Uploading: {errorMessage}. Please check your data again
              </span>
            )}
            {!editingIndicator && !singleIndicatorMode.exists && (
              <Fragment>
                <textarea
                  value={newIndicatorDescription}
                  onChange={(e) => setNewIndicatorDescription(e.target.value)}
                  placeholder="Indicator Description"
                  className="big border-gray"
                  disabled={!dataFormatCheckPassed}
                />
                <Checkbox
                  color="info-o"
                  animation="pulse"
                  onChange={() =>
                    setNewIndicatorInversed(!newIndicatorInversed)
                  }
                  checked={newIndicatorInversed}
                  disabled={!dataFormatCheckPassed}
                >
                  Is this indicator inversed?
                </Checkbox>
                <select
                  value={newIndicatorCategory}
                  className="widest"
                  onChange={(e) =>
                    e.target.value !== 'select' &&
                    setNewIndicatorCategory(e.target.value)
                  }
                  disabled={!dataFormatCheckPassed}
                >
                  <option value="select">Select a category</option>
                  <option value="macro">Macro</option>
                  <option value="commodity">Commodity</option>
                  <option value="monetary">Monetary</option>
                  <option value="consumer">Consumer</option>
                </select>
              </Fragment>
            )}
          </Fragment>
        )}
        {cloningIndicator && (
          <Fragment>
            <Checkbox
              color="success-o"
              animation="pulse"
              onChange={() =>
                setNewIndicatorIsTracking(!newIndicatorIsTracking)
              }
              checked={newIndicatorIsTracking}
            >
              Do you want the new indicator to track the indicator?
            </Checkbox>
            {newIndicatorIsTracking && (
              <Fragment>
                <span className="banner-strip danger ">
                  Do not use this feature yet. Full testing pending
                </span>
              </Fragment>
            )}
            <select
              value={indicatorToClone}
              className="widest"
              onChange={(e) => setIndicatorToClone(e.target.value)}
            >
              <option value="select">Select an indicator</option>
              {fullFetchedIndicatorsParameters.map((item) => (
                <option key={item.id} value={item.fid}>
                  {item.title}
                </option>
              ))}
            </select>
          </Fragment>
        )}
        <FunctionalButton
          functionToExecute={handlePostData}
          doesReset
          disabled={checkSubmitButtonDisabled()}
          initialButtonState={'Submit'}
          className="primary inline wide"
        />
      </div>
    </div>
  )
}
