import React from 'react'
import MiniIndicator from './MiniIndicator'
import MiniScenario from './MiniScenario'
import MiniTrendline from './MiniTrendline'
import { IMiniEntity } from '../utils/interfaces'

const MiniEntity: React.FC<IMiniEntity> = ({ id, type }) => {
  if (!id || !type) return null

  if (type === 'indicator') {
    return <MiniIndicator indicatorId={id} />
  } else if (type === 'scenario') {
    return <MiniScenario fid={id} />
  } else if (type === 'trendline') {
    return <MiniTrendline trendlineId={id} />
  } else {
    return null
  }
}

export default MiniEntity
