import React, { useCallback, useEffect, useState } from 'react'
import { isEmpty } from '../utils/functions'
import {
  IMemoEntry,
  IMemo,
  IMemoPage,
  ICollectionEntry
} from '../utils/interfaces'
import {
  deleteMemo,
  fetchMemoById,
  getImageFromS3,
  getMemoLink,
  modifyCollectionEntries,
  updateMemo
} from '../utils/fetch'
import {
  TabbingSwitch,
  AccessDenied,
  FunctionalButton,
  InlineSpinner,
  Spinner,
  EntityHeader
} from '../helperComponents/_components'
import {
  Icon3Dots,
  IconLarger,
  IconSmaller,
  IconUploadNew,
  TickFail,
  TickSuccess
} from '../helperComponents/Icons'
import CustomContextMenu from '../helperComponents/CustomContextMenu'
import EntityTags from '../helperComponents/EntityTags'
import PopupModal from '../helperComponents/PopUpModal'
import { jsPDF } from 'jspdf'
import ChatbotComponent from '../helperComponents/ChatbotComponent'

const MemoPage = ({ memoId, favourites, userTags }: IMemoPage) => {
  const [memo, setMemo] = React.useState<IMemo>()
  const [files, setFiles] = useState<File[] | null>(null)
  const [intialState, setInitialState] = React.useState<IMemoEntry[]>([])
  const [loading, setLoading] = React.useState<boolean>(true)
  const [contentItems, setContentItems] = useState<IMemoEntry[]>([])
  const [uploadState, setUploadState] = useState<string>('ready')
  const [detailsTabMode, setDetailsTabMode] = useState<string>('reader')
  const [newName, setNewName] = useState<string>('')
  const [newDescription, setNewDescription] = useState<string>('')
  const [newShortDescription, setNewShortDescription] = useState<string>('')
  const [modalDeleteMemo, setModalDeleteMemo] = useState<boolean>(false)
  const [ownerInfo, setOwnerInfo] = useState({
    profileImage: '',
    username: ''
  })
  const [
    modalIndicatorNameAndDescription,
    setModalIndicatorNameAndDescription
  ] = useState<boolean>(false)
  const defaultContextMenuSettings = {
    top: 0,
    left: 0,
    show: false
  }
  const [isMemoFavourite, setIsMemoFavourite] = useState<boolean>(false)
  const [contextMenuDetails, setContextMenuDetails] = useState(
    defaultContextMenuSettings
  )
  const resetContextMenuDetails = () => {
    if (contextMenuDetails.show) {
      setContextMenuDetails(defaultContextMenuSettings)
    }
  }

  const handleAddTextArea = () => {
    const newTextItem = {
      type: 'text',
      content: '',
      config: { className: 'col-12', source: '' }
    }
    setContentItems((prevContentItems) => [...prevContentItems, newTextItem])
    return true
  }

  const undoChanges = () => {
    setContentItems(intialState)
    return true
  }

  const checkIfTrendlineIsFavourite = () => {
    if (favourites === undefined) {
      return false
    }

    const isFavourite =
      favourites.memos.length > 0 &&
      favourites.memos.some((item: ICollectionEntry) => item.id === memoId)

    return isFavourite
  }

  // const updateFileContent = (content: string) => {
  //   setFileContent(content)
  // }

  const generatePDF = useCallback(
    async (exportPDF = false) => {
      const doc = new jsPDF() // eslint-disable-line

      // Add a large title
      doc.setFontSize(30)
      doc.text(memo?.title || 'Memo', 10, 15)
      doc.setFontSize(10)

      // Variables to keep track of the position in the PDF
      let x = 10 // Horizontal margin
      let y = 30 // Vertical position
      const heights: number[] = []

      const findPosition = (index: number) => {
        if (index === 0) return index

        const sumOfPreviousWidth = contentItems
          .slice(0, index)
          .reduce((acc, item) => {
            if (item.config.className === 'col-6') {
              return acc + 1
            }
            return acc + 2
          }, 0)

        if (sumOfPreviousWidth % 2 === 0) {
          return 0
        }
        return 1
      }

      // Loop through each item in the content structure
      for (let index = 0; index < contentItems.length; index++) {
        const item = contentItems[index]
        if (item.type === 'text') {
          // Set the column width based on the class name
          const maxWidth = item.config.className === 'col-6' ? 85 : 180 // Half page width for col-6, full page for col-12
          doc.text(item.content, x, y, { maxWidth })
          const height = doc.getTextDimensions(item.content, { maxWidth }).h
          heights.push(height)
        } else if (item.type === 'image') {
          // Check if the image should be in a new column or full width
          const width = item.config.className === 'col-6' ? 85 : 180 // Half page width for col-6, full page for col-12
          const s3ImageLink = await getImageFromS3(
            item.content.split('/').pop() || ''
          )
          const format = item.content.split('.').pop() || 'jpeg'
          const signedUrl = s3ImageLink.signedUrl
          const img = document.querySelector(
            `img[src="${item.content}"]`
          ) as HTMLImageElement
          const aspectRatio = img.width / img.height
          let height = width / aspectRatio
          if (isEmpty(height) || isNaN(height)) {
            height = 50
            if (heights.length > 0) {
              height = heights[index - 1]
            }
          }

          try {
            doc.addImage(signedUrl, format, x, y, width, height) // x, y, width, height must be correctly calculated
          } catch (e) {
            try {
              console.info(
                'initial image load failed, trying again with the backup',
                e
              )

              doc.addImage(item.content.trim(), format, x, y, width, height)
            } catch (e) {
              console.warn('image load failed. Trying jquery', e)

              doc.addImage(img, format, x, y, width, height)
            }
          }
          heights.push(height)
        }

        // print the source if it exists and is not empty under the text
        if (!isEmpty(item.config.source)) {
          doc.setFontSize(5)
          doc.text(`Source: ${item.config.source}`, x, y + heights[index] + 2)
          doc.setFontSize(10)

          heights[index] += 5
        }

        const position = findPosition(index)

        if (position === 1 && item.config.className === 'col-6') {
          const incrementHeight = Math.max(heights[index], heights[index - 1])
          y += incrementHeight > 0 ? incrementHeight : 0
        } else if (position === 0 && item.config.className === 'col-6') {
          const incrementHeight =
            contentItems[index + 1] !== undefined &&
            contentItems[index + 1].config.className === 'col-12'
              ? heights[index]
              : 0
          y += incrementHeight > 0 ? incrementHeight : 0
        } else {
          y += heights[index] > 0 ? heights[index] : 0
        }

        if (item.config.className === 'col-6' && x === 10) {
          // Check if we need to move to the next column or add a page
          x = 105 // Move to the next column
        } else {
          x = 10 // Reset to first column
          if (y > 270) {
            // Check if we need a new page
            doc.addPage()
            y = 10 // Reset y position for the new page
          }
        }

        if (position === 1) {
          // add a line to break the rows
          y += 8
        }
      }

      // add a source
      const link = `${window.location.origin}/app?t=memo&i=${memoId}`
      doc.setFontSize(8)
      doc.text(`Source: ${link} @Chartit360`, 10, 280)

      if (exportPDF) {
        doc.save(`${memo?.title} Chartit Export.pdf`)
        return true
      }
      const blob = await fetch(doc.output('datauristring')).then((res) =>
        res.blob()
      )
      const file = new File([blob], `${memo?.title} Data Export.pdf`, {
        type: 'application/pdf'
      })

      setFiles([file])
      return file
    },
    [memoId]
  )

  const handleExportAsPDF = async () => {
    await generatePDF(true)
    return true
  }

  const analyseWithAI = async () => {
    await generatePDF()
    setDetailsTabMode('ai')

    return true
  }

  const handleAddImage = async (file: File) => {
    setUploadState('loading')
    const imageType = file.type.split('/')[1]
    const link = await getMemoLink(file, imageType)

    if (!link) {
      setUploadState('error')
      return
    }

    const newImageItem = {
      type: 'image',
      content: link,
      config: { className: 'col-6', source: '' }
    }
    setContentItems([...contentItems, newImageItem])
    setUploadState('success')

    const timeout = setTimeout(() => {
      setUploadState('ready')
      clearTimeout(timeout)
    }, 2000)
  }

  const getMemo = useCallback(
    async (noReload?: boolean) => {
      if (!noReload) {
        setLoading(true)
      }
      const response = await fetchMemoById(memoId)
      if (!response || !response.data) {
        return
      }
      const data = response.data[0]
      const memo = {
        ...data,
        content: isEmpty(data.content) ? [] : JSON.parse(data.content)
      }

      setContentItems(memo.content || [])
      setMemo(memo)
      setInitialState(memo.content || [])
      setNewName(memo.title)
      setNewDescription(memo.description)
      setNewShortDescription(memo.short_description)
      setUploadState('ready')
      setIsMemoFavourite(checkIfTrendlineIsFavourite())
      setOwnerInfo({
        profileImage: response.owner.profile_image,
        username: response.owner.username
      })
      if (!noReload) {
        setLoading(false)
      }
    },
    [memoId]
  )

  const handleUpdateMemo = async () => {
    const res = await updateMemo({
      fid: memoId,
      memoName: memo?.title || '',
      memoContent: contentItems
    })
    return res
  }

  const resetModals = () => {
    window.collapseSidePanel(true)
    resetContextMenuDetails()
    setModalIndicatorNameAndDescription(false)
    setModalDeleteMemo(false)
  }

  const undoNamingChanges = () => {
    if (!memo) return
    setNewName(memo.title)
    setNewDescription(memo.description)
    setNewShortDescription(memo.short_description)
    resetModals()
  }

  const handleContextMenuDetails = (e: any) => {
    e.preventDefault()
    if (contextMenuDetails.show) {
      setContextMenuDetails(defaultContextMenuSettings)
      return
    }
    resetModals()
    setContextMenuDetails({
      show: true,
      top: e.pageY,
      left: e.pageX - 210
    })
  }

  const handleDragStart = (
    e: {
      dataTransfer: {
        setData: (arg0: string, arg1: any) => void
        effectAllowed: string
      }
    },
    index: any
  ) => {
    try {
      e.dataTransfer.setData('text/plain', index)
      e.dataTransfer.effectAllowed = 'move'
    } catch (e) {
      console.error(e)
    }
  }

  const handleDrop = (
    e: {
      preventDefault: () => void
      dataTransfer: { getData: (arg0: string) => any }
    },
    dropIndex: number | undefined
  ) => {
    try {
      e.preventDefault()
      const dragIndex = e.dataTransfer.getData('text/plain')
      const draggedItem = contentItems[dragIndex]
      const remainingItems = contentItems.filter(
        (_, index) => index !== parseInt(dragIndex)
      )
      const newContentItems = [
        ...remainingItems.slice(0, dropIndex),
        draggedItem,
        ...remainingItems.slice(dropIndex)
      ]
      setContentItems(newContentItems)
    } catch (e) {
      console.error(e)
    }
  }

  const removeItemByIndex = (index: number) => {
    const newContentItems = [...contentItems]
    newContentItems.splice(index, 1)
    setContentItems(newContentItems)
  }

  const MemoBlock = ({
    content,
    index,
    onDragStart,
    onDrop,
    mode
  }: {
    content: IMemoEntry
    index: number
    onDragStart: (e: React.DragEvent<HTMLDivElement>, index: number) => void
    onDrop: (e: React.DragEvent<HTMLDivElement>, dropIndex: number) => void
    mode: string
  }) => {
    const isLarge = content.config.className === 'col-12'
    const [text, setText] = React.useState<string>(content.content)
    const [source, setSource] = React.useState<string>(content.config.source)

    const handleResize = () => {
      const newContentItems = [...contentItems] // Ensure you have access to contentItems or pass as props
      newContentItems[index].config.className = isLarge ? 'col-6' : 'col-12'
      setContentItems(newContentItems) // Ensure setContentItems is passed or accessible
    }

    const handleUpdate = () => {
      const newContentItems = [...contentItems] // Ensure you have access to contentItems or pass as props
      newContentItems[index].content = text
      setContentItems(newContentItems) // Ensure setContentItems is passed or accessible
    }

    const handleUpdateSource = () => {
      const newContentItems = [...contentItems] // Ensure you have access to contentItems or pass as props
      newContentItems[index].config.source = source
      setContentItems(newContentItems) // Ensure setContentItems is passed or accessible
    }

    const handleTextChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      if (mode === 'reader') return
      setText(e.target.value)
    }

    return (
      <div
        className={`memo-block ${content.config.className}`}
        onDragOver={(e) => e.preventDefault()} // Allows for the drop to register
        onDrop={(e) => onDrop(e, index)}
      >
        {mode === 'configuration' && (
          <div className="controls">
            <div
              className="drag-handle"
              draggable
              onDragStart={(e) => onDragStart(e, index)}
            >
              {/* Optional: Add an icon or text to signify this is a draggable area */}
              <span>:::</span>{' '}
              {/* This is just a simple textual representation; you might use an icon */}
            </div>
            <div className="flex gap-3">
              <input
                type="text"
                className="wide"
                placeholder="Source"
                onBlur={handleUpdateSource}
                value={source}
                onChange={(e) => setSource(e.target.value)}
              />
              <button
                onClick={handleResize}
                className="icon border"
                title={isLarge ? 'Shrink' : 'Expand'}
              >
                {isLarge ? <IconSmaller /> : <IconLarger />}
              </button>
              <FunctionalButton
                functionToExecute={() => removeItemByIndex(index)} // Make sure this function is passed or accessible
                initialButtonState={'Remove'}
                className="destructive"
                doesReset
                iconMinusMode
              />
            </div>
          </div>
        )}
        {content.type === 'text' && (
          <textarea
            className={`col-12 ${mode}`}
            // esure there are enough rows to display the content
            rows={
              text.length < 20
                ? 1
                : Math.min(
                    content.config.className === 'col-12'
                      ? Math.max(
                          (window.innerWidth / text.length) * 4 + 1,
                          text.split('\n').length
                        )
                      : Math.max(
                          (window.innerWidth / text.length) * 8 + 1,
                          text.split('\n').length
                        ),
                    mode === 'reader' ? 1000 : 20
                  )
            }
            value={text}
            onChange={handleTextChange}
            onBlur={handleUpdate}
            onDrop={(e) => e.preventDefault()}
          />
        )}
        {content.type === 'image' && (
          <div
            className="image pointer"
            onClick={(e) => {
              e.preventDefault()

              if (mode === 'reader') {
                try {
                  window.open(content.config.source, '_blank')
                } catch (e) {
                  console.warn('Error opening source:', e)
                }
              }
            }}
          >
            <img
              src={content.content}
              alt="uploaded"
              className="full pointer"
              // disable interaction
              onDrag={(e) => e.preventDefault()}
            />
            {!isEmpty(content.config.source) && (
              <span className="t-small small font-small col-12 t-center">
                Source: {content.config.source}
              </span>
            )}
          </div>
        )}
      </div>
    )
  }

  const refreshMemoNoClose = () => {
    getMemo(true)
  }

  useEffect(() => {
    getMemo()
  }, [memoId])

  if (loading) {
    return <Spinner />
  }

  if (!memo && !loading) {
    return (
      <div className="entity-page new">
        <h4 className="subtitle"></h4>
        <h1 className="title">Memo not found</h1>
      </div>
    )
  }

  if (memo) {
    return (
      <div className="entity memo new">
        <EntityHeader
          subtitle="Memo"
          ownerInfo={ownerInfo}
          title={newName}
          description={newShortDescription}
        />
        <div className="entity-blocks">
          <div className="container w-12 full">
            <div className="entity-info">
              <div
                className="entity-parameters"
                onClick={resetContextMenuDetails}
              >
                <TabbingSwitch
                  options={[
                    {
                      label: 'Reading Mode',
                      active: detailsTabMode === 'reader',
                      onClick: () => setDetailsTabMode('reader')
                    },
                    {
                      label: 'Edit Mode',
                      active: detailsTabMode === 'configuration',
                      onClick: () => setDetailsTabMode('configuration')
                    },
                    // {
                    //   label: 'Reading Mode',
                    //   active: detailsTabMode === 'reader',
                    //   onClick: () => setDetailsTabMode('reader')
                    // },
                    {
                      label: 'AI',
                      active: detailsTabMode === 'ai',
                      onClick: () => setDetailsTabMode('ai')
                    },
                    {
                      label: 'Tags',
                      active: detailsTabMode === 'tags',
                      onClick: () => setDetailsTabMode('tags')
                    }
                    // {
                    //   label: 'Sharing',
                    //   active: detailsTabMode === 'sharing',
                    //   onClick: () => setDetailsTabMode('sharing')
                    // }
                  ]}
                  numberVisible={5}
                  fit
                  className="no-border col-3"
                />
                <button
                  className="no-btn flex no-hover"
                  onClick={handleContextMenuDetails}
                >
                  <Icon3Dots />
                </button>
              </div>
              <div
                style={{
                  height: '80%'
                }}
              >
                <div
                  style={{
                    display:
                      detailsTabMode === 'reader' ||
                      detailsTabMode === 'configuration'
                        ? 'flex'
                        : 'none'
                  }}
                  className="p-2 flex flex-wrap align-items-start"
                >
                  {contentItems &&
                    contentItems.map((contentPiece, index) => (
                      <MemoBlock
                        key={index}
                        content={contentPiece}
                        index={index}
                        onDragStart={handleDragStart}
                        onDrop={handleDrop}
                        mode={detailsTabMode}
                      />
                    ))}
                </div>

                {detailsTabMode === 'configuration' && (
                  <div className="col-12 flex gap-3 middle center p-2">
                    <FunctionalButton
                      functionToExecute={handleAddTextArea}
                      initialButtonState={'Text'}
                      className="secondary"
                      doesReset
                      iconPlusMode
                      combinedButtonTitle
                    />
                    <div className={'file-upload'}>
                      <label htmlFor="file-input" className="file-input">
                        {
                          {
                            loading: <InlineSpinner />,
                            error: <TickFail />,
                            success: <TickSuccess />,
                            ready: <IconUploadNew />
                          }[uploadState]
                        }
                      </label>
                      <input
                        type="file"
                        id="file-input"
                        name="file-input"
                        onChange={async (e) => {
                          if (e.target.files) {
                            handleAddImage(e.target.files[0])
                          }
                        }}
                      />
                      <label htmlFor="file-input">
                        {
                          {
                            loading: 'Loading...',
                            error: 'Error',
                            success: 'Success',
                            ready: 'Add Image'
                          }[uploadState]
                        }
                      </label>
                    </div>
                    <FunctionalButton
                      functionToExecute={undoChanges}
                      initialButtonState={'Undo'}
                      className="ternary"
                      doesReset
                      iconUndoMode
                      combinedButtonTitle
                    />
                    <FunctionalButton
                      functionToExecute={handleUpdateMemo}
                      initialButtonState={'Save'}
                      className="primary"
                      doesReset
                      iconSaveMode
                      combinedButtonTitle
                    />
                  </div>
                )}
                {detailsTabMode === 'tags' && (
                  <div className="col-12 p-2">
                    <EntityTags
                      type="memo"
                      allTags={userTags}
                      entityTags={memo.tags || []}
                      entityId={memoId}
                      functionRefresh={refreshMemoNoClose}
                    />
                  </div>
                )}
                <div
                  style={{
                    display: detailsTabMode === 'ai' ? 'block' : 'none'
                  }}
                  className="flex-row col-12"
                >
                  <ChatbotComponent
                    page="entity"
                    instructions="Analyse this file containing information and provide insights as well as an in-depth analysis of the content"
                    assistantOn={true}
                    providedFiles={files}
                    clearFiles={() => setFiles(null)}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <CustomContextMenu
          contextMenuSettings={contextMenuDetails}
          menu={[
            {
              onClick: () => {
                resetModals()
                setModalIndicatorNameAndDescription(true)
              },
              title: 'Edit Details',
              noAccess: !memo.is_own
            },
            {
              onClick: () => {
                resetModals()
                analyseWithAI()
              },
              title: 'Analyse',
              noAccess: !memo.is_own
            },
            {
              onClick: handleExportAsPDF,
              title: 'Export as PDF',
              functionalButtonSettings: {
                exists: true,
                refreshFunction: () => console.info('Export complete'),
                doesReset: true
              }
            },
            {
              onClick: () =>
                modifyCollectionEntries({
                  entityType: 'memo',
                  entityId: memoId,
                  action: isMemoFavourite ? 'remove' : 'add',
                  entityName: memo?.title || '',
                  collectionId: favourites === undefined ? 0 : favourites.id
                }),
              title: isMemoFavourite
                ? 'Remove from My Library'
                : 'Add to My Library',
              functionalButtonSettings: {
                exists: true,
                refreshFunction: refreshMemoNoClose,
                doesReset: true
              },
              noAccess: memo.is_own
            },
            {
              onClick: () => {
                resetModals()
                setModalDeleteMemo(true)
              },
              title: 'Delete Memo'
            }
          ]}
        />
        {modalDeleteMemo && (
          <PopupModal
            isOpen={modalDeleteMemo}
            onClose={() => setModalDeleteMemo(false)}
            title="Are you sure you want to delete this indicator?"
            size="small"
            handleSubmit={() => undefined}
            saveButtonExists={false}
            inner
          >
            <div className="flex-row center middle margin-1">
              <FunctionalButton
                className="destructive"
                functionToExecute={async () => await deleteMemo(memoId)}
                initialButtonState={'Delete'}
                refreshOnComplete={{
                  exists: true,
                  refreshFunction: () => window.switchFunctions.deleted(memoId)
                }}
                doesReset
              />
            </div>
          </PopupModal>
        )}
        {modalIndicatorNameAndDescription && (
          <PopupModal
            isOpen={modalIndicatorNameAndDescription}
            onClose={undoNamingChanges}
            title="Edit Memo Details"
            size="large"
            handleSubmit={() => undefined}
            noChanges={
              newName === memo.title &&
              newDescription === memo.description &&
              newShortDescription === memo.short_description
            }
            inner
          >
            <input
              className="input center middle flex col-12"
              type="text"
              value={newName}
              onChange={(e) => setNewName(e.target.value)}
              placeholder="Indicator name"
            />
            <textarea
              rows={5}
              value={newShortDescription}
              onChange={(e) => setNewShortDescription(e.target.value)}
              placeholder="Short description"
            />
            <textarea
              rows={10}
              value={newDescription}
              onChange={(e) => setNewDescription(e.target.value)}
              placeholder="Indicator description"
            />
            <div className="flex gap-3">
              <button
                className="no-btn border center middle full"
                onClick={undoNamingChanges}
              >
                Undo
              </button>
              <FunctionalButton
                className="primary border center middle full"
                functionToExecute={() =>
                  updateMemo({
                    fid: memoId,
                    memoName: newName,
                    shortDescription: newShortDescription,
                    description: newDescription
                  })
                }
                initialButtonState="Save"
                iconSaveMode
                combinedButtonTitle
                disabled={newName === ''}
                refreshOnComplete={{
                  exists: true,
                  refreshFunction: () =>
                    setModalIndicatorNameAndDescription(false)
                }}
              />
            </div>
          </PopupModal>
        )}
      </div>
    )
  }

  return (
    <AccessDenied message="You do not have access to this memo. Please contact the administrator." />
  )
}

export default React.memo(MemoPage)
