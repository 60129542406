import { linearRegression } from 'simple-statistics'
import { isEmpty } from './functions'

/**
 * This function calculates exponential regression on a given dataset and
 * returns the parameters of the regression model along with the standard
 * deviation, mean, and individual residuals of the differences between
 * actual and predicted values.
 *
 * @param values - An array of numerical data points.
 * @returns An object containing:
 *    a: The 'a' parameter of the exponential model y = a * e^(b*x)
 *    b: The 'b' parameter of the exponential model y = a * e^(b*x)
 *    residuals: The differences between actual and predicted values.
 *    residualsStdDev: The standard deviation of the residuals.
 *    residualsMean: The mean of the residuals.
 */

interface LinearExponentialStatsInputs {
  values: (number | null | string)[]
  isRelative: boolean
  chartMode: string
}

interface ExponentialAndLinearStats {
  a: number
  b: number
  residuals: number[]
  adjustedResiduals: number[] | void
  residualsStdDev: number
}

// interface ExponentialStatsWResiduals extends ExponentialStats {
//   adjustedResiduals: number[]
// }

function customPow(base: number, exponent: number) {
  if (base < 0) {
    return Math.pow(-base, exponent)
  } else {
    return Math.pow(base, exponent)
  }
}

const processValues = (values: (number | null | string)[]): number[] => {
  let valuesResult: any = []

  valuesResult = values.map((val: any) =>
    isEmpty(val) ? null : isNaN(Number(val)) ? null : Number(val)
  )

  // go through the valuesResult, and if ta value is null, use the last non-null value
  // to replace it
  valuesResult = valuesResult.map((val: any, index: number) => {
    if (val === null) {
      for (let i = index; i >= 0; i--) {
        if (valuesResult[i] !== null) {
          return valuesResult[i]
        }
      }
    }

    return val
  })

  return valuesResult.filter((val: any) => val !== null).map(Number)
}

const getStandardDeviation = (values: number[]): number => {
  if (values.length === 0) return 0

  values = processValues(values)

  const mean = values.reduce((a, b) => a + b) / values.length
  const variance =
    values.map((val) => Math.pow(val - mean, 2)).reduce((a, b) => a + b) /
    values.length

  return Math.sqrt(variance)
}

const computeLinearStats = (
  nonNullValues: number[],
  isRelative: boolean
): ExponentialAndLinearStats => {
  const x = Array.from({ length: nonNullValues.length }, (_, i) => i)
  const regressionLine = linearRegression(
    x.map((xi, idx) => [xi, nonNullValues[idx]])
  )
  const predictedValues = x.map(
    (xi) => regressionLine.m * xi + regressionLine.b
  )
  let residuals = []

  if (isRelative) {
    residuals = nonNullValues.map(
      (val: number, idx: number) => (val - predictedValues[idx]) / val
    )
  } else {
    residuals = nonNullValues.map(
      (val: number, idx: number) => val - predictedValues[idx]
    )
  }

  const a = regressionLine.m
  const b = regressionLine.b
  const residualsStdDev = getStandardDeviation(residuals)

  const adjustedResiduals = residualsStdDev
    ? [...residuals].map((residual) => residual / Number(residualsStdDev))
    : []

  return {
    a,
    b,
    residuals,
    adjustedResiduals,
    residualsStdDev
  }
}

const computeCAGRStats = (
  nonNullValues: number[],
  isRelative: boolean
): ExponentialAndLinearStats => {
  const n = nonNullValues.length
  const beginningValue = nonNullValues[0]
  const endingValue = nonNullValues[n - 1]
  const divided = endingValue / beginningValue
  const power = 1 / (n - 1)

  const a = customPow(divided, power) - 1 || 0
  const b = beginningValue

  const x = Array.from({ length: n }, (_, i) => i)
  const predictedValues = x.map((xi) => b * Math.pow(1 + a, xi))

  let residuals = []

  if (isRelative) {
    residuals = nonNullValues.map(
      (val: number, idx: number) => (val - predictedValues[idx]) / val
    )
  } else {
    residuals = nonNullValues.map(
      (val: number, idx: number) => val - predictedValues[idx]
    )
  }

  const residualsStdDev = getStandardDeviation(residuals)

  const adjustedResiduals = residualsStdDev
    ? [...residuals].map((residual) => residual / Number(residualsStdDev))
    : []

  return {
    a,
    b,
    residuals,
    adjustedResiduals,
    residualsStdDev
  }
}

const computeAverageStats = (
  nonNullValues: number[],
  isRelative: boolean,
  offset: number
): ExponentialAndLinearStats => {
  const transformedValues = nonNullValues.map((val: number, idx: number) => {
    if (idx > offset) {
      return null
    }

    let formulaValue = 0
    for (let i = 0; i < offset; i++) {
      formulaValue += Number(nonNullValues[idx - i])
    }
    formulaValue /= offset

    const newResidual = isRelative
      ? (Number(val) - formulaValue) / Number(val)
      : Number(val) - formulaValue

    return newResidual
  })

  const residuals = transformedValues.filter(
    (val: number | null): val is number => val !== null
  )

  const residualsStdDev = getStandardDeviation(residuals)

  const adjustedResiduals = residualsStdDev
    ? [...residuals].map((residual) => residual / Number(residualsStdDev))
    : []

  return {
    a: 0,
    b: 0,
    residuals,
    adjustedResiduals,
    residualsStdDev
  }
}

const computeOtherStats = (
  nonNullValues: number[],
  isRelative: boolean
): ExponentialAndLinearStats => {
  const minValue = Math.min(...nonNullValues)

  const adjustment = minValue <= 0 ? Math.abs(minValue) + 0.000000000001 : 0

  const adjustedValues = nonNullValues.map((val: number) => val + adjustment)

  const logValues = adjustedValues.map((val: number) => Math.log(Number(val)))

  const x = Array.from({ length: logValues.length }, (_, i) => i)

  const regressionLine = linearRegression(
    x.map((xi, idx) => [xi, logValues[idx]])
  )

  const a = Math.exp(regressionLine.b)

  const b = regressionLine.m

  const predictedValues = x.map((xi) => a * Math.exp(b * xi) - adjustment)

  let residuals = []

  if (isRelative) {
    residuals = nonNullValues.map(
      (val: number, idx: number) => (val - predictedValues[idx]) / val
    )
  } else {
    residuals = nonNullValues.map(
      (val: number, idx: number) => val - predictedValues[idx]
    )
  }

  const residualsStdDev = getStandardDeviation(residuals)

  const adjustedResiduals = residualsStdDev
    ? [...residuals].map((residual) => residual / Number(residualsStdDev))
    : []

  return {
    a,
    b,
    residuals,
    residualsStdDev,
    adjustedResiduals
  }
}

function computeExponentialAndLinearStats({
  values,
  isRelative,
  chartMode
}: LinearExponentialStatsInputs): ExponentialAndLinearStats {
  try {
    const nonNullValues = processValues(values)

    switch (chartMode) {
      case 'linear':
        return computeLinearStats(nonNullValues, isRelative)
      case 'cagr':
        return computeCAGRStats(nonNullValues, isRelative)
      case 'average':
        return computeAverageStats(
          nonNullValues,
          isRelative,
          Number(chartMode.split('---')[1])
        )
      default:
        return computeOtherStats(nonNullValues, isRelative)
    }
  } catch (e) {
    console.log('Error in computeExponentialAndLinearStats', e)
    return {
      a: 0,
      b: 0,
      residuals: [],
      adjustedResiduals: [],
      residualsStdDev: 0
    }
  }
}

export { computeExponentialAndLinearStats }
