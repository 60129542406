import React from 'react'
import { useNavigate } from 'react-router-dom'

const LogoutPage: React.FC = () => {
  const navigator = useNavigate()

  const handleLogout = () => {
    // Clear the authentication token from local storage
    localStorage.clear()
    navigator('/')
  }

  return (
    <div className="panel-empty-page">
      <h1>Are you sure you want to log out?</h1>
      <div className="flex-row center middle margin-1 gap-10">
        <button className="primary tab" onClick={handleLogout}>
          Log out
        </button>
        <button
          className="border no-btn"
          onClick={() => (window.location.href = '/app')}
        >
          Back
        </button>
      </div>
    </div>
  )
}

export default LogoutPage
