import React, { useEffect, useMemo, useState } from 'react'
import {
  IBasicIndicator,
  ICalculatedIndicator,
  IEquationPiece,
  ITrendline
} from '../utils/interfaces'
import { FunctionalButton, SearchableSelect } from './_components'
import { updateEquationCalculatedIndicator } from '../utils/fetch'
import { checkEquationValidity } from '../utils/functions'
import { Icon3Dots } from './Icons'
import PopupModal from './PopUpModal'
import { checkCalcEquationChanges } from '../utils/transformingData'

const CombineIndicatorFormula = ({
  indicators,
  indicatorId,
  refreshFunction,
  updateComputationalMode,
  computationMode,
  changes,
  dataMode
}: {
  indicators: (IBasicIndicator | ICalculatedIndicator | ITrendline)[]
  indicatorId: string
  refreshFunction: () => void
  updateComputationalMode: () => Promise<boolean>
  computationMode: string
  changes: boolean
  dataMode: string
}) => {
  const OPERATIONS = ['+', '-', '*', '/', '(', ')']
  const [error, setError] = useState('')
  const [info, setInfo] = useState('')
  const existingEquation = useMemo(() => {
    // replace the equation with the one from the selected indicator
    const selectedIndicator = indicators.find(
      (ind) => ind.fid === indicatorId
    ) as ICalculatedIndicator

    if (!selectedIndicator) return []

    if (selectedIndicator.equation) {
      return selectedIndicator.equation
    }
    return []
  }, [indicators, indicatorId])

  const [equation, setEquation] = useState<IEquationPiece[]>(existingEquation)
  const [renderedEquation, setRenderedEquation] = useState<any>(null)
  const [modalPieceSettings, setModalPieceSettings] =
    useState<IEquationPiece | null>(null)

  const indicatorsNames = useMemo(() => {
    return indicators
      .filter((indicator) => indicator !== undefined && indicator !== null)
      .filter(
        (indicator) =>
          indicator.fid !== indicatorId &&
          (Object.keys(indicator).includes('base_indicator')
            ? (indicator as any).base_indicator !== indicatorId
            : true)
      )
      .map((indicator) => ({
        value: indicator.fid,
        label: indicator.title || ''
      }))
      .sort((a, b) => a.label.localeCompare(b.label))
  }, [indicators, existingEquation])

  const insertIntoEquationNew = ({
    value,
    positionIndex,
    mode = 'values',
    type,
    offset = 0,
    cagr = 0
  }: {
    value: string
    positionIndex: number
    type: string
    mode?: string
    offset?: number
    cagr?: number
  }) => {
    const newToken = {
      value,
      type,
      offset,
      mode,
      cagr
    }
    const newEquation = [...equation]
    newEquation[positionIndex] = newToken
    setEquation(newEquation)
    setModalPieceSettings(null)
  }

  const evaluateIfIndicatorIsExternal = (indicatorId: string) => {
    const indicator = indicators.find((_) => _.fid === indicatorId)

    if (!indicator || indicator.category === 'FRED') {
      return true
    }

    return false
  }

  const evaluateTypeOfIndicator = (indicatorId: string) =>
    evaluateIfIndicatorIsExternal(indicatorId) ? 'external' : 'indicator'

  const removeLast = () => {
    const newEquation = [...equation]
    newEquation.pop()
    setEquation(newEquation)
  }

  const addIndicator = () => {
    if (indicatorsNames.length === 0) {
      return
    }
    insertIntoEquationNew({
      value: indicatorsNames[0].value,
      positionIndex: equation.length,
      type: evaluateTypeOfIndicator(indicatorsNames[0].value)
    })
  }

  const clearEquation = () => {
    setEquation([])
  }

  const handleUpdate = async () => {
    if (
      !checkEquationValidity(
        equation,
        indicatorsNames.map((_) => _.value),
        setError,
        OPERATIONS
      )
    ) {
      return false
    }

    const res1 = await updateComputationalMode()
    const res2 = await updateEquationCalculatedIndicator({
      equation,
      indicatorId
    })

    return res1 && res2
  }

  const renderUpdatedEquation = (newEquation: IEquationPiece[]) => {
    const eq = newEquation.map((token, index) => {
      if (token.type === 'operator') {
        return (
          <select
            key={index}
            className="operator"
            defaultValue={token.value}
            onChange={(e) =>
              insertIntoEquationNew({
                value: e.target.value,
                positionIndex: index,
                type: 'operator'
              })
            }
          >
            {OPERATIONS.map((operation, idx) => (
              <option key={idx} value={operation}>
                {operation}
              </option>
            ))}
          </select>
        )
      } else if (token.type === 'number') {
        return (
          <input
            key={index}
            type="number"
            className="narrower"
            value={Number(token.value)}
            onChange={(e) =>
              insertIntoEquationNew({
                value: e.target.value,
                positionIndex: index,
                type: 'number'
              })
            }
          />
        )
      } else {
        return (
          <div className="indicator-box" key={index}>
            <SearchableSelect
              options={indicatorsNames}
              onChange={(value: any) =>
                insertIntoEquationNew({
                  value,
                  positionIndex: index,
                  type: evaluateTypeOfIndicator(value)
                })
              }
              defaultValue={token.value}
              width={Math.min(
                Math.max(
                  indicatorsNames
                    .find((_) => _.value === token.value)!
                    .label.toString().length * 8,
                  150
                ),
                250
              )}
              className="indicator"
            />
            <div className="settings">
              <button onClick={() => setModalPieceSettings(token)}>
                <Icon3Dots />
              </button>
              <span>
                {token.cagr > 0 && `CAGR of ${token.cagr}   `}
                {token.offset > 0 && `Offset by ${token.offset}`}
              </span>
            </div>
          </div>
        )
      }
    })

    setError('')
    setEquation(newEquation)
    setRenderedEquation(eq)
  }

  const updateEquation = (newEquation: IEquationPiece[]) => {
    try {
      renderUpdatedEquation(newEquation)
    } catch (error) {
      if (typeof newEquation === 'string') {
        newEquation = JSON.parse(newEquation)
        try {
          renderUpdatedEquation(newEquation)
        } catch (error) {
          setError('Invalid equation')
        }
      }
    }
  }

  const resetEquation = () => {
    setRenderedEquation(null)
    setEquation(existingEquation)
  }

  useEffect(() => {
    updateEquation(equation)
  }, [equation])

  useEffect(() => {
    updateEquation(existingEquation)
  }, [existingEquation])

  useEffect(() => {
    if (error !== '' && $('.equation-display').html() === '') {
      setInfo('Try reseting the equation')
    } else {
      setInfo('')
    }
  }, [error])

  return (
    <div className="combine-indicator-formula">
      <div className="operation-buttons">
        <div className="control-buttons">
          <FunctionalButton
            className="secondary"
            functionToExecute={addIndicator}
            noReturn
            iconPlusMode
            doesReset
            combinedButtonTitle
            initialButtonState="Item"
            disabled={indicatorsNames.length === 0}
          />
          <FunctionalButton
            className="secondary"
            functionToExecute={() =>
              insertIntoEquationNew({
                value: '+',
                positionIndex: equation.length,
                type: 'operator'
              })
            }
            noReturn
            iconPlusMode
            doesReset
            initialButtonState="Operator"
            combinedButtonTitle
            disabled={indicatorsNames.length === 0}
          />
          <FunctionalButton
            className="secondary"
            functionToExecute={() =>
              insertIntoEquationNew({
                value: '0',
                positionIndex: equation.length,
                type: 'number'
              })
            }
            noReturn
            iconPlusMode
            doesReset
            initialButtonState="Number"
            combinedButtonTitle
            disabled={indicatorsNames.length === 0}
          />
          <FunctionalButton
            className="destructive"
            functionToExecute={removeLast}
            noReturn
            iconMinusMode
            initialButtonState="Back"
            combinedButtonTitle
            doesReset
            disabled={indicatorsNames.length === 0}
          />
          <FunctionalButton
            className="destructive"
            functionToExecute={clearEquation}
            noReturn
            iconMinusMode
            doesReset
            initialButtonState="Clear"
            combinedButtonTitle
            disabled={indicatorsNames.length === 0}
          />
        </div>
      </div>
      {error !== '' && (
        <span className="banner-strip small danger">{error}</span>
      )}
      {info !== '' && <span className="banner-strip small info">{info}</span>}
      <div className="equation-display">{renderedEquation}</div>
      <div className="operation-buttons">
        {(checkCalcEquationChanges(equation, existingEquation) || changes) && (
          <div className="flex gap-2">
            <FunctionalButton
              className="primary center middle"
              functionToExecute={handleUpdate}
              refreshOnComplete={{
                exists: true,
                refreshFunction
              }}
              doesReset
              initialButtonState={'Calculate'}
            />
            <FunctionalButton
              className="teriary center middle"
              functionToExecute={resetEquation}
              initialButtonState={'Reset'}
              doesReset
            />
          </div>
        )}
      </div>

      {modalPieceSettings !== null && (
        <PopupModal
          isOpen={modalPieceSettings !== null}
          onClose={() => setModalPieceSettings(null)}
          title={
            'Editing ' +
            indicatorsNames.find((_) => _.value === modalPieceSettings.value)
              ?.label
          }
          inner
          size="small"
          handleSubmit={() =>
            insertIntoEquationNew({
              value: modalPieceSettings.value,
              positionIndex: equation.findIndex(
                (token) => token.value === modalPieceSettings.value
              ),
              type: evaluateTypeOfIndicator(modalPieceSettings.value),
              mode: modalPieceSettings.mode,
              offset: modalPieceSettings.offset,
              cagr: modalPieceSettings.cagr
            })
          }
          saveButtonExists={true}
          nested
          noChanges
        >
          <div className="flex-row margin-1 gap-3">
            <p className="m-0">CAGR of</p>
            <input
              type="number"
              className="narrower"
              value={modalPieceSettings.cagr}
              onChange={(e) =>
                setModalPieceSettings({
                  ...modalPieceSettings,
                  cagr: Number(e.target.value)
                })
              }
            />
            <br />
            <p className="m-0">Offset by</p>
            <input
              type="number"
              className="narrower"
              value={modalPieceSettings.offset}
              onChange={(e) =>
                setModalPieceSettings({
                  ...modalPieceSettings,
                  offset: Number(e.target.value)
                })
              }
            />
          </div>
        </PopupModal>
      )}
    </div>
  )
}

export default CombineIndicatorFormula
